import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { documentApi } from '../../../api/document';
import { QualioDocument } from '../../../api/model/document';
import { tokenApi } from '../../../api/token';
import { handleError, handleSuccess } from '../../../config/handlers';
import { CKEditorInstance } from '../../../types/CKEditorInstance';
import { getUserCommentsPermission } from '../../../util/CurrentUser';
import { convertApiDocumentToViewableInstance } from '../../../util/DocumentApiUtils';
import { DocumentEditor } from '../DocumentEditor/DocumentEditor';
import { DocumentHTMLViewer } from '../DocumentHTMLViewer/DocumentHTMLViewer';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export const DocumentEditorFrameRoot: React.FC<{
  currentUser: CurrentUser;
}> = ({ currentUser }) => {
  const [mtfeDocument, setMtfeDocument] = useState<QualioDocument>();
  const [collaborationToken, setCollaborationToken] = useState<string>();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const requestedDocVersion = searchParams.get('version');
  const [editors, setEditors] = useState<CKEditorInstance[]>();
  const [context, setContext] = useState<any>();
  const [commentsPermission, setCommentsPermission] = useState<number>();

  useEffect(() => {
    // MTFE should set the document first
    // This is just a fallback in case some race condition appears
    if (id && !mtfeDocument) {
      const loadDocument = async () => {
        let fetchedDocument;
        try {
          fetchedDocument = await documentApi.fetchByIdOrVersion(
            id,
            requestedDocVersion ?? '',
          );
        } catch (e) {
          //updateHasError(true, (e as Error).message, true);
          return;
        }
        fetchedDocument = convertApiDocumentToViewableInstance(fetchedDocument);
        setMtfeDocument(fetchedDocument);
      };

      void loadDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, requestedDocVersion, currentUser]);

  useEffect(() => {
    const loadCollaborationToken = async (permissions: number) => {
      const token = await tokenApi.fetch({ comments: permissions });
      setCollaborationToken(token);
    };

    if (currentUser && mtfeDocument && commentsPermission === undefined) {
      const currentCommentPermissions = getUserCommentsPermission(
        currentUser,
        mtfeDocument,
      );
      setCommentsPermission(currentCommentPermissions);
      void loadCollaborationToken(currentCommentPermissions);
    }
  }, [currentUser, mtfeDocument, commentsPermission]);

  useEffect(() => {
    return () => {
      if (context) {
        if (context.plugins.has('WebSocketGateway')) {
          const webSocketGateway = context.plugins.get('WebSocketGateway');
          webSocketGateway?.destroy();
        }
        context.destroy();
      }
    };
  }, [context]);

  if (
    !mtfeDocument ||
    !currentUser ||
    commentsPermission === undefined ||
    commentsPermission === null ||
    !collaborationToken
  ) {
    return <LoadingSpinner message={'Loading document'} />;
  }

  const isEditingSessionLoading = !editors;

  return (
    <>
      {isEditingSessionLoading && (
        <DocumentHTMLViewer
          viewDocument={mtfeDocument}
          showSidebar={true}
          showTOC={true}
          setLoaded={false}
        />
      )}
      <div className={`toolbarWrapper`} id="toolbarWrapper"></div>
      <DocumentEditor
        editorDocument={mtfeDocument}
        companyId={currentUser.companyId}
        currentUser={currentUser}
        editorsReadyCallback={setEditors}
        contextReadyCallback={setContext}
        commentsPermission={commentsPermission}
        handleAutoSaveError={handleError}
        handleAutoSaveSuccess={handleSuccess}
        collaborationToken={collaborationToken}
      />
    </>
  );
};
