import { QBadge } from '@qualio/ui-components';
import { QualioDocument } from '../../../api/model/document';

type DocumentCodeCellProperties = {
  documentCode: QualioDocument['code'];
};

export const DocumentCodeCell: React.FC<DocumentCodeCellProperties> = ({
  documentCode,
}) => {
  return (
    <QBadge data-cy={`table-doc-code-${documentCode}`}>{documentCode}</QBadge>
  );
};
