import React, { useCallback, useMemo } from 'react';

import {
  QMenuButton,
  QMenuItemType,
  QualioQThemeProvider,
} from '@qualio/ui-components';

import { QualioDocumentSection } from '../../../api/model/document';
import { ChangeControlSection } from '../../../types/DocumentCreate';
import { navigateToSection } from '../../../util/DocumentNavigationUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import styles from './TableOfContents.module.css';

export const TableOfContents: React.FC<{
  sections: QualioDocumentSection[] | ChangeControlSection[];
  insideFroalaDocumentViewer?: boolean;
  sectionTitlesVisible: boolean;
}> = ({
  sections,
  insideFroalaDocumentViewer = false,
  sectionTitlesVisible,
}) => {
  const handleItemClick = useCallback((item: QMenuItemType) => {
    const index = parseInt(item.id) - 1;
    navigateToSection(index);
  }, []);

  const TOCItems: QMenuItemType[] = useMemo(
    () =>
      DocumentPresentationUtils.getDocumentSectionsTitles(sections).map(
        (title, idx) => ({
          id: title.split('.')[0],
          label: title,
          'data-testid': `toc-element-${idx + 1}`,
        }),
      ),
    [sections],
  );

  return (
    <div
      id="documentTocControl"
      data-testid="documentTocControl"
      className={`${
        !insideFroalaDocumentViewer
          ? styles['document-editor__document__toc-control']
          : styles['document-froala-viewer__document__toc-control']
      }`}
    >
      {sectionTitlesVisible && (
        <QualioQThemeProvider>
          <QMenuButton
            items={TOCItems}
            onItemClick={handleItemClick}
            variant="icon"
            buttonLabel=""
            aria-label="toc-button"
            iconName="Menu"
            data-cy="toc-button"
          ></QMenuButton>
        </QualioQThemeProvider>
      )}
    </div>
  );
};
