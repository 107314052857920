import { Plugin } from '@ckeditor/ckeditor5-core';
import { ExportPdfConfig } from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
import { MentionConfig } from '@ckeditor/ckeditor5-mention';
import { BaseSuggestionThreadView } from '@ckeditor/ckeditor5-track-changes';
import { medtechApiClient, MEDTECH_UPLOAD_URL } from '../api';
import { PageOrientation } from '../api/model/document';
import { tokenApi } from '../api/token';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { formatDate } from '../util/DateUtils';
import { qualioCommentThreadBuilder } from '../views/ckeditor/QualioCommentThreadView';
import QualioCommentView from '../views/ckeditor/QualioCommentView';
import { CKEditorConfig } from './CKEditorConfig';
import {
  AI_COMMANDS,
  IMAGE_TOOLBAR_ITEMS,
  TABLE_TOOLBAR_ITEMS,
} from './ToolbarItems';

/**
 * https://docs.aws.amazon.com/bedrock/latest/userguide/model-ids.html
 */
enum AWSBedrockModelId {
  INSTANT = 'anthropic.claude-instant-v1',
  V2_1 = 'anthropic.claude-v2:1',
  V3_SONNET = 'anthropic.claude-3-sonnet-20240229-v1:0',
  V3_HAIKU = 'anthropic.claude-3-haiku-20240307-v1:0',
}

export class DocumentEditorConfigFactory {
  editorConfig: CKEditorConfig;

  constructor() {
    this.editorConfig = {
      fontFamily: {
        options: ['default'],
      },
      fontSize: {
        options: [
          'small',
          {
            title: 'Normal',
            model: '14px',
            upcastAlso: [],
            view: '',
          },
          {
            title: 'Large',
            model: '19.6px',
            upcastAlso: [],
            view: '',
          },
        ],
      },
      simpleFileUpload: {
        url: MEDTECH_UPLOAD_URL,
      },
      simpleUpload: {
        uploadUrl: MEDTECH_UPLOAD_URL,
        withCredentials: true,
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_p',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_h1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_h2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_h3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_h4',
          },
        ],
      },
      list: {
        properties: {
          styles: true,
          startIndex: false,
          reversed: false,
        },
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
      },
      table: {
        contentToolbar: TABLE_TOOLBAR_ITEMS,
      },
      image: {
        toolbar: IMAGE_TOOLBAR_ITEMS,
      },
      licenseKey: process.env.REACT_APP_CKE_PREMIUM_FEATURES_LICENSE_KEY,
      indentBlock: {
        offset: 2,
        unit: 'em',
      },
      orientation: PageOrientation.Portrait,
      locale: {
        dateTimeFormat: formatDate,
      },
    };
  }

  getConfig = () => {
    return this.editorConfig;
  };

  withAI = (companyId: number) => {
    const apiProxyModeEnabled = true;

    this.editorConfig.ai = {
      aws: apiProxyModeEnabled
        ? {
            apiUrl: `${process.env.REACT_APP_AI_API_URL}/ckeditor-proxy`,
            requestParameters: {
              model: AWSBedrockModelId.V2_1,
              stream: false,
            },
            requestHeaders: async () => {
              const jwt = (
                await medtechApiClient.post<string>(
                  `${companyId}/auth/token/service/qualio-ai`,
                )
              ).data;
              return {
                Authorization: jwt,
              };
            },
          }
        : {
            // https://ckeditor.com/docs/ckeditor5/latest/api/module_ai_adapters_awstextadapter-AWSTextAdapterConfig.html#member-requestParameters
            requestParameters: {
              model: AWSBedrockModelId.V2_1,
              max_tokens_to_sample: 500,
              temperature: 1,
              top_k: 250,
              top_p: 1,
              anthropic_version: 'bedrock-2023-05-31',
              stream: true,
            },
            bedrockClientConfig: {
              region: 'us-east-1',
              credentials: {
                sessionToken: process.env.REACT_APP_AWS_SESSION_TOKEN ?? '',
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '',
                secretAccessKey:
                  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? '',
              },
            },
          },
      useTheme: false,
    };
    this.editorConfig.ai.aiAssistant = {
      commands: AI_COMMANDS,
    };

    return this;
  };

  withCompanyId = (companyId: number) => {
    this.editorConfig.companyId = companyId;
    return this;
  };

  withPlugins = (plugins: (typeof Plugin)[]) => {
    this.editorConfig.plugins = plugins;
    return this;
  };

  withSectionId = (sectionId: number) => {
    this.editorConfig.sectionId = sectionId;
    return this;
  };

  withDocumentId = (documentId: number) => {
    this.editorConfig.documentId = documentId;
    return this;
  };

  withSectionPosition = (position: number) => {
    this.editorConfig.position = position;
    return this;
  };

  withEntityType = (entityType: string) => {
    this.editorConfig.entityType = entityType;
    return this;
  };

  withAutosave = (onAutoSave: (editor: CKEditorInstance) => Promise<void>) => {
    this.editorConfig.autosave = {
      save: onAutoSave,
      waitingTime: 1000,
    };
    return this;
  };

  withMention = (mentionConfig: MentionConfig) => {
    this.editorConfig.mention = mentionConfig;
    return this;
  };

  withCollaboration = (channelIDSuffix: string) => {
    this.editorConfig.collaboration = {
      channelId: `${process.env.REACT_APP_ENV}${
        process.env.REACT_APP_CHANNEL_ID_SUFFIX ?? ''
      }-${channelIDSuffix}`,
    };
    return this;
  };

  withPDFExport = (exportPdfConfig: ExportPdfConfig) => {
    this.editorConfig.exportPdf = {
      ...exportPdfConfig,
      converterUrl: process.env.REACT_APP_CKE_PDF_SERVICE_URL,
      tokenUrl: () => tokenApi.fetch(),
    };
    return this;
  };

  withComments = (commentsPermission: number, documentStatus: string) => {
    const commentThreadView = qualioCommentThreadBuilder(
      commentsPermission,
      documentStatus,
    );
    this.editorConfig.comments = {
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_comments_comments-CommentsConfig.html
      editorConfig: {},
      CommentView: QualioCommentView as any,
      CommentThreadView: commentThreadView as any,
    };
    return this;
  };

  withTrackChanges = (suggestionThreadView: BaseSuggestionThreadView) => {
    this.editorConfig.trackChanges = {
      SuggestionThreadView: suggestionThreadView as any,
      disableComments: false,
      trackFormatChanges: 'default',
      mergeNestedSuggestions: true,
    };
    return this;
  };

  withToolbarItems = (toolbarItems: string[]) => {
    this.editorConfig.toolbar = toolbarItems;
    return this;
  };

  withLists = (customStartIndexes: boolean) => {
    this.editorConfig.list = {
      properties: {
        styles: true,
        startIndex: customStartIndexes,
        reversed: customStartIndexes,
      },
    };

    return this;
  };
}
