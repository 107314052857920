import { deprecated, QBox, QCheckbox, QStack } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchAllResult } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { ClickPropagationBarrier } from '../ClickPropagationBarrier';
import { DocumentCodeCell } from '../DocumenCodeCell/DocumentCodeCell';
import { DocumentStatusCell } from '../DocumentStatusCell/DocumentStatusCell';
import { DocumentTagsCell } from '../DocumentTagsCell/DocumentTagsCell';
import styles from './DocumentList.module.css';
import { SortCallbackObj } from './SortUtils';

type CellRenderProps<T extends keyof QualioDocument> = {
  row: {
    original: QualioDocument;
  };
  cell: {
    value: T extends keyof QualioDocument ? QualioDocument[T] : never;
  };
};

export interface DocumentListProps {
  fetchedTags: Tag[];
  filteredDocs: FetchAllResult;
  manualPagination: deprecated.QManualPagination;
  selectedDocumentsMap: Map<QualioDocument['id'], QualioDocument>;
  setSelectedDocumentsMap: (
    selectedDocuments: Map<QualioDocument['id'], QualioDocument>,
  ) => void;
  sortCallback: (sortingRule: SortCallbackObj[]) => void;
  sortingRules: SortCallbackObj[];
  hideCheckboxColumn?: boolean;
}

export const DocumentList = ({
  fetchedTags,
  filteredDocs,
  manualPagination,
  selectedDocumentsMap,
  setSelectedDocumentsMap,
  sortCallback,
  sortingRules,
  hideCheckboxColumn = false,
}: DocumentListProps) => {
  const { qualioFrontendRefresh } = useFlags();
  const navigate = useNavigate();
  const allDocumentsSelectedOnPage = useMemo(() => {
    return (
      !!filteredDocs.documents.length &&
      filteredDocs.documents.every((document) =>
        selectedDocumentsMap.has(document.id),
      )
    );
  }, [selectedDocumentsMap, filteredDocs]);

  const someDocumentsSelectedOnPage = useMemo(() => {
    return filteredDocs.documents.some((document) =>
      selectedDocumentsMap.has(document.id),
    );
  }, [selectedDocumentsMap, filteredDocs]);

  const handleSelectAllDocuments = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked && !someDocumentsSelectedOnPage) {
        const allDocumentsSelectedMap = new Map(
          filteredDocs.documents.map((doc) => {
            return [doc.id, doc];
          }),
        );
        setSelectedDocumentsMap(
          new Map([...selectedDocumentsMap, ...allDocumentsSelectedMap]),
        );
      } else {
        const deselectedDocuments = new Map(
          filteredDocs.documents.map((doc) => {
            return [doc.id, doc];
          }),
        );

        setSelectedDocumentsMap(
          new Map(
            Array.from(selectedDocumentsMap).filter(
              ([id]) => !deselectedDocuments.has(id),
            ),
          ),
        );
      }
    },
    [
      filteredDocs.documents,
      selectedDocumentsMap,
      setSelectedDocumentsMap,
      someDocumentsSelectedOnPage,
    ],
  );

  const handleSelectDocument = useCallback(
    (document: QualioDocument, e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedSelectedDocumentsMap = new Map(selectedDocumentsMap);
      if (e.currentTarget.checked) {
        updatedSelectedDocumentsMap.set(document.id, document);
      } else {
        updatedSelectedDocumentsMap.delete(document.id);
      }
      setSelectedDocumentsMap(updatedSelectedDocumentsMap);
    },
    [selectedDocumentsMap, setSelectedDocumentsMap],
  );

  const columns = useMemo(() => {
    const selectedColumns = [
      {
        id: 'DocumentsHeaderCheckbox',
        Header: (
          <span className={`${styles['table-checkbox']}`}>
            <QCheckbox
              data-cy="documentsHeaderCheckbox"
              aria-label="documents header checkbox"
              isChecked={allDocumentsSelectedOnPage}
              isIndeterminate={
                !allDocumentsSelectedOnPage && someDocumentsSelectedOnPage
              }
              onChange={handleSelectAllDocuments}
            />
          </span>
        ),
        accessor: 'id',
        Cell: ({ row: { original: document } }: CellRenderProps<'id'>) => {
          return (
            <ClickPropagationBarrier className={`${styles['table-checkbox']}`}>
              <QCheckbox
                data-cy={`document-checkbox-${document.code}`}
                aria-label={`document checkbox ${document.id}`}
                isChecked={selectedDocumentsMap.has(document.id)}
                onChange={(e) => {
                  handleSelectDocument(document, e);
                }}
              />
            </ClickPropagationBarrier>
          );
        },
        width: '2%',
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'code',
        Cell: ({ cell: { value: code } }: CellRenderProps<'code'>) => (
          <DocumentCodeCell documentCode={code} />
        ),
        width: '8%',
      },
      {
        Header: 'Title',
        accessor: (document: QualioDocument) => (
          <span data-cy={`table-doc-${document.code}-title`}>
            {document.title}
          </span>
        ),
        width: '20%',
      },
      {
        Header: 'Type',
        accessor: (document: QualioDocument) => (
          <span data-cy={`table-doc-${document.code}-type`}>
            {document.type}
          </span>
        ),
        width: '15%',
      },
      {
        Header: 'Owner',
        accessor: (document: QualioDocument) => document.owner,
        width: '15%',
      },
      {
        Header: 'Tags',
        accessor: 'tag_ids',
        Cell: ({ cell: { value: tagIds } }: CellRenderProps<'tag_ids'>) => (
          <DocumentTagsCell tagIds={tagIds} availableTags={fetchedTags} />
        ),
        width: '20%',
        disableSortBy: true,
      },
      {
        Header: 'Version',
        accessor: (document: QualioDocument) =>
          `${document.major_version}.${document.minor_version}`,
        width: '5%',
      },
      {
        Header: 'Status',
        accessor: 'status_id',
        Cell: ({ cell: { value: statusId } }: CellRenderProps<'status_id'>) => (
          <DocumentStatusCell statusId={statusId as DocumentStatus} />
        ),
        width: '15%',
      },
    ];
    if (hideCheckboxColumn) {
      selectedColumns.shift();
    }
    return selectedColumns;
  }, [
    fetchedTags,
    handleSelectAllDocuments,
    handleSelectDocument,
    allDocumentsSelectedOnPage,
    someDocumentsSelectedOnPage,
    selectedDocumentsMap,
    hideCheckboxColumn,
  ]);

  return (
    <>
      <QStack>
        <QBox
          color="gray.500"
          fontWeight="semibold"
          pt="6"
          data-cy={'workspace-search-result-summary'}
          fontSize="sm"
        >
          {filteredDocs.total} document{filteredDocs.total > 1 ? 's' : ''}
        </QBox>
      </QStack>
      <deprecated.QDataTable
        manualPagination={manualPagination}
        columns={columns}
        data={filteredDocs.documents as any}
        onRowClick={(clicked) => {
          if (qualioFrontendRefresh) {
            navigate(`/workspace/documents/${clicked.id}`);
          } else {
            window.location.assign(`/workspace/documents/${clicked.id}`);
          }
        }}
        hasPagination={true}
        hasPageSizeOptions={true}
        manualSortBy={{
          sortByCallback: sortCallback,
          defaultSortByColumn: sortingRules,
        }}
      />
    </>
  );
};
