import { useAnalytics, useCurrentUser } from '@qualio/ui-components';
import { forwardRef, useCallback, useContext, useState } from 'react';
import { QualioDocument } from '../../../../api/model/document';
import { analyticsHandler } from '../../../../util/Analytics/AnalyticsUtils';
import { isOwner } from '../../../../util/CurrentUser';
import { CollabServerConnectionManagerV2 } from '../../CollabServerConnectionManagerV2/index';
import { DocumentHTMLViewer } from '../../DocumentHTMLViewer/DocumentHTMLViewer';
import { FileDocumentEditor } from '../../FileDocumentEditor/index';
import { InlineAttachmentPreviewModal } from '../../InlineAttachmentPreview/InlineAttachmentPreviewModal';
import { DocumentOverviewContext } from '../Context';
import { DocEditorSideBarWrapper } from '../DocumentEditorV2/DocumentContentEditor/DocEditorSideBarWrapper';
import { DocumentSectionsManagerV2 } from '../DocumentEditorV2/DocumentContentEditor/DocumentSectionsManagerV2/DocumentSectionsManagerV2';
import { shouldShowFileUpload } from '../RenderLogic';

enum ContentComponentName {
  CKE_EDITOR,
  HTML_VIEWER,
  FILE_UPLOAD,
}

export const DocumentVariantRenderer = forwardRef<HTMLDivElement>((_, ref) => {
  const analytics = useAnalytics();
  const [attachmentForPreview, setAttachmentForPreview] = useState<string>();
  const [didReceiveDisconnectionEvent, setDidReceiveDisconnectionEvent] =
    useState<boolean>(false);
  const currentUser = useCurrentUser();
  const handleSessionDisconnectionEvent = useCallback(() => {
    setDidReceiveDisconnectionEvent(true);
  }, []);
  const showAttachmentForPreviewCallback = useCallback(
    (attachmentId: string) => {
      analyticsHandler(
        'Document Editor | Inline attachment preview opened',
        analytics,
      );
      setAttachmentForPreview(attachmentId);
    },
    [analytics],
  );

  const { qualioDocument, commentsPermission } = useContext(
    DocumentOverviewContext,
  );

  const determineContentComponentToShow = (
    commentsPermission: number,
    qualioDocument: QualioDocument,
  ): ContentComponentName => {
    if (shouldShowFileUpload(qualioDocument)) {
      return ContentComponentName.FILE_UPLOAD;
    }

    const hasEditPermission = commentsPermission > 0;
    if (
      !hasEditPermission ||
      qualioDocument.status_id === 'deleted' ||
      qualioDocument.status_id === 'effective' ||
      !qualioDocument.is_most_recent_version
    ) {
      return ContentComponentName.HTML_VIEWER;
    } else {
      return ContentComponentName.CKE_EDITOR;
    }
  };

  const componentToShow = determineContentComponentToShow(
    commentsPermission,
    qualioDocument,
  );

  return (
    <>
      {attachmentForPreview && (
        <InlineAttachmentPreviewModal
          currentUser={currentUser}
          attachmentId={attachmentForPreview}
          onClose={() => setAttachmentForPreview(undefined)}
        />
      )}
      {componentToShow === ContentComponentName.FILE_UPLOAD && (
        <FileDocumentEditor
          currentUser={currentUser}
          fileDocument={qualioDocument}
          isOwner={isOwner(currentUser, qualioDocument)}
          commentsPermission={commentsPermission ?? 0}
        />
      )}
      {componentToShow === ContentComponentName.HTML_VIEWER && (
        <DocumentHTMLViewer
          viewDocument={qualioDocument}
          showTOC={true}
          showSidebar={false}
          setLoaded={true}
          handleAttachmentClick={showAttachmentForPreviewCallback}
        />
      )}
      {componentToShow === ContentComponentName.CKE_EDITOR && (
        <>
          <DocEditorSideBarWrapper ref={ref}>
            <DocumentSectionsManagerV2
              handleAttachmentClick={showAttachmentForPreviewCallback}
              handleSessionDisconnectionEvent={handleSessionDisconnectionEvent}
            />
          </DocEditorSideBarWrapper>
          <CollabServerConnectionManagerV2
            didReceiveDisconnectionEvent={didReceiveDisconnectionEvent}
          />
        </>
      )}
    </>
  );
});
