import { QStack, QTag } from '@qualio/ui-components';
import React from 'react';
import { QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';

type DocumentTagsCellProps = {
  tagIds: QualioDocument['tag_ids'];
  availableTags: readonly Tag[];
};

const getClippedTagTitles = (tags: Tag[]) => {
  return tags
    .slice(2)
    .map((t) => t.name)
    .join(', ');
};

export const DocumentTagsCell: React.FC<DocumentTagsCellProps> = ({
  tagIds,
  availableTags,
}) => {
  const tags = availableTags.filter((tag) => tagIds.includes(tag.id));
  let renderedTags = tags.map((tag) => {
    return (
      <QTag key={tag.id} rightIcon={tag.group_ids.length ? 'Lock' : undefined}>
        {tag.name}
      </QTag>
    );
  });
  if (tags.length > 2) {
    renderedTags = renderedTags.slice(0, 2);
    renderedTags = renderedTags.concat(
      <QTag key={'clipped-tags'}>
        <div title={getClippedTagTitles(tags)}>{'...'}</div>
      </QTag>,
    );
  }

  return (
    <QStack direction="row" spacing={3}>
      {renderedTags}
    </QStack>
  );
};
