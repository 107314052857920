import { QEmptyState, QStack } from '@qualio/ui-components';

export const TrainingAssessmentErrorState = () => {
  return (
    <QStack
      data-testid="training-assessment-error-state"
      align="center"
      marginTop="100"
      width="648px"
    >
      <QEmptyState
        subtitle="Please refresh the page. If the issue continues, contact your manager at
        Qualio Support."
        title="Oops! Looks like we were unable to load the tab."
      />
    </QStack>
  );
};
