import { Context } from '@ckeditor/ckeditor5-core';

import { CKEditorUtils } from '../../util/CKEditorUtils';
import {
  configureBalloonPanels,
  configureQualioPluginsForTrackChanges,
  EditorMode,
  enableUploadAdapter,
  isCommentsOnlyMode,
  setCommentsOnlyModeTo,
  toggleCheckboxMode,
  toggleTrackChanges,
} from '../../util/EditModeUtils';
import {
  calculatePageOffsetY,
  extractAttachmentIdFromHref,
  getHTMLElement,
  setElementDisabledState,
} from '../../util/HTMLElementUtils';
import { ToolbarManager } from '../../util/ToolbarManager';

import { ViewElement } from '@ckeditor/ckeditor5-engine';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../api/document';
import { QualioDocument } from '../../api/model/document';
import MessageSquarePlus from '../../assets/MessageSquarePlus.svg';
import { CKEditorInstance } from '../../types/CKEditorInstance';
import { configureAnalytics } from '../../util/Analytics/AnalyticsUtils';
import { retryOnError } from '../../util/APIUtils';
import { getDocumentEditorGlobalWindowObject } from '../../util/AppUtils';
import {
  buildAttachmentsErrorMessage,
  isAttachmentError,
} from '../../util/ErrorManagementUtils';
import { refreshDisplayMode } from '../../util/SidebarUtils';
import { saveSectionFromEditor } from '../../views/reducers/DocumentReducer';
import { handleComment } from './CommentEventHandler';
import { extractDocumentIdFromChannelId } from './eventHandlerUtil';
import {
  notifyIFrame,
  notifyParent,
  registerIFrameMessageHandler,
} from './IFrameMessageHandler';
import { setupSuggestionUpdateHandler } from './SuggestionEventHandler';

const RETRIES = 3;

let currentWindowResizeListener: () => void | undefined;

export const registerParentWindowResizeListener = () => {
  window.addEventListener('resize', () => {
    notifyIFrame({
      type: 'resize',
      content: { parentWidth: window.innerWidth },
    });
  });
};

export const deregisterWindowResizeListener = () => {
  if (currentWindowResizeListener) {
    window.removeEventListener('resize', currentWindowResizeListener);
  }
};

export const setupClickListener = (editors: CKEditorInstance[]): void => {
  editors.forEach((editor) => {
    editor.editing.view.document.on('click', handleClick(editor));
    editor.editing.view.document.on(
      'selectionChangeDone',
      handleSelectionChange(editor),
    );
  });
};

const handleClick =
  (editor: CKEditorInstance) =>
  (
    evt: any,
    data: {
      target?: ViewElement;
      domEvent?: PointerEvent;
    },
  ) => {
    const clickedViewElement = data.target;
    if (!clickedViewElement) {
      return;
    }
    if (clickedViewElement.is('$text')) {
      return;
    }
    const href = clickedViewElement?.getAttribute('href');
    const isCommentOrReadOnlyEditor =
      isCommentsOnlyMode(editor) || editor.isReadOnly;
    // open links in a new tab in comment or read-only mode
    if (clickedViewElement.name === 'a' && href && isCommentOrReadOnlyEditor) {
      data.domEvent?.preventDefault();
      data.domEvent?.stopPropagation();
      handleLinkClick(href);
      return;
    }
    // open/download links/attachments that are commented on and/or are styled spans or bold
    if (
      (clickedViewElement.name === 'span' ||
        clickedViewElement.name === 'strong' ||
        clickedViewElement.name === 'i' ||
        clickedViewElement.name === 's' ||
        clickedViewElement.name === 'u') &&
      isCommentOrReadOnlyEditor
    ) {
      data.domEvent?.preventDefault();
      data.domEvent?.stopPropagation();
      openPotentiallyClickedAnchor(clickedViewElement);
      return;
    }
  };

const handleSelectionChange = (editor: CKEditorInstance) => () => {
  // disable comment button when input is only selected element
  const selectedElement =
    editor.editing.view.document.selection.getSelectedElement();
  if (selectedElement?.name === 'input') {
    const addCommentThreadCommand = editor.commands.get('addCommentThread');
    addCommentThreadCommand?.fire('change:isEnabled', false);
  }
};

const openPotentiallyClickedAnchor = (
  clickedViewElement: ViewElement,
): void => {
  const href = CKEditorUtils.getFirstParentHref(clickedViewElement);
  if (!href) {
    return;
  }
  handleLinkClick(href);
};

const handleLinkClick = (href: string): void => {
  const attachmentId = extractAttachmentIdFromHref(href);
  if (attachmentId) {
    // open inline attachment links in preview modal
    notifyParent({
      type: 'attachment-preview',
      content: {
        attachmentId: attachmentId,
      },
    });
  } else {
    // open links that are not inline attachments in a new tab in comment- or read-only mode
    window.open(href, '_blank');
  }
};

export const setupCommentButton = (
  currentUser: CurrentUser,
  editors: CKEditorInstance[],
  commentsPermissions: number,
): void => {
  const commentButton = getHTMLElement('div#documentCommentControl button');
  const commentButtonParagraph = getHTMLElement('div#documentCommentControl p');
  const commentDiv = getHTMLElement('div#documentCommentControl');
  // we do not have to set up the button for adding comments if a user has read-only permissions for commenting
  if (commentsPermissions < 2) {
    return;
  }
  commentDiv?.classList.remove('hidden');
  if (commentButton && commentButtonParagraph) {
    commentButton.innerHTML = MessageSquarePlus;
    setElementDisabledState(commentButton, true);
    const editorContent = getHTMLElement('#documentEditorContent');
    const toolbar = getHTMLElement('#toolbarWrapper');
    editorContent?.addEventListener('mouseup', (event: any) => {
      const offsetY =
        event.clientY -
        editorContent.getBoundingClientRect().top +
        editorContent.scrollTop;
      commentButtonParagraph.style.marginTop =
        Math.max(
          offsetY +
            calculatePageOffsetY(editorContent) -
            (toolbar?.clientHeight ?? 0) -
            commentButtonParagraph.clientHeight / 2,
          20,
        ) + 'px';
    });
    editors.forEach((editor, index) => {
      const addCommentThreadCommand = editor.commands.get('addCommentThread');
      addCommentThreadCommand?.on(
        'change:isEnabled',
        (_eventInfo, _name, value) => {
          setElementDisabledState(commentButton, !value);
          commentButton.setAttribute('data-index', index.toString());
        },
      );

      editor.ui?.view.listenTo(commentButton, 'click', () => {
        if (commentButton.getAttribute('data-index') === index.toString())
          addCommentThreadCommand?.execute();
      });

      const commentsRepository = editor.plugins.get(
        'CommentsRepository',
      ) as any;
      commentsRepository?.on(
        'addComment',
        (evt: EventInfo, data: CommentsRepository.CommentData) => {
          handleComment(evt, data, editor.model.markers, currentUser);
        },
        {
          priority: 'highest',
        },
      );

      commentsRepository?.on(
        'resolveCommentThread',
        handleRemoveCommentThread,
        { priority: 'highest' },
      );

      commentsRepository?.on('removeCommentThread', handleRemoveCommentThread, {
        priority: 'highest',
      });
    });
  }
};

/**
 * @description Handles events for removing/resolving comment threads
 */
export function handleRemoveCommentThread(
  _evt: EventInfo,
  data: CommentsRepository.RemoveCommentThreadData,
) {
  const channelId = data.channelId;
  const companyId = extractCompanyIdFromChannelId(channelId);
  const documentId = extractDocumentIdFromChannelId(channelId);

  documentApi
    .setDeletedByUserCommentThreads(
      Number(companyId),
      documentId,
      data.threadId,
      channelId,
    )
    .catch((error) => {
      retryOnError(
        () =>
          documentApi.setDeletedByUserCommentThreads(
            Number(companyId),
            documentId,
            data.threadId,
            channelId,
          ),
        200,
        error,
        RETRIES,
      );
    });
}

function extractCompanyIdFromChannelId(channelId: string) {
  const companySearchStrLength = 8;
  return channelId.substring(
    channelId.indexOf('company-') + companySearchStrLength,
    channelId.indexOf('-document'),
  );
}

function mathCharactersExtended(editor: CKEditorInstance): void {
  const scp = editor.plugins.get('SpecialCharacters');
  scp.addItems('Mathematical', [{ title: 'delta', character: 'Δ' }]);
}

export const enableCKEditorMode = (
  mode: EditorMode,
  editors: CKEditorInstance[],
  toolbarManager: ToolbarManager,
  inEditModeCallback: (inEditMode: boolean) => void,
) => {
  const inEditMode = mode !== EditorMode.VIEW;

  toggleCheckboxMode(mode === EditorMode.EDIT);
  setCommentsOnlyModeTo(editors, !inEditMode);
  inEditModeCallback(inEditMode);

  if (inEditMode) {
    // focus and show first editor
    editors[0].focus();
    toolbarManager.setToolbarFromEditor(editors[0]);
    toggleTrackChanges(editors, mode);
  } else {
    editors.forEach((editor) => {
      toolbarManager.hideToolbar(editor);
    });
  }
};

export const editorReadyHandler = (
  editor: CKEditorInstance,
  toolbarManager: ToolbarManager,
  smartlinkEverythingEnabled: boolean,
) => {
  setCommentsOnlyModeTo([editor], true);

  toolbarManager.configureEditorToShowToolbarOnFocus(editor);
  toolbarManager.hideToolbar(editor);
  CKEditorUtils.configureEditorDelete(editor);
  enableUploadAdapter(editor);
  configureAnalytics(editor);
  configureQualioPluginsForTrackChanges(editor);
  mathCharactersExtended(editor);
  editor.model.schema.setAttributeProperties('htmlAttributes', {
    isFormatting: true,
  });
  const pendingActions = editor.plugins.get('PendingActions');
  if (pendingActions) {
    pendingActions.on('change:hasAny', (evt, propertyName, newValue) => {
      if (newValue) {
        notifyParent({
          type: 'pending-changes',
          content: {
            hasPendingActions: true,
            editingActions: true,
          },
        });
      } else {
        notifyParent({
          type: 'pending-changes',
          content: {
            hasPendingActions: false,
            editingActions: true,
          },
        });
      }
    });
  }
  configureBalloonPanels(editor);
};

export const createContextReadyHandler = (
  currentUser: CurrentUser,
  readyHandler: (editors: CKEditorInstance[]) => void,
  commentsPermissions: number,
  inEditModeCallback: (inEditMode: boolean) => void,
  toolbarManager: ToolbarManager,
  smartlinkEverythingEnabled: boolean,
) => {
  return (context: Context) => {
    const { editors } = context;
    const editorsArr: CKEditorInstance[] = Array.from(editors) as any[];
    const editModeCallback = () =>
      enableCKEditorMode(
        EditorMode.EDIT,
        editorsArr,
        toolbarManager,
        inEditModeCallback,
      );
    const viewModeCallback = () =>
      enableCKEditorMode(
        EditorMode.VIEW,
        editorsArr,
        toolbarManager,
        inEditModeCallback,
      );
    const trackChangesModeCallback = () =>
      enableCKEditorMode(
        EditorMode.TRACKCHANGES,
        editorsArr,
        toolbarManager,
        inEditModeCallback,
      );
    readyHandler(editorsArr);
    registerIFrameMessageHandler({
      resizeCallback: (parentWidth: number) =>
        refreshDisplayMode({ instances: editorsArr, windowWidth: parentWidth }),
      trackChangesModeCallback,
      editModeCallback,
      viewModeCallback,
    });
    refreshDisplayMode({
      instances: editorsArr,
      windowWidth: window.innerWidth + 200,
    });
    setupCommentButton(currentUser, editorsArr, commentsPermissions);
    // Need to indicate MTFE that the editor is loaded
    // to enable the edit and export button
    getDocumentEditorGlobalWindowObject().loaded = true;
    notifyParent({ type: 'initialized' });

    editorsArr.forEach((editor) => {
      editorReadyHandler(editor, toolbarManager, smartlinkEverythingEnabled);
    });
    setupSuggestionUpdateHandler(currentUser, editorsArr);
    setupClickListener(editorsArr);
    setupEditorConnectionClosedHandler(context);
  };
};

export const createEditorAutosaveHandler =
  (
    document: QualioDocument,
    handleAutoSaveError: (
      msg: string,
      sectionEditor?: CKEditorInstance,
      doc?: QualioDocument,
    ) => void,
    handleAutoSaveSuccess: () => void,
  ) =>
  async (editor: CKEditorInstance): Promise<void> => {
    await saveSectionFromEditor(
      document,
      editor,
      handleAutoSaveError,
      handleAutoSaveSuccess,
    );
  };

export const setupEditorConnectionClosedHandler = (context: Context) => {
  const webSocketGateway = context.plugins.get('WebSocketGateway') as any;
  webSocketGateway.on(
    'change:state',
    (eventInfo: any, _name: any, value: any, _oldValue: any) => {
      if (!eventInfo?.source?._isBrowserOffline && value === 'disconnected') {
        notifyParent({
          type: 'session-disconnected',
        });
      }
    },
  );
};

export const handleError = (
  msg: string,
  sectionEditor?: CKEditorInstance,
  document?: QualioDocument,
) => {
  const errorMsg = isAttachmentError(msg)
    ? buildAttachmentsErrorMessage(msg, sectionEditor, document)
    : msg;
  notifyParent({ type: 'error', content: { success: false, error: errorMsg } });
};

export const handleSuccess = () => {
  notifyParent({
    type: 'error',
    content: {
      success: true,
      lastUpdatedAt: getDocumentEditorGlobalWindowObject().lastUpdated,
    },
  });
};
