export const navigateToSection = (index: number) => {
  const sectionsHeadings = document.querySelectorAll(
    'h1.section-heading, h2.section-top-heading',
  );
  if (sectionsHeadings.length > 0 && index < sectionsHeadings.length) {
    sectionsHeadings[index].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
