import { DataProvider, useCurrentUser } from '@qualio/ui-components';

import { useSearchParams } from 'react-router-dom';
import { ExportDocumentListButton } from '../Export/ExportDocumentListButton/ExportDocumentListButton';

export const ExportButton = () => {
  const currentUser = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { data, isLoading } = DataProvider.useDataProvider();
  const tagsParams = searchParams.get('filteringTag');
  const orderParam = searchParams.get('order_by');
  const selectedTags = tagsParams !== null ? tagsParams.split(',') : [];

  return (
    <ExportDocumentListButton
      currentUser={currentUser}
      tagsFilter={selectedTags}
      isDisabled={isLoading || !data || data.length === 0}
      searchQuery={null}
      sortingValue={orderParam}
    />
  );
};
