import { QIcon, QStack } from '@qualio/ui-components';
import { QActivityText } from './QActivityText';

type QActivityTransitionProps = {
  oldValue: string;
  newValue: string;
};
export const QActivityTransition = ({
  oldValue,
  newValue,
}: QActivityTransitionProps) => {
  return (
    <QStack
      style={{ flexWrap: 'wrap', rowGap: '8px' }}
      spacing={0}
      direction="row"
      alignItems="center"
    >
      <QActivityText text={oldValue} weight="semibold" />
      <QStack paddingInline="8px">
        <QIcon iconName="ArrowRight" />
      </QStack>
      <QActivityText text={newValue} weight="semibold" />
    </QStack>
  );
};
