import { tokenApiClient } from '../api';
import { handleError } from '../config/handlers';
import { redirect } from '../util/PageUtils';

export const tokenApi = {
  async fetch(params?: { [key: string]: any }): Promise<string> {
    const searchParams = new URLSearchParams(params);
    return tokenApiClient
      .get('', { params: searchParams })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response?.status === 401) {
          redirect();
        } else {
          handleError(
            'Failed to get collaboration token reason was: ' + error.toString(),
          );
        }
      });
  },
};
