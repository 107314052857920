import {
  QDivider,
  QFlex,
  QIconButton,
  QualioQThemeProvider,
} from '@qualio/ui-components';

export const FileDocumentControls = ({
  currentPage,
  totalPages,
  handleNavigate,
  handleZoom,
  showDownloadButton,
  handleDownload,
  zoom,
  isPaginated,
  isImage,
  handlePagination,
  handlePrint,
  showCommentButton,
}: {
  currentPage?: number;
  totalPages?: number;
  handleNavigate?: (page: number) => void;
  handleZoom: (zoom: number) => void;
  showDownloadButton: boolean;
  handleDownload: () => void;
  zoom: number;
  isPaginated?: boolean;
  isImage?: boolean;
  handlePagination?: () => void;
  handlePrint?: () => void;
  showCommentButton: boolean;
}) => {
  const handleNavigateNext = () => {
    handleNavigate && handleNavigate(1);
  };

  const handleNavigatePrev = () => {
    handleNavigate && handleNavigate(-1);
  };

  const handleZoomIn = () => {
    handleZoom(0.1);
  };

  const handleZoomOut = () => {
    handleZoom(-0.1);
  };

  const paginateStyle = {
    transform: `${isPaginated ? '' : 'rotate(-90deg)'}`,
    display: `${isImage ? 'none' : 'block'}`,
  };

  return (
    <div
      id={'controls'}
      data-testid="previewControlWrapper"
      style={{ width: '100%' }}
    >
      <QualioQThemeProvider>
        <QFlex w="100%" justify="right">
          <QFlex hidden={!isPaginated}>
            <QIconButton
              data-cy="file-document-previous-page-button"
              data-testid="controlButtonPreviousPage"
              onClick={handleNavigatePrev}
              isDisabled={currentPage === 1}
              size="lg"
              iconName="ChevronLeft"
              aria-label="Previous Page"
            />
            <div
              style={{ lineHeight: '48px' }}
            >{`Page ${currentPage}/${totalPages}`}</div>
            <QIconButton
              data-cy="file-document-next-page-button"
              data-testid="controlButtonNextPage"
              onClick={handleNavigateNext}
              isDisabled={currentPage === totalPages}
              size="lg"
              iconName="ChevronRight"
              aria-label="Next Page"
            />
          </QFlex>
          <QIconButton
            data-cy="file-document-zoom-out-button"
            data-testid="controlButtonZoomOut"
            onClick={handleZoomOut}
            isDisabled={zoom < 0.2}
            size="lg"
            iconName="ZoomOut"
            aria-label="Zoom Out"
          />
          <QIconButton
            data-cy="file-document-zoom-in-button"
            data-testid="controlButtonZoomIn"
            onClick={handleZoomIn}
            isDisabled={zoom === 3.5}
            size="lg"
            iconName="ZoomIn"
            aria-label="Zoom In"
          />
          <div style={paginateStyle}>
            <QIconButton
              data-cy="file-document-toggle-pagination-button"
              data-testid="controlButtonTogglePagination"
              onClick={handlePagination}
              size="lg"
              iconName="Code"
              aria-label="Toggle Pagination"
            />
          </div>
          {showDownloadButton && (
            <QIconButton
              data-cy="file-document-download-button"
              data-testid="controlButtonHandleDownload"
              onClick={handleDownload}
              size="lg"
              iconName="Download"
              aria-label="Download"
            />
          )}
          {handlePrint && (
            <QIconButton
              data-cy="file-document-print-button"
              data-testid="controlButtonHandlePrint"
              onClick={handlePrint}
              size="lg"
              iconName="Printer"
              aria-label="Print"
            />
          )}
          {showCommentButton && (
            <QIconButton
              data-cy="file-document-comment-button"
              data-testid="commentButton"
              iconName={'MessageSquare'}
              aria-label="Comment"
              size="lg"
              isDisabled={true}
            />
          )}
        </QFlex>
        <QDivider mt="6" mb="6" />
      </QualioQThemeProvider>
    </div>
  );
};
