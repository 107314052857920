import {
  QButton,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStateSecondaryButtonGroup,
  QStack,
} from '@qualio/ui-components';
import { QEmptyStateProps } from '@qualio/ui-components/lib/QComponents/EmptyState/EmptyState';
import { useContext } from 'react';
import { EditorMode } from '../../../util/EditModeUtils';
import { DocumentOverviewContext } from '../DocumentOverview/Context';

type TrainingAssessmentEmptyStateProps = {
  onClick?: () => void;
};

export const TrainingAssessmentEmptyState: React.VFC<
  TrainingAssessmentEmptyStateProps
> = ({ onClick }) => {
  const { currentEditorMode } = useContext(DocumentOverviewContext);
  const isEditMode = currentEditorMode === EditorMode.EDIT;

  const emptyStateProps: QEmptyStateProps = {
    subtitle:
      'Training assessments can be created and edited when the document is in editing mode',
    title: 'No training assessment created.',
  };

  return (
    <QStack data-testid="training-assessment-empty-state" gap="4">
      {isEditMode ? (
        <div data-testid="create-training-assessment-button">
          <QEmptyState {...emptyStateProps}>
            <QEmptyStateButtonGroup>
              <QEmptyStateSecondaryButtonGroup>
                <QButton variant="outline" leftIcon="Plus" onClick={onClick}>
                  Create training assessment
                </QButton>
              </QEmptyStateSecondaryButtonGroup>
            </QEmptyStateButtonGroup>
          </QEmptyState>
        </div>
      ) : (
        <QEmptyState {...emptyStateProps} />
      )}
    </QStack>
  );
};
