import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { ProtectedRoute } from './ProtectedRoute';
import { BulkFileUploadRoot } from './views/components/BulkFileUpload/BulkFileUploadRoot';
import { DocumentEditorFrameRoot } from './views/components/DocumentEditorFrameRoot/DocumentEditorFrameRoot';
import { DocumentEditorRoot } from './views/components/DocumentEditorRoot/DocumentEditorRoot';
import { DocumentLibrary } from './views/components/DocumentLibrary/DocumentLibrary';
import { DocumentLibraryV2 } from './views/components/DocumentLibraryV2';
import { DocumentOverview } from './views/components/DocumentOverview/DocumentOverview';
import { DocumentWorkspace } from './views/components/DocumentWorkspace/DocumentWorkspace';
import { ErrorHandlerProvider } from './views/components/ErrorHandler';

import { AnalyticsWrapper } from './views/components/AnalyticsWrapper';
import RouteWatcher from './views/components/RouteWatcher/RouteWatcher';

import { QualioQThemeProvider, ToastProvider } from '@qualio/ui-components';
import { isInQualioFrontendApp } from './util/UrlUtils';
import { DocumentCompareVersions } from './views/components/DocumentCompareVersions';
import { TrainingAssessmentView } from './views/components/DocumentOverview/DocumentTraining';
import { DocumentWorkspaceV2 } from './views/components/DocumentWorkspace/DocumentWorkspaceV2';
import { NotFound } from './views/components/NotFound';

export const App: React.FC<{
  currentUser: CurrentUser;
}> = ({ currentUser }) => {
  const {
    documentLibraryUpdates,
    documentWorkspaceUpdates,
    qualioFrontendRefresh,
    useLegacyDomainForMTFE,
  } = useFlags();
  const inQualioFrontendApp = isInQualioFrontendApp(useLegacyDomainForMTFE);
  const routePaths = [
    '/document-editor/edit/:id',
    '/workspace/e/:id',
    '/workspace/e/:id/approve-pr',
    '/workspace/e/:id/training',
    '/workspace/documents/:id/approve-pr',
    '/workspace/documents/:id',
    '/workspace/documents/:id/training',
    '/library/documents/:id',
    '/library/documents/:id/training',
    '/library/e/:id',
    '/library/e/:id/training',
  ];

  const compareVersionsRoutePaths = [
    '/workspace/e/:id/compare/:baseCompare',
    '/workspace/documents/:id/compare/:baseCompare',
    '/library/documents/:id/compare/:baseCompare',
    '/library/e/:id/compare/:baseCompare',
    '/workspace/e/:id/training/compare/:baseCompare',
    '/workspace/documents/:id/training/compare/:baseCompare',
    '/library/documents/:id/training/compare/:baseCompare',
    '/library/e/:id/training/compare/:baseCompare',
  ];

  const trainingAssessmentRoutePaths = [
    '/workspace/e/:id/training/assessment',
    '/workspace/documents/:id/training/assessment',
    '/library/documents/:id/training/assessment',
    '/library/e/:id/training/assessment',
  ];

  const iframeRoute = '/iframe/document/:id';
  const fileUploadRoute = '/upload';
  const libraryRoute = '/library';
  const workspaceRoute = '/workspace';
  const appClasses = `${styles['document-editor__app']} ${
    !inQualioFrontendApp ? styles['document-editor__app__align'] : ''
  }`;
  return (
    <div id="documentEditorApp" className={appClasses}>
      <ErrorHandlerProvider>
        <Router>
          <RouteWatcher />
          <Routes>
            {compareVersionsRoutePaths.map((path, index) => {
              if (qualioFrontendRefresh || inQualioFrontendApp) {
                return (
                  <Route
                    key={'DocumentCompareVersions' + index}
                    path={path}
                    element={<DocumentCompareVersions />}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={path}
                    element={<DocumentEditorRoot currentUser={currentUser} />}
                  />
                );
              }
            })}
            {trainingAssessmentRoutePaths.map((path, index) => {
              if (qualioFrontendRefresh || inQualioFrontendApp) {
                return (
                  <Route
                    key={'TrainingAssessmentView' + index}
                    path={path}
                    element={
                      <QualioQThemeProvider>
                        <ToastProvider enableDelegation={true}>
                          <TrainingAssessmentView />
                        </ToastProvider>
                      </QualioQThemeProvider>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={path}
                    element={<DocumentEditorRoot currentUser={currentUser} />}
                  />
                );
              }
            })}
            {routePaths.map((path, index) => {
              if (qualioFrontendRefresh || inQualioFrontendApp) {
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <QualioQThemeProvider>
                        <ToastProvider enableDelegation={true}>
                          <DocumentOverview />
                        </ToastProvider>
                      </QualioQThemeProvider>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={path}
                    element={<DocumentEditorRoot currentUser={currentUser} />}
                  />
                );
              }
            })}
            <Route
              path={iframeRoute}
              element={
                <AnalyticsWrapper>
                  <DocumentEditorFrameRoot currentUser={currentUser} />
                </AnalyticsWrapper>
              }
            ></Route>
            <Route
              path={fileUploadRoute}
              element={<BulkFileUploadRoot currentUser={currentUser} />}
            ></Route>
            <Route
              path={libraryRoute}
              element={
                <ProtectedRoute
                  predicate={() => currentUser.permissions.can_view_library}
                >
                  {documentLibraryUpdates ? (
                    <QualioQThemeProvider>
                      <ToastProvider enableDelegation={true}>
                        <DocumentLibraryV2 />
                      </ToastProvider>
                    </QualioQThemeProvider>
                  ) : (
                    <DocumentLibrary currentUser={currentUser} />
                  )}
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path={workspaceRoute}
              element={
                <ProtectedRoute
                  predicate={() => currentUser.permissions.can_view_workspace}
                >
                  {documentWorkspaceUpdates ? (
                    <QualioQThemeProvider>
                      <ToastProvider enableDelegation={true}>
                        <DocumentWorkspaceV2 />
                      </ToastProvider>
                    </QualioQThemeProvider>
                  ) : (
                    <DocumentWorkspace currentUser={currentUser} />
                  )}
                </ProtectedRoute>
              }
            ></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </ErrorHandlerProvider>
    </div>
  );
};
