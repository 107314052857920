import { MedtechUserV2 } from '../user';

export type ActivityLogAction =
  | 'modified_quality_approvers'
  | 'modified_other_approvers'
  | 'modified_reviewers'
  | 'modified_doc_title'
  | 'modified_doc_owner'
  | 'modified_doc_review_period'
  | 'modified_doc_training_required'
  | 'modified_doc_effective_on_approval'
  | 'modified_doc_format'
  | 'modified_file_download_permission_group'
  | 'approved'
  | 'declined'
  | 'upgraded_document_template'
  | 'reviewed'
  | 'changed_status';

export type ActivityLogEntityType = 'document' | 'change_control';

export type ActivityLog = {
  entity_id: string;
  entity_type: ActivityLogEntityType;
  action: ActivityLogAction;
  timestamp: number;
  actor: MedtechUserV2 | null;
  data: any;
  key?: number;
};

type ActivityLogActionVerb =
  | 'modified'
  | 'approved'
  | 'declined'
  | 'upgraded'
  | 'reviewed';

type ActivityLogActionEntity =
  | 'Quality approvers'
  | 'Other approvers'
  | 'Reviewers'
  | 'Owner'
  | 'Title'
  | 'Review period'
  | 'Retraining required'
  | 'Effective on approval'
  | 'Format'
  | 'Download permissions'
  | 'Document'
  | 'Document template';

type ActivityLogActionToEntityAndVerb = {
  [key in ActivityLogAction]: {
    verb: ActivityLogActionVerb;
    entity: ActivityLogActionEntity;
  };
};

export const activityLogDetailMap: ActivityLogActionToEntityAndVerb = {
  modified_quality_approvers: { verb: 'modified', entity: 'Quality approvers' },
  modified_other_approvers: { verb: 'modified', entity: 'Other approvers' },
  modified_reviewers: { verb: 'modified', entity: 'Reviewers' },
  modified_doc_owner: { verb: 'modified', entity: 'Owner' },
  modified_doc_title: { verb: 'modified', entity: 'Title' },
  modified_doc_review_period: { verb: 'modified', entity: 'Review period' },
  modified_doc_training_required: {
    verb: 'modified',
    entity: 'Retraining required',
  },
  modified_doc_effective_on_approval: {
    verb: 'modified',
    entity: 'Effective on approval',
  },
  modified_doc_format: {
    verb: 'modified',
    entity: 'Format',
  },
  modified_file_download_permission_group: {
    verb: 'modified',
    entity: 'Download permissions',
  },
  approved: { verb: 'approved', entity: 'Document' },
  declined: { verb: 'declined', entity: 'Document' },
  // TODO: changed_status not used here, included for linting, need to refactor typing
  changed_status: { verb: 'modified', entity: 'Document' },
  upgraded_document_template: {
    verb: 'upgraded',
    entity: 'Document template',
  },
  reviewed: { verb: 'reviewed', entity: 'Document' },
};
