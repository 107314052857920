import { Parser } from 'html-to-react';
import React from 'react';
import slugify from 'slugify';
import { QualioDocument } from '../../../api/model/document';
import { getDocumentEditorGlobalWindowObject } from '../../../util/AppUtils';
import { TableOfContents } from '../TableOfContents';
import styles from './FroalaDocumentViewer.module.css';

const parseHtmlToReact = (html: string) => {
  const htmlToReactParser = new Parser();
  return htmlToReactParser.parse(html);
};

export const FroalaDocumentViewer: React.FC<{
  document: QualioDocument;
  showTOC: boolean;
  addContainerClass: boolean;
  handleAttachmentClick: (attachmentId: string) => void;
}> = ({ document, showTOC, addContainerClass, handleAttachmentClick }) => {
  // indicate to medtech-frontend that the editor is loaded to enable the export/edit buttons
  getDocumentEditorGlobalWindowObject().loaded = true;

  return (
    <>
      <div
        className={`${
          addContainerClass
            ? styles['document-editor__froala__document__viewer-container']
            : ''
        }`}
      >
        {showTOC && (
          <TableOfContents
            sections={document.sections}
            insideFroalaDocumentViewer={true}
            sectionTitlesVisible={document.text_sections_titles_visible}
          />
        )}
        <div
          className={`${styles['document-editor__froala__document__viewer-content']} document froala-document-viewer`}
        >
          {document.sections.map((section) => (
            <div key={section.id}>
              {section.type !== 'attachment' &&
                section.type !== 'single_attachment' && (
                  <div
                    className={
                      !document.text_sections_titles_visible
                        ? 'headless-text-section'
                        : ''
                    }
                  >
                    {section.type !== 'resolution' &&
                      document.text_sections_titles_visible && (
                        <h2
                          className="section-top-heading"
                          id={slugify(section.title.toLowerCase())}
                        >
                          {section.position + '. ' + section.title}
                        </h2>
                      )}
                    {section.type === 'resolution' && (
                      <h2 className="section-top-heading">{section.title}</h2>
                    )}
                    <div
                      style={{
                        counterReset:
                          'multilevel 0 section ' + section.position,
                      }}
                      className="clearfix"
                    >
                      {parseHtmlToReact(section.content)}
                    </div>
                  </div>
                )}
              {section.type === 'attachment' && (
                <div>
                  {document.text_sections_titles_visible && (
                    <h2 className="section-top-heading">
                      {section.position + '. ' + section.title}
                    </h2>
                  )}
                  {!document.text_sections_titles_visible && (
                    <h2 className="section-top-heading">{section.title}</h2>
                  )}

                  {(section.section_attachments ?? []).length > 0 && (
                    <div>
                      {section.section_attachments?.map((attachment, index) => (
                        <p key={attachment.id}>
                          {index + 1}.&#32;&nbsp;
                          <span>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href="#"
                              onClick={() =>
                                handleAttachmentClick(attachment.id.toString())
                              }
                            >
                              {attachment.filename}
                            </a>
                          </span>
                        </p>
                      ))}
                    </div>
                  )}
                  {(section.section_attachments ?? []).length === 0 && (
                    <p>No attachments.</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          id="commentsSidebarPlaceholder"
          className={`${styles['document-editor__document__sidebar']}`}
        ></div>
      </div>
    </>
  );
};
