import {
  QBox,
  QButton,
  QCheckbox,
  QCloseButton,
  QFormControl,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QRadio,
  QRadioGroup,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { ChangeEvent, useState } from 'react';
import { DocumentFormat, QualioDocument } from '../../../../api/model/document';
import { isQualityUserInAccount } from '../../../../util/CurrentUser';
import { EXPORT_BUTTON_LABEL } from '../../DocumentOverview/Buttons/index';

interface DocumentExportProps {
  isOpen: boolean;
  onClose: () => void;
  document: QualioDocument;
  onExportDocument: (
    coverPageEnabled: boolean,
    controlledCopy: boolean,
    orientation: 'portrait' | 'landscape' | undefined,
    onDocumentExportCompletion: () => void,
    onDocumentExportFailure: () => void,
    onAttachmentDownloadFailure: () => void,
  ) => void;
  currentUser: CurrentUser;
  newOverviewPageEnabled: boolean;
}

export const DocumentExportModal = ({
  isOpen,
  onClose,
  document,
  onExportDocument,
  currentUser,
  newOverviewPageEnabled = false,
}: DocumentExportProps) => {
  const { showToast } = useToastProvider();
  const [controlledCopy, setControlledCopy] = useState(false);
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    'portrait',
  );
  const [coverPageEnabled, setCoverPageEnabled] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const isQualityUser = isQualityUserInAccount(currentUser);

  const onControlledCopyChange = (value: string) => {
    const newValue = value === 'true';
    setControlledCopy(newValue);
  };

  const onCoverPageChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setCoverPageEnabled(evt.target.checked);
  };

  const reset = () => {
    setIsDownloading(false);
    setCoverPageEnabled(true);
  };

  const onCloseCallback = () => {
    reset();
    onClose();
  };

  const onDocumentExportFailure = () => {
    showToast({
      status: 'error',
      title: 'Error',
      description: 'Export failed - please try again or contact support.',
      id: 'export-document-error-toast',
    });
    setIsDownloading(false);
  };

  const onAttachmentDownloadFailure = () => {
    showToast({
      status: 'error',
      title: 'Error',
      description: 'Downloading attachments failed',
      id: 'export-attachment-error-toast',
    });
  };

  const exportDocument = () => {
    setIsDownloading(true);
    onExportDocument(
      coverPageEnabled,
      controlledCopy,
      orientation,
      onCloseCallback,
      onDocumentExportFailure,
      onAttachmentDownloadFailure,
    );
  };

  const shouldShowOrientationOptions =
    document.document_format === DocumentFormat.QualioNativeDocument &&
    document.editor_version !== null;

  const shouldShowCoverPageOptions = document.editor_version !== null;

  const shouldShowTypeOptions = document.status_id === 'effective';

  if (newOverviewPageEnabled) {
    return (
      <QModal onClose={onCloseCallback} isOpen={isOpen} size="xl">
        <QModalHeader>
          <QText>Export document</QText>
        </QModalHeader>
        <QModalBody>
          <QStack spacing={6}>
            <QText fontSize="md">Qualio exports documents as a PDF.</QText>
            {shouldShowTypeOptions && (
              <QFormControl label="Type">
                <QRadioGroup
                  value={isQualityUser ? controlledCopy.toString() : 'false'}
                  onChange={onControlledCopyChange}
                  data-testid="export-modal-type-selection"
                >
                  <QStack direction="row">
                    <QRadio
                      value="false"
                      data-cy="uncontrolled-radio"
                      data-testid="export-modal-uncontrolled-type"
                    >
                      Uncontrolled
                    </QRadio>
                    <QRadio
                      isDisabled={!isQualityUser}
                      value="true"
                      data-cy="controlled-radio"
                      data-testid="export-modal-controlled-type"
                    >
                      Controlled
                    </QRadio>
                  </QStack>
                </QRadioGroup>
              </QFormControl>
            )}
            {shouldShowOrientationOptions && (
              <QFormControl size="md" label="Orientation">
                <QRadioGroup
                  value={orientation}
                  onChange={(nextValue: string) =>
                    setOrientation(
                      nextValue === 'portrait' ? 'portrait' : 'landscape',
                    )
                  }
                  data-testid="export-modal-orientation-selection"
                >
                  <QStack direction="row">
                    <QRadio
                      value="portrait"
                      data-testid="export-modal-portrait-orientation"
                    >
                      Portrait
                    </QRadio>
                    <QRadio
                      value="landscape"
                      data-testid="export-modal-landscape-orientation"
                    >
                      Landscape
                    </QRadio>
                  </QStack>
                </QRadioGroup>
              </QFormControl>
            )}
            {shouldShowCoverPageOptions && (
              <QStack spacing={0}>
                <QFormControl
                  label="Cover page"
                  helper="A cover page includes document properties as the first page of
                  the document export."
                >
                  <QCheckbox
                    isChecked={coverPageEnabled}
                    onChange={onCoverPageChange}
                    data-testid="export-modal-coverpage-selection"
                  >
                    Include cover page
                  </QCheckbox>
                </QFormControl>
              </QStack>
            )}
          </QStack>
        </QModalBody>
        <QModalActions>
          <QButton variant="outline" onClick={onCloseCallback}>
            Cancel
          </QButton>
          <QButton
            isLoading={isDownloading}
            onClick={exportDocument}
            data-cy="export-document-button"
            data-testid="export-modal-submit-button"
          >
            {EXPORT_BUTTON_LABEL}
          </QButton>
        </QModalActions>
      </QModal>
    );
  }

  return (
    <QModal onClose={onCloseCallback} isOpen={isOpen}>
      <QModalHeader>
        <QText>Export options</QText>
        <QCloseButton onClick={onCloseCallback} />
      </QModalHeader>
      <QModalBody>
        {document.status_id !== 'effective' ? null : (
          <QBox p={4}>
            <QFormControl label="Type of copy">
              <QRadioGroup
                value={isQualityUser ? controlledCopy.toString() : 'false'}
                onChange={onControlledCopyChange}
                data-testid="export-modal-type-selection"
              >
                <QStack direction="row">
                  <QRadio
                    value="false"
                    data-cy="uncontrolled-radio"
                    data-testid="export-modal-uncontrolled-type"
                  >
                    Uncontrolled
                  </QRadio>
                  <QRadio
                    isDisabled={!isQualityUser}
                    value="true"
                    data-cy="controlled-radio"
                    data-testid="export-modal-controlled-type"
                  >
                    Controlled
                  </QRadio>
                </QStack>
              </QRadioGroup>
            </QFormControl>
          </QBox>
        )}
        {document.document_format === DocumentFormat.DocumentUpload ? null : (
          <QBox p={4}>
            <QFormControl size="md" label="Orientation">
              <QRadioGroup
                value={orientation}
                onChange={(nextValue: string) =>
                  setOrientation(
                    nextValue === 'portrait' ? 'portrait' : 'landscape',
                  )
                }
                data-testid="export-modal-orientation-selection"
              >
                <QStack direction="row">
                  <QRadio
                    value="portrait"
                    data-testid="export-modal-portrait-orientation"
                  >
                    Portrait
                  </QRadio>
                  <QRadio
                    value="landscape"
                    data-testid="export-modal-landscape-orientation"
                  >
                    Landscape
                  </QRadio>
                </QStack>
              </QRadioGroup>
            </QFormControl>
          </QBox>
        )}
        <QBox p={4}>
          <QFormControl>
            <QCheckbox
              isChecked={coverPageEnabled}
              onChange={onCoverPageChange}
              size="md"
              data-testid="export-modal-coverpage-selection"
              data-cy="export-modal-coverpage-selection"
            >
              Include cover page
            </QCheckbox>
          </QFormControl>
        </QBox>
      </QModalBody>
      <QModalActions>
        <QButton variant="ghost" onClick={onCloseCallback}>
          Cancel
        </QButton>
        <QButton
          isLoading={isDownloading}
          onClick={exportDocument}
          data-cy="export-document-button"
          data-testid="export-modal-submit-button"
        >
          Export document
        </QButton>
      </QModalActions>
    </QModal>
  );
};
