import { Tooltip } from '@chakra-ui/react';
import { QIconButton } from '@qualio/ui-components';
import { forwardRef, ReactNode, useContext } from 'react';
import { EditorMode } from '../../../../../util/EditModeUtils';
import { TableOfContents } from '../../../TableOfContents/index';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';
import styles from './DocumentContentEditor.module.css';

export const DocEditorSideBarWrapper = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }, ref) => {
  const { qualioDocument, currentEditorMode, commentsPermission } = useContext(
    DocumentOverviewContext,
  );

  const { docEditorStatus } = useContext(EditorStatusContext);

  const isEditMode =
    currentEditorMode !== EditorMode.VIEW &&
    docEditorStatus !== EditorStatus.CONNECTING;

  const visibilityClass = `document-editor__document__content-container__show`;
  const borderClass = isEditMode
    ? styles['document-editor__document__content-container-border']
    : '';

  return (
    <div
      ref={ref}
      className={`${styles['document-editor__document__content-container']} ${styles[visibilityClass]} ${borderClass}`}
    >
      {
        <TableOfContents
          sections={qualioDocument.sections}
          sectionTitlesVisible={qualioDocument.text_sections_titles_visible}
        />
      }
      <div
        id="documentEditorContent"
        data-testid="documentEditorContent"
        className={`${
          styles['document-editor__document__section-content']
        } document ${!isEditMode ? 'non-editable-mode' : ''}`}
      >
        {children}
      </div>
      <div
        hidden={commentsPermission <= 1}
        id="documentCommentControl"
        data-testid="documentCommentControl"
        className={`${styles['document-editor__document__comment-control']} hidden`}
      >
        <Tooltip
          label={'Select content to add a comment'}
          hasArrow={true}
          placement={'top'}
        >
          <p>
            <QIconButton
              iconName={'MessageSquare'}
              aria-label="icon-button"
              size="md"
              data-cy="comment-button"
            />
          </p>
        </Tooltip>
      </div>
      <div
        id="commentsSidebar"
        data-testid="commentsSidebar"
        className={`${styles['document-editor__document__sidebar']} ${
          docEditorStatus === EditorStatus.CONNECTING ? 'ck-hidden' : ''
        } `}
      ></div>
    </div>
  );
});
