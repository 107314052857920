import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

import { AngularRouterSatellite } from '@qualio/ui-components';

const RouteWatcher = (): JSX.Element => {
  const { qualioFrontendRefresh } = useFlags();
  const { pathname, search, hash } = useLocation();
  const location = `${pathname}${search}${hash}`;

  return (
    <>
      {!qualioFrontendRefresh && <AngularRouterSatellite location={location} />}
    </>
  );
};

export default RouteWatcher;
