import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import {
  CurrentUser,
  QAlert,
  QBox,
  QButton,
  QCollapse,
  QFormControl,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QMultiSelect,
  QRadio,
  QRadioGroup,
  QSelect,
  QStack,
  QText,
  QTextarea,
  useToastProvider,
} from '@qualio/ui-components';

import { changeControlApi } from '../../../../../api/changeControl';
import { documentApi } from '../../../../../api/document';
import { QualioDocument } from '../../../../../api/model/document';
import {
  CreatePeriodicReviewRequest,
  PeriodicReview,
} from '../../../../../api/model/periodicReview';
import {
  MedtechCompanyUserGroup,
  MedtechUserV2,
} from '../../../../../api/user';

import { redirectTo } from '../../../../../util/PageUtils';

import { ChangeControlDTO } from '../../../../../types/DocumentCreate';
import {
  isNormalUsergroup,
  isQualityUsergroup,
} from '../../../../../util/CurrentUser';
import styles from './PeriodicReviewModal.module.css';

export type PeriodicReviewModalProps = {
  onClose: () => void;
  isOpen: boolean;
  currentUser: CurrentUser;
  qualioDocument: QualioDocument;
  periodicReview?: PeriodicReview;
  users: MedtechUserV2[];
  submissionCallback: () => void;
};

enum PeriodicReviewUpdateStatus {
  UPDATE = 'update',
  DO_NOT_UPDATE = 'no_update',
}

type PeriodicReviewOption = {
  value: number | string | null;
  label: string;
};

type PeriodicReviewForm = {
  qualityApprovers: number[];
  otherApprovers: number[];
  comment: string;
  reviewPeriod: string | null;
};

const getCurrentApprovers = (
  qualioDocument: QualioDocument,
  type: MedtechCompanyUserGroup,
  users: MedtechUserV2[],
  periodicReview?: PeriodicReview,
) => {
  const { approver_group } = qualioDocument.audit_groups;

  if (periodicReview) {
    return periodicReview.approvers
      .filter((approver) => {
        const user = users.find((item) => item.id === approver.id);
        return user?.usergroups.includes(type);
      })
      .map((approver) => approver.id);
  }

  if (approver_group) {
    return approver_group
      .filter((approver) => approver?.usergroups.includes(type))
      .map((approver) => approver?.id);
  }

  return [];
};

const getReviewPeriodOptions = () => {
  const options: PeriodicReviewOption[] = [{ value: null, label: 'None' }];
  for (let i = 1; i <= 36; i++) {
    options.push({ value: i, label: `${i}` });
  }
  for (let g = 42; g <= 84; g += 6) {
    options.push({ value: g, label: `${g}` });
  }
  return options;
};

const getApproversOptions = (approvers: MedtechUserV2[]) => {
  return approvers.map((approver) => {
    return {
      value: approver.id,
      label: approver.full_name,
    };
  });
};

const sanitiseReviewPeriod = (reviewPeriodStr: string | null) => {
  return reviewPeriodStr === null ? reviewPeriodStr : parseInt(reviewPeriodStr);
};

const getSanitisedValue = (value: any) => {
  return isNaN(value) ? value?.value : value;
};

const getSanitisedApproversValues = (value: any[]) => {
  return value.map((val) => getSanitisedValue(val));
};

export const PeriodicReviewModal = ({
  onClose,
  isOpen,
  periodicReview,
  currentUser,
  qualioDocument,
  users,
  submissionCallback,
}: PeriodicReviewModalProps) => {
  const [updateStatus, setUpdateStatus] = useState(
    PeriodicReviewUpdateStatus.UPDATE,
  );
  const [performingReviewAction, setPerformingReviewAction] = useState(false);

  const { showToast } = useToastProvider();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PeriodicReviewForm>({
    values: {
      qualityApprovers: getCurrentApprovers(
        qualioDocument,
        'quality',
        users,
        periodicReview,
      ),
      otherApprovers: getCurrentApprovers(
        qualioDocument,
        'normal',
        users,
        periodicReview,
      ),
      comment: '',
      reviewPeriod:
        qualioDocument.review_period === null
          ? null
          : qualioDocument.review_period.toString(),
    },
  });

  const createNewDraft = async () => {
    setPerformingReviewAction(true);
    let newDoc: QualioDocument;
    let ccTab = '';
    try {
      if (periodicReview) {
        await documentApi.concludePeriodicReview(
          currentUser.companyId,
          qualioDocument.id,
        );
      }
      newDoc = await documentApi.createDraftFromEffectiveDoc(
        qualioDocument.company_id,
        qualioDocument.document_matrix_id,
      );
    } catch (e: unknown) {
      showToast({
        status: 'error',
        title: 'Error',
        description: 'Failed to create new draft.',
        id: 'periodic-review-create-new-draft-error-toast',
      });
      setPerformingReviewAction(false);
      return;
    }

    try {
      const changeControlTemplates =
        await changeControlApi.fetchChangeControlTemplates();
      if (changeControlTemplates && changeControlTemplates.length > 0) {
        const changeControlTemplate = changeControlTemplates[0];
        await changeControlApi.createChangeControl(
          newDoc,
          changeControlTemplate.id as number,
          { sections: changeControlTemplate.sections } as ChangeControlDTO,
        );
      }
    } catch (e: unknown) {
      ccTab = '?tab=change-control';
    }

    redirectTo(`/workspace/documents/${newDoc.id}${ccTab}`);
  };

  const startPeriodicReview: SubmitHandler<PeriodicReviewForm> = async (
    periodicReviewFormData,
  ) => {
    setPerformingReviewAction(true);
    const sanitsedPeriod = sanitiseReviewPeriod(
      periodicReviewFormData.reviewPeriod,
    );
    const periodicReviewData: CreatePeriodicReviewRequest = {
      comment: periodicReviewFormData.comment,
      suggested_review_period: sanitsedPeriod,
      approvers: [
        ...periodicReviewFormData.qualityApprovers,
        ...getSanitisedApproversValues(periodicReviewFormData.otherApprovers),
      ],
    };
    try {
      await documentApi.createPeriodicReview(
        currentUser,
        qualioDocument.id,
        periodicReviewData,
      );
      submissionCallback();
      onClose();
      const successDescription = periodicReview
        ? 'Existing periodic review has been canceled and new one was submitted to selected people for approval.'
        : 'Successfully submitted review for approval.';
      showToast({
        status: 'success',
        title: 'Sent for approval!',
        description: successDescription,
        id: 'periodic-review-create-success-toast',
      });
    } catch (e: unknown) {
      showToast({
        status: 'error',
        title: 'Error',
        description: 'Failed to submit review for approval. Please try again.',
        id: 'periodic-review-create-error-toast',
      });
    } finally {
      setPerformingReviewAction(false);
    }
  };

  const handlePeriodicReviewAction = () => {
    if (updateStatus === PeriodicReviewUpdateStatus.UPDATE) {
      void createNewDraft();
    } else {
      void handleSubmit(startPeriodicReview)();
    }
  };

  const handleRadioChange = (value: PeriodicReviewUpdateStatus) => {
    setUpdateStatus(value);
  };

  const actionText =
    updateStatus === PeriodicReviewUpdateStatus.UPDATE
      ? 'Create new draft'
      : 'Submit for approval';

  const reviewPeriodOptions = useMemo(() => getReviewPeriodOptions(), []);

  const qualityApproversOptions = useMemo(
    () => getApproversOptions(users.filter(isQualityUsergroup)),
    [users],
  );

  const otherApproversOptions = useMemo(
    () => getApproversOptions(users.filter(isNormalUsergroup)),
    [users],
  );

  return (
    <QModal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      data-cy={'periodic-review-modal'}
    >
      <QModalHeader>
        <QText>Periodic review</QText>
      </QModalHeader>
      <QModalBody>
        {periodicReview && (
          <QStack pb={4}>
            <QAlert
              description="By continuing, you will stop the periodic review already in progress"
              status="warning"
              title="This affects the active periodic review."
            />
          </QStack>
        )}
        <QStack>
          <QText>Please indicate if the document needs to be updated.</QText>
        </QStack>
        <QRadioGroup onChange={handleRadioChange} value={updateStatus} pt={6}>
          <QStack spacing={3}>
            <QBox className={styles['periodic-review__form-control__wrapper']}>
              <QFormControl helper="A new draft version of the document will be created.">
                <QRadio
                  value={PeriodicReviewUpdateStatus.UPDATE}
                  data-testid="periodic-review-create-draft"
                >
                  Yes, the document needs updating.
                </QRadio>
              </QFormControl>
            </QBox>
            <QFormControl>
              <QRadio
                value={PeriodicReviewUpdateStatus.DO_NOT_UPDATE}
                data-testid="periodic-review-create-review"
              >
                No, the document doesn't need to be updated.
              </QRadio>
            </QFormControl>
          </QStack>
        </QRadioGroup>
        <QCollapse
          isOpen={updateStatus === PeriodicReviewUpdateStatus.DO_NOT_UPDATE}
        >
          <QFormControl mt={4} label="Comment (optional)">
            <Controller
              name="comment"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <QTextarea
                    onChange={onChange}
                    value={value}
                    data-testid={'periodic-review-comment'}
                    data-cy={'periodic-review-comment'}
                    placeholder={`E.g. "I reviewed the document and stablised that no changes are needed"`}
                  />
                );
              }}
            ></Controller>
          </QFormControl>
          <QFormControl mt={4} label="Review period (months)">
            <Controller
              name="reviewPeriod"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <QSelect
                    onChange={(val) => {
                      onChange(getSanitisedValue(val));
                    }}
                    value={getSanitisedValue(value)}
                    data-testid={'periodic-review-period'}
                    data-cy={'periodic-review-period'}
                    options={reviewPeriodOptions}
                  />
                );
              }}
            ></Controller>
          </QFormControl>
          <QFormControl
            label="Quality approvers"
            isInvalid={Boolean(errors.qualityApprovers)}
            error={errors.qualityApprovers?.message}
            mt={4}
          >
            <Controller
              name="qualityApprovers"
              control={control}
              render={({ field: { onChange, value } }) => {
                const formProps = register('qualityApprovers', {
                  required: 'You must choose at least one mandatory approver!',
                });
                const { ref, ...sanitisedProps } = formProps;
                return (
                  <QMultiSelect
                    {...sanitisedProps}
                    onChange={(val: any) => {
                      onChange(getSanitisedApproversValues(val));
                    }}
                    value={getSanitisedApproversValues(value)}
                    data-testid={'periodic-review-quality-approvers'}
                    data-cy={'periodic-review-quality-approvers'}
                    options={qualityApproversOptions}
                  />
                );
              }}
            ></Controller>
          </QFormControl>
          <QFormControl label="Other approvers" mt={4}>
            <Controller
              name="otherApprovers"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <QMultiSelect
                    onChange={(val: any) => {
                      onChange(getSanitisedApproversValues(val));
                    }}
                    value={getSanitisedApproversValues(value)}
                    data-testid={'periodic-review-other-approvers'}
                    data-cy={'periodic-review-other-approvers'}
                    options={otherApproversOptions}
                  />
                );
              }}
            ></Controller>
          </QFormControl>
        </QCollapse>
      </QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={onClose}>
          Cancel
        </QButton>
        <QButton
          isLoading={performingReviewAction}
          onClick={handlePeriodicReviewAction}
          data-cy={'periodic-review-modal-confirmation'}
        >
          {actionText}
        </QButton>
      </QModalActions>
    </QModal>
  );
};
