import {
  DeletableFile,
  QAttachment,
  QAttachments,
  QAttachmentUploadInfo,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MEDTECH_UPLOAD_URL } from '../../../api';
import { QualioDocument } from '../../../api/model/document';
import { qualioFileDocumentApi } from '../../../api/qualioFileDocumentApi';
import {
  analyticsHandler,
  FileUploadEvents,
} from '../../../util/Analytics/AnalyticsUtils';
import { updateLastModifiedTimeOnSave } from '../../../util/EventHandlersUtils';
import {
  ACCEPTED_FILE_DOCUMENT_EXTS,
  FILE_UPLOAD_SIZE_LIMIT_MB,
  translateExtensionToMimeType,
} from '../../../util/FileUploadUtils';
import { DeleteEntityModal } from '../DeleteEntityModal/index';

export const FileDocumentUploadPanel: React.FC<{
  document: QualioDocument;
  attachments: QAttachment[];
  currentUser: CurrentUser & { tz?: string };
  setAttachments: (arg0: QAttachment[]) => void;
  analytics: SegmentAnalytics.AnalyticsJS;
}> = ({ document, attachments, setAttachments, currentUser, analytics }) => {
  const ref = useRef<null | {
    removeFile: (id: string, options: any) => void;
  }>();
  const [fileToDelete, setFileToDelete] = useState<DeletableFile | null>(null);
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const onCloseModal = () => setFileToDelete(null);

  const removeFileCallback = useCallback(async () => {
    setIsDeletingFile(true);
    await qualioFileDocumentApi.removeAttachmentAsDocumentContent(document);
    const date = Date.now();
    updateLastModifiedTimeOnSave(date, currentUser.tz);
    analyticsHandler(FileUploadEvents.Delete, analytics);
    setAttachments([]);
    setFileToDelete(null);
    setIsDeletingFile(false);
  }, [analytics, currentUser.tz, document, setAttachments]);

  useEffect(() => {
    const filePontElement = window.document.querySelector(
      'div.filepond--drop-label',
    );
    filePontElement?.setAttribute('data-testid', 'dropzone');
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <QAttachments
        data-cy="uploadFileView"
        attachments={attachments}
        filePondRef={(r) => (ref.current = r)}
        acceptedFileTypes={ACCEPTED_FILE_DOCUMENT_EXTS.map(
          translateExtensionToMimeType,
        )}
        uploadInfoProvider={() =>
          Promise.resolve({
            url: `${MEDTECH_UPLOAD_URL}?entity=document.section&entity_id=${document.sections[0].id}`,
            destination: 'MEDTECH',
          } as QAttachmentUploadInfo)
        }
        onAdd={async (attachment: QAttachment) => {
          setAttachments([attachment]);
          await qualioFileDocumentApi.setAttachmentAsDocumentContent(
            attachment.id,
            document,
          );
          const date = Date.now();
          updateLastModifiedTimeOnSave(date, currentUser.tz);
          analyticsHandler(FileUploadEvents.Upload, analytics);
        }}
        onClick={() => Promise.resolve()}
        onBeforeRemove={(file) => {
          setFileToDelete(file);
          return false;
        }}
        onRemove={removeFileCallback}
        onRevert={removeFileCallback}
        maxFiles={1}
        maxFileSize={`${FILE_UPLOAD_SIZE_LIMIT_MB}MB`}
        maxTotalFileSize={`${FILE_UPLOAD_SIZE_LIMIT_MB}MB`}
        allowMultiple={false}
      >
        <DeleteEntityModal
          isDeletingEntity={isDeletingFile}
          isOpen={!!fileToDelete}
          onCloseModal={onCloseModal}
          deleteHandler={() => {
            if (fileToDelete) {
              ref.current?.removeFile(fileToDelete.id, { revert: true });
            }
          }}
        />
      </QAttachments>
    </div>
  );
};
