import { User } from '@ckeditor/ckeditor5-collaboration-core';
import Suggestion from '@ckeditor/ckeditor5-track-changes/src/suggestion';
import { SuggestionThreadConfig } from '@ckeditor/ckeditor5-track-changes/src/ui/view/basesuggestionthreadview';
import SuggestionThreadView from '@ckeditor/ckeditor5-track-changes/src/ui/view/suggestionthreadview';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class QualioSuggestionThreadView extends SuggestionThreadView {
  static documentOwner: string;
  static documentStatus: string;
  constructor(
    locale: Locale,
    model: Suggestion,
    localUser: User,
    config: SuggestionThreadConfig,
  ) {
    super(locale, model, localUser, config);

    const template = this.getTemplate() as any;
    template.children[2].template.children[1].cancelButtonView.set({
      label: 'Cancel',
      withText: true,
      icon: null,
    });
    template.children[2].template.children[1].submitButtonView.set({
      label: 'Reply',
      withText: true,
      icon: null,
    });
    handleDisplayingSuggestButtons(localUser, template, model);
    super.setTemplate(template);
  }
}

export const qualioSuggestionThreadBuilder = (
  owner: string,
  status: string,
) => {
  QualioSuggestionThreadView.documentOwner = owner;
  QualioSuggestionThreadView.documentStatus = status;
  return QualioSuggestionThreadView;
};

export const handleDisplayingSuggestButtons = (
  localUser: User,
  template: any,
  suggestion: Suggestion,
) => {
  if (!shouldDisplayApplyButton(localUser)) {
    template.children[0].children[1].children[1].children[0].isVisible = false;
  }
  if (!shouldDisplayDeclineButton(localUser, suggestion)) {
    template.children[0].children[1].children[1].children[1].isVisible = false;
  }
};

const shouldDisplayApplyButton = (localUser: User) => {
  return (
    localUser.id === QualioSuggestionThreadView.documentOwner &&
    QualioSuggestionThreadView.documentStatus === 'Draft'
  );
};

const shouldDisplayDeclineButton = (
  localUser: User,
  suggestion: Suggestion,
) => {
  return (
    localUser.id === QualioSuggestionThreadView.documentOwner ||
    localUser.id === suggestion.author.id
  );
};
