import { MedtechToolbarToggler } from '../../hooks/MedtechToolbarToggler';
import { analyticsHandler } from '../../util/Analytics/AnalyticsUtils';
import { getDocumentEditorGlobalWindowObject } from '../../util/AppUtils';
import { navigateToSection } from '../../util/DocumentNavigationUtils';
import { EditorMode } from '../../util/EditModeUtils';
import { useErrorHandler } from '../../views/components/ErrorHandler';

const MESSAGE_EVENT_NAME = 'message';
export const EDIT_MODE_DROPDOWN_ID = 'documentEditNew';
export const VIEW_MODE_DROPDOWN_ID = 'documentViewNew';
export const TRACK_CHANGES_MODE_DROPDOWN_ID = 'documentEditorTrackChanges';
export const IFRAME_ID = 'documentEditorFrame';

export type MessageType =
  | 'pending-changes'
  | 'initialized'
  | 'attachment-preview'
  | 'analytics'
  | 'document-mode'
  | 'resize'
  | 'section-navigation'
  | 'session-disconnected';

export type Message =
  | {
      type: 'error';
      content: {
        success: boolean;
        lastUpdatedAt?: string | number;
        error?: string;
      };
    }
  | {
      type: MessageType;
      content?: any;
    };

export type IFrameMessageHandlerConfiguration = {
  viewModeCallback: any;
  editModeCallback: any;
  trackChangesModeCallback: any;
  resizeCallback: (parentWidth: number) => void;
};

export type ParentMessageHandlerConfiguration = {
  name: string;
  hasErrorCallback?: ParentMessageHandlerConfigurationErrorCallback;
  medtechToolbarToggler?: MedtechToolbarToggler;
  showAttachmentForPreviewCallback?: (attachmentId: string) => void;
  analytics?: SegmentAnalytics.AnalyticsJS;
  successCallback?: (data: { lastUpdated?: string | number }) => void;
  sessionDisconnectedCallback?: () => void;
};

type ParentMessageHandlerConfigurationErrorCallback = ReturnType<
  typeof useErrorHandler.prototype.updateHasError
>;

export const notifyParent = (message: Message) => {
  if (process.env.REACT_APP_MTFE_HOSTNAME) {
    window.parent?.postMessage(message, {
      targetOrigin: process.env.REACT_APP_MTFE_HOSTNAME,
    });
  }
};

export const notifyIFrame = (message: Message) => {
  const iframe = document.querySelector<HTMLIFrameElement>(`#${IFRAME_ID}`);
  iframe?.contentWindow?.postMessage(message, {
    targetOrigin: `${process.env.REACT_APP_URL_ORIGIN}`,
  });
};

type ActiveListener = {
  name: string;
  listener: (messageEvent?: MessageEvent<Message>) => void;
};

export const activeListeners: ActiveListener[] = [];

const addActiveListener = (
  listener: (messageEvent?: MessageEvent<Message>) => void,
  name: string,
) => {
  for (let i = 0; i < activeListeners.length; i++) {
    if (activeListeners[i].name === name) {
      window.removeEventListener(
        MESSAGE_EVENT_NAME,
        activeListeners[i].listener,
      );
      activeListeners.splice(i, 1);
      break;
    }
  }

  window.addEventListener(MESSAGE_EVENT_NAME, listener);
  activeListeners.push({ name, listener });
};

export const removeActiveListeners = () => {
  while (activeListeners.length > 0) {
    const listener = activeListeners.pop()!;
    window.removeEventListener(MESSAGE_EVENT_NAME, listener.listener);
  }
};

export const registerIFrameMessageHandler = (
  config: IFrameMessageHandlerConfiguration,
) => {
  const iFrameMessageHandler = (messageEvent?: MessageEvent<Message>) => {
    if (
      !messageEvent ||
      messageEvent?.origin !== process.env.REACT_APP_MTFE_HOSTNAME
    ) {
      return;
    }
    const message = messageEvent.data;
    switch (message.type) {
      case 'document-mode':
        iFrameDocumentModeHandler(
          message,
          config.viewModeCallback,
          config.editModeCallback,
          config.trackChangesModeCallback,
        );
        break;
      case 'resize':
        iFrameResizeHandler(message, config.resizeCallback);
        break;
      case 'section-navigation':
        iFrameSectionNavigationHandler(message);
        break;
    }
  };
  addActiveListener(iFrameMessageHandler, 'iframe');
};

export const registerParentMessageHandler = (
  config: ParentMessageHandlerConfiguration,
) => {
  const parentMessageHandler = (messageEvent?: MessageEvent<Message>) => {
    if (
      !messageEvent ||
      messageEvent?.origin !== process.env.REACT_APP_URL_ORIGIN
    ) {
      return;
    }
    const message = messageEvent.data;
    switch (message.type) {
      case 'error':
        if (config.hasErrorCallback && config.successCallback) {
          parentErrorHandlerListener(
            message,
            config.hasErrorCallback,
            config.successCallback,
          );
        }
        break;
      case 'pending-changes':
        if (config.medtechToolbarToggler) {
          parentPendingChangesHandler(message, config.medtechToolbarToggler);
        }
        break;
      case 'initialized':
        parentEditorsInitializedHandler();
        break;
      case 'attachment-preview':
        if (config.showAttachmentForPreviewCallback) {
          parentAttachmentPreviewHandler(
            message,
            config.showAttachmentForPreviewCallback,
          );
        }
        break;
      case 'analytics':
        if (config.analytics) {
          analyticsHandler(
            message.content.event,
            config.analytics,
            message.content.properties,
          );
        }
        break;
      case 'session-disconnected':
        if (config.sessionDisconnectedCallback) {
          config.sessionDisconnectedCallback();
        }
        break;
    }
  };
  addActiveListener(parentMessageHandler, config.name);
};

export const registerParentDropdownSenders = () => {
  const enableViewModeButton = document.querySelector(
    `#${VIEW_MODE_DROPDOWN_ID}`,
  );
  const enableEditModeButton = document.querySelector(
    `#${EDIT_MODE_DROPDOWN_ID}`,
  );
  const enableTrackChangesModeButton = document.querySelector(
    `#${TRACK_CHANGES_MODE_DROPDOWN_ID}`,
  );
  enableEditModeButton?.addEventListener('click', () => {
    notifyIFrame({ type: 'document-mode', content: EditorMode.EDIT });
  });

  enableViewModeButton?.addEventListener('click', () => {
    notifyIFrame({ type: 'document-mode', content: EditorMode.VIEW });
  });

  enableTrackChangesModeButton?.addEventListener('click', () => {
    notifyIFrame({ type: 'document-mode', content: EditorMode.TRACKCHANGES });
  });
};

const iFrameDocumentModeHandler = (
  message: Message,
  viewModeCallback: any,
  editModeCallback: any,
  trackChangesModeCallback: any,
) => {
  if (message.content === EditorMode.VIEW) {
    viewModeCallback();
  } else if (message.content === EditorMode.EDIT) {
    editModeCallback();
  } else if (message.content === EditorMode.TRACKCHANGES) {
    trackChangesModeCallback();
  }
};

const iFrameResizeHandler = (
  message: Message,
  resizeCallback: (parentWidth: number) => void,
) => {
  if (message.content.parentWidth) {
    resizeCallback(message.content.parentWidth);
  }
};

const iFrameSectionNavigationHandler = (message: Message) => {
  if (message.content.sectionIndex) {
    navigateToSection(message.content.sectionIndex);
  }
};

const parentErrorHandlerListener = (
  message: Message,
  hasErrorCallback: ParentMessageHandlerConfigurationErrorCallback,
  successCallback: (data: { lastUpdated?: string | number }) => void,
) => {
  if (message.type !== 'error') {
    return;
  }
  if (message.content.error) {
    hasErrorCallback(true, message.content.error);
  }
  if (message.content.success) {
    hasErrorCallback(false);
    successCallback({ lastUpdated: message.content.lastUpdatedAt });
  }
};

const parentPendingChangesHandler = (
  message: Message,
  medtechToolbarToggler: MedtechToolbarToggler,
) => {
  if (message.content.hasPendingActions && message.content.editingActions) {
    medtechToolbarToggler.disableButtons();
  } else if (
    !message.content.hasPendingActions &&
    message.content.editingActions
  ) {
    medtechToolbarToggler.enableButtons();
  }
};

const parentEditorsInitializedHandler = () => {
  getDocumentEditorGlobalWindowObject().loaded = true;
};

const parentAttachmentPreviewHandler = (
  message: Message,
  showAttachmentForPreviewCallback: (attachmentId: string) => void,
) => {
  if (message.content.attachmentId) {
    showAttachmentForPreviewCallback(message.content.attachmentId);
  }
};
