import React from 'react';

import { QText } from '@qualio/ui-components';

export const DotSeparator: React.FC = () => {
  return (
    <QText
      fontSize="xs"
      color="gray.500"
      data-cy="dot-separator"
      data-testid="dot-separator"
    >
      &nbsp; • &nbsp;
    </QText>
  );
};
