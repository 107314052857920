import {
  QualioQThemeProvider,
  ToastProvider,
  useAnalytics,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { documentApi } from '../../../api/document';
import { DocumentFormat, QualioDocument } from '../../../api/model/document';
import {
  deregisterWindowResizeListener,
  registerParentWindowResizeListener,
} from '../../../config/handlers';
import {
  registerParentDropdownSenders,
  registerParentMessageHandler,
  removeActiveListeners,
} from '../../../config/handlers/IFrameMessageHandler';
import { useMedtechToolbarToggler } from '../../../hooks/MedtechToolbarToggler';
import { analyticsHandler } from '../../../util/Analytics/AnalyticsUtils';
import { getDocumentEditorGlobalWindowObject } from '../../../util/AppUtils';
import { getUserCommentsPermission } from '../../../util/CurrentUser';
import { convertApiDocumentToViewableInstance } from '../../../util/DocumentApiUtils';
import { updateLastModifiedTimeOnSave } from '../../../util/EventHandlersUtils';
import { CollabServerConnectionManager } from '../CollabServerConnectionManager';
import { DocumentCollaborationSessionDeleteModal } from '../DocumentCollaborationSessionDeleteModal';
import { DocumentCommentHistoryPanel } from '../DocumentCommentHistoryPanel';
import { DocumentConvertFormatModal } from '../DocumentConvertFormatModal';
import { DocumentHTMLViewer } from '../DocumentHTMLViewer/DocumentHTMLViewer';
import { useErrorHandler } from '../ErrorHandler';
import { ErrorToastManager } from '../ErrorToastManager';
import { ExportWrapper } from '../Export/ExportWrapper';
import { FileDocumentEditor } from '../FileDocumentEditor';
import { FroalaDocumentViewer } from '../FroalaDocumentViewer/FroalaDocumentViewer';
import { InlineAttachmentPreviewModal } from '../InlineAttachmentPreview/InlineAttachmentPreviewModal';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const NO_CACHE_RANDOM_NUMBER = Math.round(Math.random() * 10000000);

export const DocumentEditorRoot: React.FC<{
  currentUser: CurrentUser & { tz?: string };
}> = ({ currentUser }) => {
  let MTFEDocument = getDocumentEditorGlobalWindowObject().document;
  if (MTFEDocument && !shouldShowFroalaViewer(MTFEDocument)) {
    MTFEDocument = convertApiDocumentToViewableInstance(MTFEDocument);
  }
  const [mtfeDocument, setMtfeDocument] =
    useState<QualioDocument>(MTFEDocument);
  const [documentExportModalIsOpen, setDocumentExportModalIsOpen] =
    useState(false);

  const [editorSessionResetModalIsOpen, setEditorSessionResetModalIsOpen] =
    useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const requestedDocVersion = searchParams.get('version');
  const location = useLocation();

  const onCloseEditorSessionResetModal = () =>
    setEditorSessionResetModalIsOpen(false);
  const openDocumentExportModal = () => setDocumentExportModalIsOpen(true);

  const openEditorSessionResetModal = () =>
    setEditorSessionResetModalIsOpen(true);
  const [commentsPermission, setCommentsPermission] = useState<number>();
  const { errorMsg, hasError, updateHasError } = useErrorHandler();
  const urlPatternUsingOldTabView = ['training', 'approve-pr'];
  const oldTabViewUsedToDisplayContent = urlPatternUsingOldTabView.some(
    (pattern) => location.pathname.includes(pattern),
  );
  const [attachmentForPreview, setAttachmentForPreview] = useState<string>();
  const showAttachmentForPreviewCallback = (attachmentId: string) => {
    analyticsHandler(
      'Document Editor | Inline attachment preview opened',
      analytics,
    );
    setAttachmentForPreview(attachmentId);
  };
  const [commentHistoryPanelIsOpen, setCommentHistoryPanelIsOpen] =
    useState(false);
  const onCloseCommentHistoryPanel = () => setCommentHistoryPanelIsOpen(false);
  const [changeDocumentFormatModalIsOpen, setChangeDocumentFormatModalIsOpen] =
    useState(false);
  const onCloseChangeDocumentFormatModal = (success: boolean) => {
    if (success) {
      window.location.reload();
    } else {
      setChangeDocumentFormatModalIsOpen(false);
    }
  };
  const medtechToolbarToggler = useMedtechToolbarToggler();
  const analytics = useAnalytics();
  const { useHtmlViewerForFroala } = useFlags();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let componentToShow = '';

  const showCommentHistoryPanelButton = document.querySelector(
    '#documentCommentHistory',
  );

  showCommentHistoryPanelButton?.addEventListener('click', (event: any) => {
    const disabled = event?.target?.getAttribute('disabled');
    if (disabled === null) {
      setCommentHistoryPanelIsOpen(true);
    }
  });

  const convertDocumentFormatButton = document.getElementById(
    'documentFileConversion',
  );

  convertDocumentFormatButton?.addEventListener('click', () => {
    if (!convertDocumentFormatButton.classList.contains('fake-link-disabled')) {
      setChangeDocumentFormatModalIsOpen(true);
    }
  });

  const handleSaveSuccess = (data: { lastUpdated?: string | number }) => {
    if (data.lastUpdated) {
      updateLastModifiedTimeOnSave(data.lastUpdated, currentUser.tz);
    }
  };

  const loadDocument = useCallback(
    async (loadCallback?: () => void) => {
      if (id) {
        let fetchedDocument;
        try {
          fetchedDocument = await documentApi.fetchByIdOrVersion(
            id,
            requestedDocVersion ?? '',
          );
        } catch (e) {
          updateHasError(true, (e as Error).message, true);
          return;
        }
        // don't convert if froala viewer
        if (!shouldShowFroalaViewer(fetchedDocument)) {
          fetchedDocument =
            convertApiDocumentToViewableInstance(fetchedDocument);
        }
        loadCallback && loadCallback();
        setMtfeDocument(fetchedDocument);
      }
    },
    [id, requestedDocVersion, updateHasError],
  );

  useEffect(() => {
    // MTFE should set the document first
    // This is just a fallback in case some race condition appears
    if (!mtfeDocument) {
      void loadDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, requestedDocVersion, currentUser]);

  useEffect(() => {
    addGlobalWindowHandlerForDocumentExport(openDocumentExportModal);
    addGlobalWindowHandlerForSessionReset(openEditorSessionResetModal);
    registerParentMessageHandler({
      name: 'document-editor-root',
      hasErrorCallback: updateHasError,
      medtechToolbarToggler,
      showAttachmentForPreviewCallback,
      analytics,
      successCallback: handleSaveSuccess,
    });
    registerParentDropdownSenders();
    registerParentWindowResizeListener();
    return () => {
      //deregister window listener
      deregisterWindowResizeListener();
      removeActiveListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medtechToolbarToggler]);

  useEffect(() => {
    if (currentUser && mtfeDocument && commentsPermission === undefined) {
      const currentCommentPermissions = getUserCommentsPermission(
        currentUser,
        mtfeDocument,
      );
      setCommentsPermission(currentCommentPermissions);
    }
  }, [currentUser, mtfeDocument, commentsPermission]);

  if (
    !mtfeDocument ||
    !currentUser ||
    commentsPermission === undefined ||
    commentsPermission === null
  ) {
    return <LoadingSpinner />;
  }

  componentToShow = determineContentComponentToShow(
    commentsPermission,
    mtfeDocument,
    oldTabViewUsedToDisplayContent,
  );

  return (
    <>
      {componentToShow === 'cke-html-viewer' && (
        <DocumentHTMLViewer
          viewDocument={mtfeDocument}
          showSidebar={!oldTabViewUsedToDisplayContent}
          showTOC={!oldTabViewUsedToDisplayContent}
          setLoaded={true}
          handleAttachmentClick={showAttachmentForPreviewCallback}
        />
      )}
      {componentToShow === 'cke-editor' && (
        <iframe
          id={'documentEditorFrame'}
          data-tesetid="documentEditorFrame"
          className={'doc-iframe'}
          title={'ckeditor-instance'}
          style={{
            minWidth: '100%',
            height: windowHeight - 83 + 'px',
            minHeight: windowHeight - 83 + 'px',
            border: 'none',
            position: 'relative',
            top: '-15px',
          }}
          // we use the noCache param to avoid Chrome caching the content of the iframe
          src={`${process.env.REACT_APP_URL_ORIGIN}/iframe/document/${mtfeDocument.id}?noCache=${NO_CACHE_RANDOM_NUMBER}`}
        ></iframe>
      )}
      {componentToShow === 'file-upload' && (
        <FileDocumentEditor
          currentUser={currentUser}
          fileDocument={mtfeDocument}
          isOwner={mtfeDocument.owner.id === currentUser.userId}
          commentsPermission={commentsPermission}
        />
      )}
      {componentToShow === 'froala-html-viewer' && (
        <>
          {useHtmlViewerForFroala ? (
            <DocumentHTMLViewer
              viewDocument={mtfeDocument}
              showSidebar={!oldTabViewUsedToDisplayContent}
              showTOC={!oldTabViewUsedToDisplayContent}
              setLoaded={true}
              handleAttachmentClick={showAttachmentForPreviewCallback}
            />
          ) : (
            <FroalaDocumentViewer
              document={mtfeDocument}
              showTOC={!oldTabViewUsedToDisplayContent}
              addContainerClass={!oldTabViewUsedToDisplayContent}
              handleAttachmentClick={showAttachmentForPreviewCallback}
            />
          )}
        </>
      )}
      <QualioQThemeProvider>
        <ExportWrapper
          qualioDocument={mtfeDocument}
          isDocumentExportModalOpen={documentExportModalIsOpen}
          onClose={() => setDocumentExportModalIsOpen(false)}
        />
        <DocumentCollaborationSessionDeleteModal
          isOpen={editorSessionResetModalIsOpen}
          onClose={onCloseEditorSessionResetModal}
          doResetSession={async () => {
            await documentApi.deleteDocumentCollaborationSession(
              mtfeDocument.company_id,
              mtfeDocument.id,
              true,
            );
          }}
        />
        {showCommentHistoryPanelButton && (
          <ToastProvider>
            <DocumentCommentHistoryPanel
              isOpen={commentHistoryPanelIsOpen}
              onClose={onCloseCommentHistoryPanel}
              companyId={currentUser.companyId}
              documentId={mtfeDocument.id.toString()}
            />
          </ToastProvider>
        )}
        {attachmentForPreview && (
          <InlineAttachmentPreviewModal
            currentUser={currentUser}
            attachmentId={attachmentForPreview}
            onClose={() => setAttachmentForPreview(undefined)}
          />
        )}
        {changeDocumentFormatModalIsOpen && (
          <ToastProvider>
            <DocumentConvertFormatModal
              onClose={onCloseChangeDocumentFormatModal}
              qualioDocument={mtfeDocument}
            />
          </ToastProvider>
        )}
      </QualioQThemeProvider>
      <ErrorToastManager msg={errorMsg} hasError={hasError} />
      <CollabServerConnectionManager
        currentDocument={mtfeDocument}
        currentUser={currentUser}
        updateHasError={updateHasError}
      />
    </>
  );
};

type ContentComponentName =
  | 'cke-editor'
  | 'cke-html-viewer'
  | 'froala-html-viewer'
  | 'file-upload';

const determineContentComponentToShow = (
  commentsPermission: number,
  qualioDocument: QualioDocument,
  oldTabViewUsedToDisplayContent: boolean,
): ContentComponentName => {
  if (shouldShowFroalaViewer(qualioDocument)) {
    return 'froala-html-viewer';
  }

  if (shouldSHowFileUpload(qualioDocument)) {
    return 'file-upload';
  }

  const hasEditPermission = commentsPermission > 0;
  if (
    !hasEditPermission ||
    qualioDocument.status_id === 'deleted' ||
    qualioDocument.status_id === 'effective' ||
    !qualioDocument.is_most_recent_version ||
    oldTabViewUsedToDisplayContent
  ) {
    return 'cke-html-viewer';
  } else {
    return 'cke-editor';
  }
};

const shouldShowFroalaViewer = (qualioDocument: QualioDocument) => {
  const isCKEDocument = qualioDocument.editor_version?.startsWith('ckeditor-v');
  return !isCKEDocument;
};

const shouldSHowFileUpload = (qualioDocument: QualioDocument) => {
  return qualioDocument.document_format === DocumentFormat.DocumentUpload;
};

const addGlobalWindowHandlerForDocumentExport = (
  openDocumentExportModal: () => void,
) => {
  getDocumentEditorGlobalWindowObject().triggerExport = () => {
    openDocumentExportModal();
  };
};

const addGlobalWindowHandlerForSessionReset = (
  openEditorSessionResetModal: () => void,
) => {
  getDocumentEditorGlobalWindowObject().resetEditorSession = () => {
    openEditorSessionResetModal();
  };
};
