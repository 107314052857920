import { QButton, useAnalytics, useCurrentUser } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { changeControlApi } from '../../../../api/changeControl';
import { DocumentFormat } from '../../../../api/model/document';
import { ChangeControl } from '../../../../types/DocumentCreate';
import { analyticsHandler } from '../../../../util/Analytics/AnalyticsUtils';
import { isOwner } from '../../../../util/CurrentUser';
import { EditorMode } from '../../../../util/EditModeUtils';
import { GenerateCCComponent } from '../../AiPoc/GenerateCC/GenerateCC';
import { InlineAttachmentPreviewModal } from '../../InlineAttachmentPreview/InlineAttachmentPreviewModal';
import { ToolbarWrapper } from '../../ToolbarWrapper/ToolbarWrapper';
import { DocumentOverviewContext } from '../Context';
import styles from '../DocumentOverview.module.css';
import { HTMLViewer } from '../HTMLViewer/index';
import { ChangeControlSectionsManager } from './ChangeControlSectionsManager';
import { ChangeControlSidebarWrapper } from './ChangeControlSidebarWrapper';

type ChangeControlProps = {
  changeControl: ChangeControl | null | undefined;
  refetchChangeControl: () => void;
};

export const ChangeControlDisplay = ({
  changeControl,
  refetchChangeControl,
}: ChangeControlProps) => {
  const analytics = useAnalytics();
  const currentUser = useCurrentUser();
  const { qualioDocument, currentEditorMode, commentsPermission } = useContext(
    DocumentOverviewContext,
  );
  const isReadOnly = useMemo(() => {
    const hasEditPermission = commentsPermission > 0;
    return (
      !hasEditPermission ||
      qualioDocument.status_id === 'deleted' ||
      qualioDocument.status_id === 'effective' ||
      !qualioDocument.is_most_recent_version
    );
  }, [
    commentsPermission,
    qualioDocument.is_most_recent_version,
    qualioDocument.status_id,
  ]);

  const [editors, setEditors] = useState([]);
  const { editorAi } = useFlags();
  const aiEnabled = editorAi || currentUser.company.ai_enabled;

  const [attachmentForPreview, setAttachmentForPreview] = useState<string>();
  const showAttachmentForPreviewCallback = useCallback(
    (attachmentId: string) => {
      analyticsHandler(
        'Document Editor | Change control | Inline attachment preview opened',
        analytics,
      );
      setAttachmentForPreview(attachmentId);
    },
    [analytics],
  );

  const widthRef = useRef<HTMLDivElement>(null);

  if (!changeControl) {
    if (isOwner(currentUser, qualioDocument)) {
      const createChangeControl = async () => {
        const templates = await changeControlApi.fetchChangeControlTemplates();
        const changeControlTemplate = templates[0];
        await changeControlApi.createChangeControl(
          qualioDocument,
          changeControlTemplate.id,
          {
            sections: changeControlTemplate.sections,
          },
        );
        refetchChangeControl();
      };
      return (
        <QButton
          data-testid={'create-change-control'}
          data-cy={'create-change-control'}
          onClick={createChangeControl}
        >
          Create a change control
        </QButton>
      );
    }
    return <div>Change control has not been created yet</div>;
  }

  return (
    <>
      {aiEnabled &&
        currentEditorMode !== EditorMode.VIEW &&
        qualioDocument.document_format ===
          DocumentFormat.QualioNativeDocument && (
          <GenerateCCComponent
            changeControl={changeControl}
            editors={editors}
          />
        )}

      <ToolbarWrapper
        widthRef={widthRef}
        className={`${styles['document-overview__toolbar']}`}
        id="changeControlToolbarWrapper"
      >
        {/*Toolbar will be placed during editing*/}
      </ToolbarWrapper>
      {attachmentForPreview && (
        <InlineAttachmentPreviewModal
          currentUser={currentUser}
          attachmentId={attachmentForPreview}
          onClose={() => setAttachmentForPreview(undefined)}
        />
      )}
      <ChangeControlSidebarWrapper
        ref={widthRef}
        changeControl={changeControl}
        handleAttachmentClick={showAttachmentForPreviewCallback}
      >
        {isReadOnly ? (
          <HTMLViewer sections={changeControl.sections} />
        ) : (
          <ChangeControlSectionsManager
            changeControl={changeControl}
            setEditors={setEditors}
            editors={editors}
            handleAttachmentClick={showAttachmentForPreviewCallback}
          />
        )}
      </ChangeControlSidebarWrapper>
    </>
  );
};
