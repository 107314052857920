import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getContextConfig } from '../../../../../../config/DocumentEditorConfig';
import {
  createContextReadyHandlerV2,
  enableCKEditorMode,
  handleError,
} from '../../../../../../config/handlers';
import QualioContext from '../../../../../../editor/QualioContext';
import { useElementIsRendered } from '../../../../../../hooks/ElementRenderedCheck';
import {
  CKE_EDITOR_ERROR,
  logCustomError,
} from '../../../../../../messages/LogErrorMessages';
import { genericApplicationErrorMessage } from '../../../../../../messages/UserErrorMessages';
import { CKEditorInstance } from '../../../../../../types/CKEditorInstance';
import {
  buildAttachmentsErrorMessage,
  buildToastDescription,
  isAttachmentError,
} from '../../../../../../util/ErrorManagementUtils';
import { refreshDisplayMode } from '../../../../../../util/SidebarUtils';
import { ToolbarManager } from '../../../../../../util/ToolbarManager';
import { isInQualioFrontendApp } from '../../../../../../util/UrlUtils';
import { saveSectionFromEditor } from '../../../../../reducers/DocumentReducer';
import { DocumentHTMLViewer } from '../../../../DocumentHTMLViewer/DocumentHTMLViewer';
import { LoadingSpinner } from '../../../../LoadingSpinner/LoadingSpinner';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../../Context';
import { SectionedContentEditor } from './DocumentSectionEditorV2/SectionedContentEditor';

type DocumentSectionEditorV2Props = {
  handleAttachmentClick: (attachmentId: string) => void;
  handleSessionDisconnectionEvent: (didReceive: boolean) => void;
};

export const DocumentSectionsManagerV2 = memo(
  ({
    handleAttachmentClick,
    handleSessionDisconnectionEvent,
  }: DocumentSectionEditorV2Props) => {
    const {
      qualioDocument,
      currentEditorMode,
      collaborationToken,
      commentsPermission,
    } = useContext(DocumentOverviewContext);
    const { setDocEditorStatus } = useContext(EditorStatusContext);
    const currentUser = useCurrentUser();
    const { useLegacyDomainForMTFE } = useFlags();

    const inQualioFrontendApp = isInQualioFrontendApp(useLegacyDomainForMTFE);
    const [isSideBarRendered, sidebarElement] =
      useElementIsRendered('#commentsSidebar');
    const [, docPresenceListContainer] = useElementIsRendered(
      '.doc-overview-presence-list',
    );
    const presenceListContainer = inQualioFrontendApp
      ? docPresenceListContainer
      : sidebarElement;

    const [, toolbarWrapperElement] = useElementIsRendered('#toolbarWrapper');
    const [editors, setEditors] = useState<CKEditorInstance[]>([]);
    const { editorBundleEnabled, editorAi } = useFlags();
    const aiEnabled = editorAi || currentUser.company.ai_enabled;
    const { showToast } = useToastProvider();
    const contextConfig = useMemo(() => {
      return getContextConfig({
        collaborationToken,
        sidebarConfig: {
          sidebarElement,
          preventScrollOutOfView: true,
        },
        presenceListConfig: {
          containerElement: presenceListContainer,
        },
        editorBundleVersion: editorBundleEnabled
          ? process.env.REACT_APP_EDITOR_BUNDLE_VERSION
          : undefined,
        editorAiEnabled: aiEnabled,
      });
    }, [
      collaborationToken,
      sidebarElement,
      editorBundleEnabled,
      aiEnabled,
      presenceListContainer,
    ]);

    useEffect(() => {
      setDocEditorStatus(EditorStatus.CONNECTING);

      return () => {
        setDocEditorStatus(EditorStatus.STABLE);
      };
    }, [setDocEditorStatus]);

    useEffect(() => {
      const listener = () => {
        refreshDisplayMode({
          instances: editors,
          windowWidth: window.innerWidth,
          breakpointOverride: 1800,
        });
      };
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    }, [editors]);

    useEffect(() => {
      if (editors.length > 0) {
        if (commentsPermission <= 1) {
          return;
        }
        enableCKEditorMode(
          currentEditorMode,
          editors,
          new ToolbarManager(toolbarWrapperElement),
          () => {
            //still do nothing?
          },
        );
      }
    }, [currentEditorMode, editors, toolbarWrapperElement, commentsPermission]);

    const { smartlinkEverything } = useFlags();

    const onContextReady = useCallback(
      (context) =>
        createContextReadyHandlerV2(
          currentUser,
          (editors: CKEditorInstance[]) => {
            setEditors(editors);
          },
          commentsPermission,
          new ToolbarManager(toolbarWrapperElement),
          'documentCommentControl',
          handleAttachmentClick,
          'documentEditorContent',
          setDocEditorStatus,
          smartlinkEverything,
          handleSessionDisconnectionEvent,
        )(context),
      [
        currentUser,
        commentsPermission,
        toolbarWrapperElement,
        handleAttachmentClick,
        handleSessionDisconnectionEvent,
        setDocEditorStatus,
        smartlinkEverything,
      ],
    );

    const onContextError = useCallback(() => {
      return (e: any) => {
        logCustomError(CKE_EDITOR_ERROR, { error: e.toString() });
        handleError(genericApplicationErrorMessage);
      };
    }, []);

    // https://github.com/ckeditor/ckeditor5/issues/13098
    const watchdogConfig = useMemo(() => {
      return {
        saveInterval: 2147483647,
      };
    }, []);

    const handleAutoSave = useCallback(
      async (editor: CKEditorInstance): Promise<void> => {
        if (commentsPermission <= 1) {
          return;
        }
        await saveSectionFromEditor(
          qualioDocument,
          editor,
          (e) => {
            setDocEditorStatus(EditorStatus.ERROR);
            const errorMsg = isAttachmentError(e)
              ? buildAttachmentsErrorMessage(e, editor, qualioDocument)
              : e;
            showToast({
              id: 'document-editor-save-error',
              status: 'error',
              title: 'Error saving section content',
              description: buildToastDescription(errorMsg),
              replace: true,
            });
            return Promise.resolve();
          },
          () => {
            //no op
          },
        );
      },
      // Do not add showToast to this array, it will crash the editor mid render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [qualioDocument, setDocEditorStatus, commentsPermission],
    );

    if (!isSideBarRendered || !toolbarWrapperElement) {
      return <LoadingSpinner></LoadingSpinner>;
    }

    const isEditingSessionLoading = editors.length === 0;

    return (
      <>
        {isEditingSessionLoading && (
          <DocumentHTMLViewer
            viewDocument={qualioDocument}
            showTOC={false}
            showSidebar={false}
            setLoaded={false}
          />
        )}
        <div className={`${isEditingSessionLoading ? 'ck-hidden' : ''}`}>
          <CKEditorContext
            context={QualioContext}
            isLayoutReady={isSideBarRendered}
            config={contextConfig}
            onReady={onContextReady}
            watchdogConfig={watchdogConfig}
            onError={onContextError}
          >
            {qualioDocument.sections.map((section) => (
              <SectionedContentEditor
                key={`sectionEditor${section.position}`}
                section={section}
                handleAutoSave={handleAutoSave}
                showSectionTitles={qualioDocument.text_sections_titles_visible}
                entityType={'document.section'}
                collaborationEntityId={qualioDocument.id.toString()}
                collaborationEntityName={'document'}
              />
            ))}
          </CKEditorContext>
        </div>
      </>
    );
  },
);
