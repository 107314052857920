import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { useMemo } from 'react';

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QualioDocument } from '../../../api/model/document';
import { getContextConfig } from '../../../config/DocumentEditorConfig';
import {
  createContextReadyHandler,
  handleError,
} from '../../../config/handlers';
import QualioContext from '../../../editor/QualioContext';
import { useElementIsRendered } from '../../../hooks/ElementRenderedCheck';
import { CKEditorInstance } from '../../../types/CKEditorInstance';
import { ToolbarManager } from '../../../util/ToolbarManager';
import { isInQualioFrontendApp } from '../../../util/UrlUtils';
import { DocumentSectionEditor } from '../DocumentSectionEditor';

export interface DocumentSectionsManagerProps {
  document: QualioDocument;
  companyId: number;
  currentUser: CurrentUser;
  handleAutoSave: (editor: CKEditorInstance) => Promise<void>;
  commentsPermission: number;
  readyHandler: (editors: CKEditorInstance[]) => void;
  contextReadyCallback: any;
  inEditModeCallback: (inEditMode: boolean) => void;
  collaborationToken: string;
  inEditMode: boolean;
}

export const DocumentSectionsManager = (
  props: DocumentSectionsManagerProps,
) => {
  const {
    currentUser,
    commentsPermission,
    readyHandler,
    contextReadyCallback,
    inEditModeCallback,
    collaborationToken,
    ...otherProps
  } = props;
  const { useLegacyDomainForMTFE } = useFlags();

  const inQualioFrontendApp = isInQualioFrontendApp(useLegacyDomainForMTFE);
  const [isSideBarRendered, sidebarElement] =
    useElementIsRendered('#commentsSidebar');
  const [, docPresenceListContainer] = useElementIsRendered(
    '.doc-overview-presence-list',
  );
  const presenceListContainer = inQualioFrontendApp
    ? docPresenceListContainer
    : sidebarElement;

  const [, toolbarWrapperElement] = useElementIsRendered('#toolbarWrapper');

  const sectionProps = { commentsPermission, ...otherProps };
  const { editorBundleEnabled } = useFlags();
  const contextConfig = useMemo(() => {
    return getContextConfig({
      collaborationToken,
      sidebarConfig: {
        sidebarElement,
        preventScrollOutOfView: true,
      },
      presenceListConfig: {
        containerElement: presenceListContainer,
      },
      editorBundleVersion: editorBundleEnabled
        ? process.env.REACT_APP_EDITOR_BUNDLE_VERSION
        : undefined,
    });
  }, [
    collaborationToken,
    sidebarElement,
    editorBundleEnabled,
    presenceListContainer,
  ]);

  const { smartlinkEverything } = useFlags();

  const contextReadyHandler = useMemo(
    () =>
      createContextReadyHandler(
        currentUser,
        readyHandler,
        commentsPermission,
        inEditModeCallback,
        new ToolbarManager(toolbarWrapperElement),
        smartlinkEverything,
      ),
    [
      currentUser,
      commentsPermission,
      inEditModeCallback,
      readyHandler,
      toolbarWrapperElement,
      smartlinkEverything,
    ],
  );

  if (!isSideBarRendered || !toolbarWrapperElement) {
    return null;
  }

  return (
    <CKEditorContext
      context={QualioContext}
      isLayoutReady={isSideBarRendered}
      config={contextConfig}
      onReady={(context: any) => {
        contextReadyCallback(context);
        contextReadyHandler(context);
      }}
      // https://github.com/ckeditor/ckeditor5/issues/13098
      watchdogConfig={{
        saveInterval: 2147483647,
      }}
      onError={(msg: any) => {
        console.log('Error' + msg);
        handleError(msg);
      }}
    >
      {props.document.sections.map((section) => (
        <DocumentSectionEditor
          key={`sectionEditor${section.position}`}
          section={section}
          {...sectionProps}
        />
      ))}
    </CKEditorContext>
  );
};
