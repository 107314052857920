import { datadogRum } from '@datadog/browser-rum';
import { notifyParent } from '../../config/handlers/IFrameMessageHandler';
import { CKEditorInstance } from '../../types/CKEditorInstance';

const commandsToTrack = [
  {
    commandName: 'codeBlock',
    trackEventTitle: 'Document Editor | Code block toggled',
  },
  {
    commandName: 'acceptSuggestion',
    trackEventTitle: 'Document Editor | Suggestion Accepted',
  },
  {
    commandName: 'discardSuggestion',
    trackEventTitle: 'Document Editor | Suggestion Discarded',
  },
  {
    commandName: 'trackChanges',
    trackEventTitle: 'Document Editor | Suggestion Created',
  },
  {
    commandName: 'todoList',
    trackEventTitle: 'Document Editor | Checkbox toggled',
  },
  {
    commandName: 'QualioSectionListCommand',
    trackEventTitle: 'Document Editor | Section List Toggled',
  },
  {
    commandName: 'mention',
    trackEventTitle: 'Document Editor | Smart Link Created',
  },
  {
    commandName: 'insertTable',
    trackEventTitle: 'Document Editor | Table Created',
  },
  {
    commandName: 'pageBreak',
    trackEventTitle: 'Document Editor | Page Break Inserted',
  },
];

const trackCommentActions = (editor: CKEditorInstance) => {
  const commentRepository = editor.plugins.get('CommentsRepository') as any;
  if (commentRepository) {
    commentRepository.on('addComment', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: 'Document Editor | Comment replied to',
        },
      });
    });

    commentRepository.on('updateComment', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: 'Document Editor | Comment edited',
        },
      });
    });

    commentRepository.on('addCommentThread', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: 'Document Editor | Comment Thread created',
        },
      });
    });

    commentRepository.on('removeComment', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: 'Document Editor | Comment resolved',
        },
      });
    });

    commentRepository.on('removeCommentThread', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: 'Document Editor | Comment Thread resolved',
        },
      });
    });
  }
};

export enum FileUploadEvents {
  Upload = 'Document Editor | File type document uploaded',
  Delete = 'Document Editor | File type document removed',
  Export = 'Document Editor | File type document exported',
  Download = 'Document Editor | File type document downloaded',
}

export enum BulkUploadEvents {
  Upload = 'Bulk Import | File added',
  Delete = 'Bulk Import | File deleted',
  DropFiles = 'Bulk Import | Drop files',
  StartImport = 'Bulk Import | Import process started',
  ExceedSizeLimit = 'Bulk Import Error | File size limit exceeded',
  UnsuppportedFileType = 'Bulk Import Error | Unsupported file type',
  CompletedImport = 'Bulk Import Completion | Import process completed',
  FailedImport = 'Bulk Import Error | Import process failed',
}

export enum ExternalFileUploadEvents {
  Upload = 'Document Editor | External file type document uploaded',
  Updated = 'Document Editor | External file type document updated from source',
}

type TrackEvent = FileUploadEvents | BulkUploadEvents | string;

export const analyticsHandler = (
  trackEvent: TrackEvent,
  analytics: SegmentAnalytics.AnalyticsJS,
  properties?: Record<string, unknown>,
) => {
  analytics.track(trackEvent, properties);
};

export const configureAnalytics = (editor: CKEditorInstance) => {
  for (const trackable of commandsToTrack) {
    editor.commands.get(trackable.commandName)?.on('execute', () => {
      notifyParent({
        type: 'analytics',
        content: {
          event: trackable.trackEventTitle,
        },
      });
    });
  }
  trackCommentActions(editor);
};

export const configureDatadog = (user: any) => {
  datadogRum.init({
    applicationId: '6e82f390-698f-49e0-9b5e-a3f813c14a79',
    clientToken: 'pub9d769049fb79de0a8b13fbf4d1dabc3b',
    site: 'datadoghq.com',
    service: 'documenteditor',
    env: process.env.NODE_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask',
    silentMultipleInit: true,
  });
  datadogRum.setUser({
    id: user.userId?.toString(),
    company: user.companyId,
  });

  datadogRum.startSessionReplayRecording();
};

export const removeDatadog = () => {
  datadogRum.stopSessionReplayRecording();
};
