import { useCallback, useState } from 'react';

import { QualioDocument } from '../../../api/model/document';
import { CKEditorInstance } from '../../../types/CKEditorInstance';
import { DocumentContentEditor } from '../DocumentContentEditor/DocumentContentEditor';

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { notifyParent } from '../../../config/handlers/IFrameMessageHandler';
import { createEditorAutosaveHandler } from '../../../config/handlers/index';

export const DocumentEditor: React.FC<{
  editorDocument: QualioDocument;
  companyId: number;
  currentUser: CurrentUser;
  editorsReadyCallback: (arg: CKEditorInstance[]) => void;
  contextReadyCallback: (arg: any) => void;
  commentsPermission: number;
  handleAutoSaveError: (
    msg: string,
    sectionEditor?: CKEditorInstance,
    doc?: QualioDocument,
  ) => void;
  handleAutoSaveSuccess: () => void;
  collaborationToken: string;
}> = ({
  editorDocument,
  companyId,
  currentUser,
  editorsReadyCallback,
  contextReadyCallback,
  commentsPermission,
  handleAutoSaveError,
  handleAutoSaveSuccess,
  collaborationToken,
}) => {
  // inEditMode means that the editor is editable, either by
  // the edit mode or the trackchanges one
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [editorsReady, setEditorsReady] = useState<boolean>(false);
  const [startTime] = useState<number>(Date.now());
  // our version of jest is too old, and performance.mark
  // can't be mocked in tests
  performance.mark && performance.mark('time-to-editable-start');

  const handleEditorsReady = useCallback((editors: CKEditorInstance[]) => {
    editorsReadyCallback(editors);
    setEditorsReady(true);
    // we really don't need to track dependencies here
    // eslint-disable-next-line
  }, []);

  if (editorsReady) {
    notifyParent({
      type: 'analytics',
      content: {
        event: 'Document Editor | Time to Editable',
        properties: {
          time: Date.now() - startTime,
          userId: currentUser.userId,
          companyId: currentUser.companyId,
          documentId: editorDocument.id,
        },
      },
    });
  }

  // eslint-disable-next-line
  const handleAutoSave = useCallback(
    createEditorAutosaveHandler(
      editorDocument,
      handleAutoSaveError,
      handleAutoSaveSuccess,
    ),
    [],
  );

  return (
    <DocumentContentEditor
      editorsReady={editorsReady}
      inEditMode={inEditMode}
      commentsPermission={commentsPermission}
      editorDocument={editorDocument}
      handleAutoSave={handleAutoSave}
      handleEditorsReady={handleEditorsReady}
      handleContextReady={contextReadyCallback}
      inEditModeCallback={setInEditMode}
      companyId={companyId}
      currentUser={currentUser}
      collaborationToken={collaborationToken}
    />
  );
};
