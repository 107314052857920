import { ChangeControl, ChangeControlDTO } from '../types/DocumentCreate';
import { medtechApiClient } from './index';
import { QualioDocument } from './model/document';

export const changeControlApi = {
  async createChangeControl(
    document: QualioDocument,
    changeControlTemplateId: number,
    changeControl: ChangeControlDTO,
  ): Promise<any> {
    const payload = {
      company_id: document.company_id,
      type: changeControlTemplateId,
      documents: [document.id],
      sections: changeControl.sections,
      title: document.title,
    };
    return await medtechApiClient
      .post(`/changes`, payload)
      .then((res) => res.data);
  },

  async getChangeControls(
    companyId: number,
    documentId: number,
  ): Promise<ChangeControlDTO[]> {
    return medtechApiClient
      .get<ChangeControlDTO[]>(
        `/changes?company_id=${companyId}&document_id=${documentId}`,
      )
      .then((res) => res.data);
  },

  async fetchChangeControlTemplates() {
    const httpResp = await medtechApiClient.get(`/change_templates`);
    return httpResp.data;
  },

  async fetchChangeControl(
    docId: number,
    companyId: number,
  ): Promise<ChangeControl[]> {
    const httpResp = await medtechApiClient.get('/changes', {
      params: {
        company_id: companyId,
        document_id: docId,
        include_attachments: true,
        process_references: 1,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
    return httpResp.data;
  },

  async updateChangeControl(changeControlDto: ChangeControlDTO): Promise<any> {
    const httpResp = await medtechApiClient.patch(
      '/changes/' + changeControlDto.id,
      changeControlDto,
    );
    return httpResp.data;
  },
};
