import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { memo, useCallback, useContext, useEffect, useMemo } from 'react';

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { changeControlApi } from '../../../../api/changeControl';
import { getContextConfig } from '../../../../config/DocumentEditorConfig';
import {
  createContextReadyHandlerV2,
  enableCKEditorMode,
  handleError,
} from '../../../../config/handlers';
import QualioContext from '../../../../editor/QualioContext';
import { useElementIsRendered } from '../../../../hooks/ElementRenderedCheck';
import { CKEditorInstance } from '../../../../types/CKEditorInstance';
import { ChangeControlDTO } from '../../../../types/DocumentCreate';
import { refreshDisplayMode } from '../../../../util/SidebarUtils';
import { ToolbarManager } from '../../../../util/ToolbarManager';
import { isInQualioFrontendApp } from '../../../../util/UrlUtils';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../Context';
import { SectionedContentEditor } from '../DocumentEditorV2/DocumentContentEditor/DocumentSectionsManagerV2/DocumentSectionEditorV2/SectionedContentEditor';
import { HTMLViewer } from '../HTMLViewer';

export interface ChangeControlSectionsManagerProps {
  changeControl: ChangeControlDTO;
  setEditors: any;
  editors: CKEditorInstance[];
  handleAttachmentClick: (attachmentId: string) => void;
}

export const ChangeControlSectionsManager = memo(
  ({
    changeControl,
    setEditors,
    editors,
    handleAttachmentClick,
  }: ChangeControlSectionsManagerProps) => {
    const { currentEditorMode, commentsPermission, collaborationToken } =
      useContext(DocumentOverviewContext);
    const currentUser = useCurrentUser();

    const { useLegacyDomainForMTFE } = useFlags();

    const inQualioFrontendApp = isInQualioFrontendApp(useLegacyDomainForMTFE);
    const [isSideBarRendered, sidebarElement] = useElementIsRendered(
      '#changeControlSidebar',
    );
    const [, ccPresentListContainer] =
      useElementIsRendered('.cc-presence-list');
    const presenceListContainer = inQualioFrontendApp
      ? ccPresentListContainer
      : sidebarElement;

    const [isToolbarWrapperRendered, toolbarWrapper] = useElementIsRendered(
      '#changeControlToolbarWrapper',
    );
    const { editorBundleEnabled, editorAi } = useFlags();
    const aiEnabled = editorAi || currentUser.company.ai_enabled;
    const { showToast } = useToastProvider();
    const { setCCEditorStatus } = useContext(EditorStatusContext);
    const contextConfig = useMemo(() => {
      return getContextConfig({
        collaborationToken,
        sidebarConfig: {
          sidebarElement,
          preventScrollOutOfView: true,
        },
        presenceListConfig: {
          containerElement: presenceListContainer,
        },
        editorBundleVersion: editorBundleEnabled
          ? process.env.REACT_APP_EDITOR_BUNDLE_VERSION
          : undefined,
        editorAiEnabled: aiEnabled,
      });
    }, [
      collaborationToken,
      sidebarElement,
      editorBundleEnabled,
      aiEnabled,
      presenceListContainer,
    ]);

    useEffect(() => {
      setCCEditorStatus(EditorStatus.CONNECTING);

      return () => {
        setCCEditorStatus(EditorStatus.STABLE);
      };
    }, [setCCEditorStatus]);

    useEffect(() => {
      const listener = () => {
        refreshDisplayMode({
          instances: editors,
          windowWidth: window.innerWidth,
          breakpointOverride: 1800,
        });
      };
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    }, [editors]);

    useEffect(() => {
      if (editors.length > 0) {
        if (commentsPermission <= 1) {
          return;
        }
        enableCKEditorMode(
          currentEditorMode,
          editors,
          new ToolbarManager(toolbarWrapper),
          () => {
            //still do nothing?
          },
        );
      }
    }, [
      currentEditorMode,
      editors,
      handleAttachmentClick,
      toolbarWrapper,
      commentsPermission,
    ]);

    const { smartlinkEverything } = useFlags();

    const onContextReady = useCallback(
      (context) =>
        createContextReadyHandlerV2(
          currentUser,
          (editors: CKEditorInstance[]) => {
            setEditors(editors);
          },
          commentsPermission,
          new ToolbarManager(toolbarWrapper),
          'changeControlCommentControl',
          handleAttachmentClick,
          'changeControlEditorContent',
          setCCEditorStatus,
          smartlinkEverything,
        )(context),
      [
        currentUser,
        commentsPermission,
        toolbarWrapper,
        handleAttachmentClick,
        setEditors,
        setCCEditorStatus,
        smartlinkEverything,
      ],
    );

    const autoSaveChangeControl = useCallback(
      async (editor: CKEditorInstance) => {
        if (commentsPermission <= 1) {
          return;
        }
        const sectionIdToUpdate = editor.config.get('sectionId');
        const sectionData = editor.getData();
        const updatedSection = changeControl?.sections.find(
          (section) => section.id === sectionIdToUpdate,
        );
        if (!updatedSection) {
          return Promise.resolve();
        }
        updatedSection.content = sectionData;
        updatedSection.type = 'text';
        return await changeControlApi
          .updateChangeControl({
            ...changeControl,
            content_last_modified_at: Date.now() / 1000,
            sections: [updatedSection],
          })
          .catch((e) => {
            setCCEditorStatus(EditorStatus.ERROR);
            showToast({
              id: 'change-control-save-error',
              status: 'error',
              title: 'Error',
              description: `An error occurred during saving of this Change Control. Please try make another change to ensure data is saved. Error was ${e.message}`,
              replace: true,
            });
            return Promise.resolve();
          });
      },
      // Do not add showToast to this array, it will crash the editor mid render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [changeControl, setCCEditorStatus, commentsPermission],
    );

    // https://github.com/ckeditor/ckeditor5/issues/13098
    const watchdogConfig = useMemo(() => {
      return {
        saveInterval: 2147483647,
      };
    }, []);

    const onContextError = useCallback(() => {
      return (msg: any) => {
        console.log('Error' + msg);
        handleError(msg);
      };
    }, []);

    const isEditingSessionLoading = editors.length === 0;

    if (!isSideBarRendered || !isToolbarWrapperRendered) {
      return <LoadingSpinner></LoadingSpinner>;
    }

    return (
      <>
        {isEditingSessionLoading && (
          <HTMLViewer sections={changeControl.sections} />
        )}
        <div className={`${isEditingSessionLoading ? 'ck-hidden' : ''}`}>
          <CKEditorContext
            context={QualioContext}
            isLayoutReady={isSideBarRendered}
            config={contextConfig}
            onReady={onContextReady}
            watchdogConfig={watchdogConfig}
            onError={onContextError}
          >
            {changeControl.sections.map((section) => (
              <SectionedContentEditor
                key={`sectionEditor${section.position}`}
                section={section}
                handleAutoSave={autoSaveChangeControl}
                //always show section titles for change controls
                showSectionTitles={true}
                entityType={'change.section'}
                collaborationEntityId={changeControl.id!}
                collaborationEntityName={'cc'}
              />
            ))}
          </CKEditorContext>
        </div>
      </>
    );
  },
);
