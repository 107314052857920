import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QBodyLayout,
  QDataTable,
  QEmptyState,
  QHeading,
  QSpinner,
  QStack,
  Sorting,
  useCurrentUser,
} from '@qualio/ui-components';

import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { TagApi } from '../../../api/tag';
import { DocumentContainer } from '../DocumentContainer';
import { ExportButton } from './ExportButton';
import { SearchFilter } from './SearchFilter';
import { TagsFilter } from './TagsFilter';
import { FOR_TRAINING_FILTER, TrainingFilter } from './TrainingFilter';

const columnHelper = createQColumnHelper<QualioDocument>();

export const DEFAULT_PAGE_SIZE = 15;

const buildUrl = (id: number | undefined) => {
  return `/workspace/documents/${id}`;
};

const TagFilterDefinitions = {
  filteringTag: {
    label: 'Tags not selected',
    schema: Filtering.schemas.StringSchema(),
  },
  q: {
    label: 'Search',
    schema: Filtering.schemas.StringSchema(),
  },
  filter_by: {
    label: 'Filter by',
    schema: Filtering.schemas.StringSchema(),
  },
} as const;

export const DocumentLibraryV2: React.FC = () => {
  const currentUser = useCurrentUser();
  const {
    Provider: PaginationProvider,
    offset,
    limit,
  } = Pagination.useLimitOffset();

  const fetchDocuments = async ({
    pageParam,
  }: {
    pageParam: DataProvider.PageParams;
  }): Promise<DataProvider.TDataWithCount<QualioDocument>> => {
    const params = pageParam?.toUrlSearchParams();
    const tagsParam = params.get('filteringTag');
    const tagsIds = tagsParam ? tagsParam.split(',') : [];
    const orderBy = params.get('order_by');
    const searchQuery = params.get('query');
    const filterBy = params.get('filter_by');
    const fetchMethod =
      filterBy === FOR_TRAINING_FILTER
        ? documentApi.fetchUserTrainingDocs
        : documentApi.fetchAll;
    const results = await fetchMethod(
      currentUser,
      [DocumentStatus.Effective],
      searchQuery,
      tagsIds,
      offset,
      limit,
      orderBy,
    );

    return {
      itemCount: results.total,
      data: results.documents,
    };
  };

  const loadTags = async (): Promise<Tag[]> => TagApi.fetchTags(currentUser);

  const { data: fetchedTags = [], isLoading: isLoadingTags } = useQuery({
    queryFn: loadTags,
    queryKey: ['tags', currentUser.companyId],
  });

  const handleTitleAction = (qualioDocument: QualioDocument) => {
    return buildUrl(qualioDocument.id);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.id((document) => document.code, {
        header: 'ID',
        id: 'code',
        minWidth: '96px',
      }),
      columnHelper.textLink('title', handleTitleAction, {
        header: 'Title',
        id: 'title',
        minWidth: '305px',
      }),
      columnHelper.text((document) => document.type, {
        header: 'Type',
        id: 'type',
        minWidth: '200px',
      }),
      columnHelper.tag(
        (document) => {
          const filteredTags = fetchedTags.filter((fetchedTag) =>
            document.tag_ids.includes(fetchedTag.id),
          );
          const tagNames = filteredTags.map((tag) => tag.name);
          return tagNames;
        },
        {
          statuses: { '*': 'default' },
          header: 'Tags',
          enableSorting: false,
        },
      ),
      columnHelper.text(
        (document) => `${document.major_version}.${document.minor_version}`,
        {
          header: 'Version',
          id: 'version',
          minWidth: '200px',
        },
      ),
    ];
  }, [fetchedTags]);

  if (isLoadingTags) {
    return (
      <QSpinner
        data-cy="library-loading-spinner"
        data-testid="library-loading-spinner"
      />
    );
  }

  return (
    <DocumentContainer>
      <div style={{ width: '100%' }}>
        <QBodyLayout.Default>
          <PaginationProvider>
            <Sorting.DefaultSortingProvider sortByKey="order_by">
              <Filtering.FilterProvider
                searchTermKey="query"
                definitions={TagFilterDefinitions}
              >
                <DataProvider.Remote
                  queryFn={fetchDocuments}
                  queryKey={['getDocuments', currentUser.userId]}
                  queryParamKeys={{
                    searchTerm: 'query',
                    sortBy: 'order_by',
                    pageSize: 'limit',
                    pageIndex: 'pageIndex',
                  }}
                >
                  <QStack isInline justify="space-between">
                    <QHeading size="lg" mb={6} data-cy="library-heading">
                      Library
                    </QHeading>
                    <ExportButton />
                  </QStack>
                  <QStack isInline>
                    <TagsFilter
                      isLoadingTags={isLoadingTags}
                      fetchedTags={fetchedTags}
                    />
                    <TrainingFilter />
                    <SearchFilter id="library" />
                  </QStack>
                  <QDataTable columns={columns}>
                    <QEmptyState
                      title="No documents found!"
                      subtitle="There are no approved or effective documents in this instance."
                      data-cy="library-table-empty-state"
                      data-testid="library-table-empty-state"
                    />
                  </QDataTable>
                </DataProvider.Remote>
              </Filtering.FilterProvider>
            </Sorting.DefaultSortingProvider>
          </PaginationProvider>
          <></>
        </QBodyLayout.Default>
      </div>
    </DocumentContainer>
  );
};
