import {
  QActivity,
  QActivityProps,
  QBox,
  QIcon,
  QStack,
  QTarget,
  useCurrentUser,
} from '@qualio/ui-components';
import { ActivityLog } from '../../../api/model/activityLog';
import {
  DocumentFormat,
  DocumentStatus,
  isDocumentFormat,
} from '../../../api/model/document';
import { MedtechCompanyUserGroup } from '../../../api/user';
import {
  ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { convertEpochToDate } from '../../../util/DateUtils';
import { FileDownloadPermissionOptions } from '../DocumentOverview/DocumentProperties/utils';
import { DocumentStatusCell } from '../DocumentStatusCell/DocumentStatusCell';
import { FormattedActionHeader } from './FormattedActionHeader';
import { QActivityText } from './QActivityText';
import { QActivityTransition } from './QActivityTransition';
import { QPersonList } from './QPersonList';

export type ActivityLogCardProps = {
  actor: ActivityLog['actor'];
  actionType: ActivityLog['action'];
  timestamp: ActivityLog['timestamp'];
  data: ActivityLog['data'];
};

const translateValue = (value: number | boolean | string) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else if (typeof value === 'number') {
    return `${value} month${value > 1 ? 's' : ''}`;
  } else if (!value) {
    return 'None';
  } else if (isDocumentFormat(value)) {
    return value === DocumentFormat.QualioNativeDocument
      ? 'Qualio Document'
      : 'Uploaded Document';
  } else {
    return value;
  }
};

export const ActivityLogCardWrapper = ({
  actor,
  actionType,
  timestamp,
  data,
}: ActivityLogCardProps) => {
  const currentUser = useCurrentUser();

  const commonProps: Pick<QActivityProps, 'fullName' | 'actionTimestamp'> = {
    fullName: actor?.full_name ?? 'Qualio',
    actionTimestamp: currentUser.formatDate(
      convertEpochToDate(timestamp),
      true,
    ),
  };

  const translatePermissionArray = (groups: MedtechCompanyUserGroup[]) => {
    if (groups.includes('read_only')) {
      return FileDownloadPermissionOptions.read_only;
    } else if (groups.includes('normal')) {
      return FileDownloadPermissionOptions.normal;
    } else if (groups.includes('quality')) {
      return FileDownloadPermissionOptions.quality;
    } else {
      return FileDownloadPermissionOptions.owner;
    }
  };

  switch (actionType) {
    case 'modified_quality_approvers':
    case 'modified_other_approvers':
    case 'modified_reviewers':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QStack
            style={{ flexWrap: 'wrap', rowGap: '8px' }}
            spacing={0}
            direction="row"
          >
            <QPersonList users={data.added_users} status={'added'} />
            <QPersonList users={data.removed_users} status={'removed'} />
          </QStack>
        </QActivity>
      );
    case 'approved':
    case 'declined':
    case 'reviewed':
      return (
        <QActivity
          status={actionType}
          actionLabel={data.document_version}
          {...commonProps}
        >
          {data.comment && <QActivityText text={data.comment} />}
          <QTarget>v{data.document_version}</QTarget>
        </QActivity>
      );
    case 'modified_doc_title':
    case 'modified_doc_review_period':
    case 'modified_doc_training_required':
    case 'modified_doc_effective_on_approval':
    case 'modified_doc_format':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={translateValue(data.old_value)}
            newValue={translateValue(data.new_value)}
          />
        </QActivity>
      );
    case 'modified_file_download_permission_group':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={translatePermissionArray(data.old_value)}
            newValue={translatePermissionArray(data.new_value)}
          />
        </QActivity>
      );
    case 'upgraded_document_template':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={`Template version ${data.previous_template_version}`}
            newValue={`Template version ${data.new_template_version}`}
          />
        </QActivity>
      );
    case 'modified_doc_owner':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={data.removed_users[0].full_name}
            newValue={data.added_users[0].full_name}
          />
        </QActivity>
      );
    case 'changed_status':
      return (
        <QActivity status="changedStatus" {...commonProps}>
          <QTarget>v{data.document_version}</QTarget>

          <QStack direction="column">
            <QStack
              style={{ flexWrap: 'wrap', rowGap: '8px' }}
              spacing={0}
              direction="row"
              alignItems="center"
            >
              <DocumentStatusCell
                statusId={data.previous_status as DocumentStatus}
              />
              <QStack paddingInline="8px">
                <QIcon iconName="ArrowRight" />
              </QStack>
              <DocumentStatusCell
                statusId={data.new_status as DocumentStatus}
              />
            </QStack>

            {data.comment && (
              <QBox>
                <QActivityText text={data.comment} />
              </QBox>
            )}
          </QStack>
        </QActivity>
      );
    default:
      logCustomError(ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR, {
        error: {
          message: 'Unknown activity log action type',
          data: { actionType, actor, timestamp, data },
        },
      });
      return null;
  }
};
