import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  deprecated,
  QBodyLayout,
  QBox,
  QEmptyState,
  QFlex,
  QHeading,
  QInput,
  QMultiSelect,
  QSelect,
  QSelectItem,
  QSelectPlaceholder,
  QSpinner,
  QStack,
  QText,
  QualioQThemeProvider,
  ToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { documentApi } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { TagApi } from '../../../api/tag';

import { useDebounce } from '../../../hooks/Debounce';

import { DocumentCodeCell } from '../DocumenCodeCell/DocumentCodeCell';
import { DocumentContainer } from '../DocumentContainer';
import {
  buildSortingRules,
  buildSortingValue,
  buildSortSearchParams,
  SortCallbackObj,
} from '../DocumentList';
import { DocumentTagsCell } from '../DocumentTagsCell/DocumentTagsCell';
import { ExportDocumentListButton } from '../Export/ExportDocumentListButton/ExportDocumentListButton';
import { TagLabel } from '../TagLabel/TagLabel';

import styles from './DocumentLibrary.module.css';

export const DEFAULT_PAGE_SIZE = 25;
export const FOR_TRAINING_FILTER = 'for_training';
export const ALL_DOCS_FILTER = 'all_docs';

type CellRenderProps<T extends keyof QualioDocument> = {
  row: {
    original: QualioDocument;
  };
  cell: {
    value: T extends keyof QualioDocument ? QualioDocument[T] : never;
  };
};

export const DocumentLibrary: React.FC<{ currentUser: CurrentUser }> = ({
  currentUser,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestedTagFilter = searchParams.get('filteringTag');
  const requestedSearchFilter = searchParams.get('q');
  const requestedPageNumber = searchParams.get('page');
  const requestedPageCount = searchParams.get('count');
  const orderByParam = searchParams.get('order_by');
  const [searchQuery, setSearchQuery] = useState<string | null>(
    requestedSearchFilter ?? null,
  );
  const [currentPage, setCurrentPage] = useState<number>(
    requestedPageNumber ? Number(requestedPageNumber) - 1 : 0,
  );
  const [currentPageCount, setCurrentPageCount] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(
    requestedPageCount ? Number(requestedPageCount) : DEFAULT_PAGE_SIZE,
  );
  const [sortingRules, setSortingRules] = useState<SortCallbackObj[]>(
    buildSortingRules(orderByParam) as SortCallbackObj[],
  );
  const { qualioFrontendRefresh } = useFlags();
  const navigate = useNavigate();
  const disabledTrainingFilterOption = {
    label: 'All documents',
    value: ALL_DOCS_FILTER,
  };
  const enabledTrainingFilterOption = {
    label: 'Training documents',
    value: FOR_TRAINING_FILTER,
  };
  const trainingFilterOptions: QSelectItem[] = [
    disabledTrainingFilterOption,
    enabledTrainingFilterOption,
  ];
  const [trainingFilter, setTrainingFilter] = useState<string>(
    disabledTrainingFilterOption.value,
  );
  const debouncedSearchQuery = useDebounce<string | null>(searchQuery);
  const paginationOffset = currentPageSize * currentPage;

  const [tagsFilter, setTagsFilter] = useState<string[]>(
    requestedTagFilter ? requestedTagFilter.split(',') : [],
  );

  const sortingValue = useMemo(() => {
    return sortingRules.length > 0 ? buildSortingValue(sortingRules) : null;
  }, [sortingRules]);

  const loadDocuments = useCallback(async () => {
    const fetchMethod =
      trainingFilter === FOR_TRAINING_FILTER
        ? documentApi.fetchUserTrainingDocs
        : documentApi.fetchAll;
    try {
      return await fetchMethod(
        currentUser,
        [DocumentStatus.Effective],
        searchQuery,
        tagsFilter,
        paginationOffset,
        currentPageSize,
        sortingValue,
      );
    } catch (e) {
      return;
    }
  }, [
    currentPageSize,
    currentUser,
    paginationOffset,
    searchQuery,
    tagsFilter,
    trainingFilter,
    sortingValue,
  ]);

  const sortCallback = (sortingRules: SortCallbackObj[]) => {
    const newSearchParams = buildSortSearchParams(sortingRules, searchParams);
    setSearchParams(newSearchParams);
    setSortingRules(sortingRules);
  };

  const loadTags = async (): Promise<Tag[]> => TagApi.fetchTags(currentUser);

  const {
    error: errorDocuments,
    isLoading: isLoadingDocuments,
    data,
  } = useQuery({
    queryFn: loadDocuments,
    queryKey: [
      'documents',
      debouncedSearchQuery,
      tagsFilter,
      paginationOffset,
      currentPageSize,
      trainingFilter,
      sortingRules,
    ],
  });

  let noEffectiveOrApprovedDocs = false;

  if (tagsFilter.length === 0 && !debouncedSearchQuery) {
    noEffectiveOrApprovedDocs = data ? data.total === 0 : false;
  }

  const {
    error: errorTags,
    isLoading: isLoadingTags,
    data: fetchedTags = [],
  } = useQuery({
    queryFn: loadTags,
    queryKey: ['tags', currentUser.companyId],
  });

  const tagItems: QSelectItem<JSX.Element, string>[] = useMemo(
    () =>
      fetchedTags.map((tag) => ({
        label: <TagLabel tag={tag} />,
        value: String(tag.id),
      })),
    [fetchedTags],
  );

  const tagsMap = useMemo(
    () =>
      fetchedTags.reduce((acc: Map<Tag['id'], Tag>, tag: Tag) => {
        acc.set(tag.id, tag);
        return acc;
      }, new Map()),
    [fetchedTags],
  );

  const onTagFilterChange = (
    tagItems: readonly QSelectItem<JSX.Element, string>[],
  ) => {
    const tagIds = [];
    for (const tagItem of tagItems) {
      tagIds.push(tagItem.value);
    }
    setTagsFilter(tagIds);
    searchParams.set('filteringTag', tagIds.join(','));
    setSearchParams(searchParams);
  };

  if (errorDocuments || errorTags) {
    console.log('Error', { errorDocuments, errorTags });
  }

  const onTrainingFilterChange = (
    selectedTrainingFilterOption: QSelectItem | null,
  ) => {
    if (selectedTrainingFilterOption) {
      setTrainingFilter(selectedTrainingFilterOption.value);
    }
  };

  const paginationCallback = (pageIndex: number, pageSize: number) => {
    searchParams.set('count', pageSize.toString());
    setSearchParams(searchParams);
    setCurrentPage(pageIndex);
    setCurrentPageSize(pageSize);
    setCurrentPageCount(Math.ceil((data?.total ?? 1) / currentPageSize));
  };

  const manualPagination = {
    paginationCallback,
    pageCount: currentPageCount,
    initialPageSize: currentPageSize,
    initialPageIndex: currentPage,
  };

  const showNoEffectiveDocuments =
    noEffectiveOrApprovedDocs && !isLoadingDocuments;
  const showNoResults =
    !noEffectiveOrApprovedDocs &&
    data?.documents.length === 0 &&
    !isLoadingDocuments;
  const showTable =
    !noEffectiveOrApprovedDocs && !showNoResults && !isLoadingDocuments;

  const onSearchFilterChange = (evt: any) => {
    searchParams.set('q', evt.target.value);
    setSearchParams(searchParams);
    setSearchQuery(evt.target.value);
  };

  return (
    <DocumentContainer>
      <div style={{ width: '100%' }}>
        <QualioQThemeProvider>
          <ToastProvider>
            <QBodyLayout.Default>
              <QStack isInline justify="space-between">
                <QHeading size="lg" mb={6}>
                  Library
                </QHeading>
                <ExportDocumentListButton
                  currentUser={currentUser}
                  tagsFilter={tagsFilter}
                  isDisabled={!data || data.total === 0}
                  searchQuery={searchQuery}
                  sortingValue={sortingValue}
                ></ExportDocumentListButton>
              </QStack>
              {showNoEffectiveDocuments ? (
                <QEmptyState
                  title="No documents found!"
                  subtitle="There are no approved or effective documents in this instance."
                />
              ) : null}
              {!showNoEffectiveDocuments ? (
                <QStack isInline justifyContent="space-between">
                  <QBox
                    className={`${styles['document-library__tag-multiselect']}`}
                  >
                    <QMultiSelect
                      isLoading={isLoadingTags}
                      size="sm"
                      options={tagItems}
                      onChange={onTagFilterChange}
                      value={tagsFilter}
                      data-cy={'library-tags'}
                      filterOption={(tagItem, query) => {
                        const tag = tagsMap.get(parseInt(tagItem.value));
                        return Boolean(
                          tag?.name.toLowerCase().includes(query.toLowerCase()),
                        );
                      }}
                    >
                      <QSelectPlaceholder>
                        <QText>Filter by tags</QText>
                      </QSelectPlaceholder>
                    </QMultiSelect>
                  </QBox>
                  {!currentUser.permissions.can_view_workspace && (
                    <QBox width={'25%'}>
                      <QSelect
                        size="sm"
                        options={trainingFilterOptions}
                        onChange={onTrainingFilterChange}
                        value={trainingFilter}
                        data-cy={'library-training-filter'}
                        aria-label="Training filter"
                      />
                    </QBox>
                  )}

                  <QInput
                    iconLeftName="Search"
                    onChange={onSearchFilterChange}
                    placeholder={'Search...'}
                    value={searchQuery ?? ''}
                    data-testid={'library-search'}
                    data-cy={'library-search'}
                  ></QInput>
                </QStack>
              ) : null}
              {isLoadingDocuments ? (
                <QFlex
                  data-testid={'library-loading-spinner'}
                  justify="center"
                  m={10}
                >
                  <QSpinner />
                </QFlex>
              ) : null}
              {showNoResults ? (
                <QEmptyState
                  title="No matching documents found!"
                  subtitle="Your search or filter produced no results. Please adjust and try again."
                />
              ) : null}
              {data && showTable ? (
                <>
                  <QStack>
                    <QBox
                      color="gray.500"
                      fontWeight="semibold"
                      pt="6"
                      data-cy={'library-search-result-summary'}
                      fontSize="sm"
                    >
                      {data.total} document
                      {data.total > 1 ? 's' : ''}
                    </QBox>
                  </QStack>
                  <deprecated.QDataTable
                    data-cy="library-table"
                    manualPagination={manualPagination}
                    columns={[
                      {
                        Header: 'ID',
                        accessor: 'code',
                        Cell: ({
                          cell: { value: code },
                        }: CellRenderProps<'code'>) => (
                          <DocumentCodeCell documentCode={code} />
                        ),
                        width: '10%',
                      },
                      {
                        Header: 'Title',
                        accessor: (document: QualioDocument) => (
                          <span data-cy={`table-doc-${document.code}-title`}>
                            {document.title}
                          </span>
                        ),
                        width: '30%',
                      },
                      {
                        Header: 'Type',
                        accessor: (document: QualioDocument) => (
                          <span data-cy={`table-doc-${document.code}-type`}>
                            {document.type}
                          </span>
                        ),
                        width: '20%',
                      },
                      {
                        Header: 'Tags',
                        accessor: 'tag_ids',
                        Cell: ({
                          cell: { value: tagIds },
                        }: CellRenderProps<'tag_ids'>) => (
                          <DocumentTagsCell
                            tagIds={tagIds}
                            availableTags={fetchedTags}
                          />
                        ),
                        width: '30%',
                        disableSortBy: true,
                      },
                      {
                        Header: 'Version',
                        accessor: (document: QualioDocument) =>
                          `${document.major_version}.${document.minor_version}`,
                        width: '10%',
                      },
                    ]}
                    data={data?.documents as any}
                    onRowClick={(clicked) => {
                      if (qualioFrontendRefresh) {
                        navigate(`/library/e/${clicked.id}`);
                      } else {
                        window.location.assign(`/library/e/${clicked.id}`);
                      }
                    }}
                    hasPagination={true}
                    hasPageSizeOptions={true}
                    manualSortBy={{
                      sortByCallback: sortCallback,
                      defaultSortByColumn: sortingRules,
                    }}
                  />
                </>
              ) : null}
            </QBodyLayout.Default>
          </ToastProvider>
        </QualioQThemeProvider>
      </div>
    </DocumentContainer>
  );
};
