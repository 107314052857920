import {
  QBadge,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QEmptyState,
  QHeader,
  QHorizontalMetadata,
  QLastModifiedMetadata,
  QOwnerMetadata,
  QPageLoader,
  QStack,
  QTab,
  QTabList,
  QTabs,
  QTag,
  QTitle,
  QualioQThemeProvider,
  QVersionMetadata,
  ToastProvider,
  useCurrentUser,
} from '@qualio/ui-components';
import diff from 'node-htmldiff';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { documentApi } from '../../../api/document';
import {
  sortVersions,
  versionIsGreaterThan,
} from '../DocumentOverview/RenderLogic';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { DocumentStatus } from '../../../api/model/document';
import { useBodyBackground } from '../../../hooks/BodyBackground';
import { isBasicUserInAccount } from '../../../util/CurrentUser';
import { convertApiDocumentToViewableInstance } from '../../../util/DocumentApiUtils';
import { DocumentStatusIdToStatusConfigMap } from '../../../util/WorkspaceUtils';
import { BannerPlaceholder } from '../BannerPlaceholder';
import { DocumentCompareVersionsViewer } from '../DocumentCompareVersionsViewer/index';
import { DocumentContainer } from '../DocumentContainer/index';
import './DocumentCompareVersions.module.css';
import { parseVersion } from './util';
import { VersionSelect } from './VersionSelect';

export const DocumentCompareVersions = () => {
  useBodyBackground('document-editor-overview-refresh', true);
  const { id, baseCompare } = useParams();
  const [baseCompareVersion, setBaseCompareVersion] = useState(baseCompare);
  const [searchParams, setSearchParams] = useSearchParams();
  const compareAgainst = searchParams.get('version');
  const currentUser = useCurrentUser();
  const [compareAgainstVersion, setCompareAgainstVersion] = useState<
    string | null
  >(compareAgainst);

  const { compareAgainstUpdates } = useFlags();

  const showIncompatibleVersionsState = useMemo(() => {
    if (baseCompareVersion === compareAgainstVersion) {
      return false;
    }

    return (
      baseCompareVersion &&
      compareAgainstVersion &&
      !versionIsGreaterThan(
        parseVersion(compareAgainstVersion),
        parseVersion(baseCompareVersion),
      )
    );
  }, [baseCompareVersion, compareAgainstVersion]);

  const location = useLocation();
  const navigate = useNavigate();

  const loadDocument = useCallback(async () => {
    if (!id) {
      return;
    }

    const fetchedDocument = await documentApi.fetchByIdOrVersion(
      id,
      compareAgainstVersion ?? '',
      1,
      1,
    );

    return convertApiDocumentToViewableInstance(fetchedDocument);
  }, [id, compareAgainstVersion]);

  const loadCompareDocument = useCallback(async () => {
    if (!id) {
      return;
    }

    const fetchedDocument = await documentApi.fetchByIdOrVersion(
      id,
      baseCompareVersion ?? '',
      1,
      1,
    );

    return convertApiDocumentToViewableInstance(fetchedDocument);
  }, [id, baseCompareVersion]);

  const {
    isLoading: isLoadingDocument,
    isFetching: isFetchingDocument,
    data: qualioDocument,
  } = useQuery({
    queryKey: ['newDocument', id, compareAgainstVersion],
    queryFn: loadDocument,
    refetchOnWindowFocus: false,
  });

  const { data: compareDocument } = useQuery({
    queryKey: ['newDocument', id, baseCompareVersion],
    queryFn: loadCompareDocument,
    refetchOnWindowFocus: false,
    enabled: compareAgainstUpdates,
  });

  const loadDocumentRevisions = useCallback(async () => {
    if (!id || !compareAgainst || !baseCompareVersion) {
      return;
    }
    return await documentApi.getDocumentRevisionsBetweenVersions(
      parseInt(id),
      compareAgainstVersion as string,
      baseCompareVersion,
    );
  }, [id, compareAgainst, compareAgainstVersion, baseCompareVersion]);

  const {
    isLoading: isLoadingRevisions,
    isFetching: isFetchingRevisions,
    data: revisions,
  } = useQuery({
    queryKey: ['newDocument', id, compareAgainstVersion, baseCompareVersion],
    queryFn: loadDocumentRevisions,
    refetchOnWindowFocus: false,
  });

  const {
    data: versions = [],
    isLoading: isLoadingVersions,
    isFetching: isFetchingVersions,
  } = useQuery({
    queryKey: ['versions', id],
    queryFn: () => documentApi.getDocumentVersionsByDocumentId(+id!),
    refetchOnWindowFocus: false,
    enabled: !!revisions,
  });

  const handleCompareAgainstVersionsOptionChange = (version: string) => {
    setCompareAgainstVersion(version);
    searchParams.set('version', version);
    setSearchParams(searchParams);
  };

  const lhsVersions = useMemo(() => {
    return versions.filter((version) => version.version !== baseCompareVersion);
  }, [versions, baseCompareVersion]);

  const handleBaseCompareVersionsOptionChange = (version: string) => {
    if (version === '') {
      const basePath = location.pathname.split('/compare/')[0];
      if (!compareAgainstVersion || compareAgainstVersion === latestVersion) {
        navigate(basePath);
      } else {
        navigate({
          pathname: basePath,
          search: createSearchParams({
            version: compareAgainstVersion,
          }).toString(),
        });
      }
    } else {
      setBaseCompareVersion(version);

      const newPath = location.pathname.replace(baseCompare as string, version);
      navigate({
        pathname: newPath,
        search: createSearchParams({
          version: compareAgainstVersion as string,
        }).toString(),
      });
    }
  };

  const sortedVersions = useMemo(
    () => (versions ? sortVersions(versions) : []),
    [versions],
  );

  const latestVersion = useMemo(
    () =>
      sortedVersions.length > 0
        ? sortedVersions[sortedVersions.length - 1].version
        : '',
    [sortedVersions],
  );

  const documentStatusProps = useMemo(() => {
    if (qualioDocument?.status_id) {
      return DocumentStatusIdToStatusConfigMap[
        qualioDocument.status_id as DocumentStatus
      ];
    }
  }, [qualioDocument?.status_id]);

  if (
    !qualioDocument ||
    isLoadingDocument ||
    isFetchingDocument ||
    !revisions ||
    isLoadingRevisions ||
    isFetchingRevisions ||
    isLoadingVersions ||
    isFetchingVersions
  ) {
    return (
      <>
        <BannerPlaceholder />
        <DocumentContainer>
          <QualioQThemeProvider>
            <QPageLoader />
          </QualioQThemeProvider>
        </DocumentContainer>
      </>
    );
  }

  if (compareDocument && qualioDocument && compareAgainstUpdates) {
    for (let i = 0; i < compareDocument.sections.length; i++) {
      const baseSection = compareDocument.sections[i];
      const newSection = qualioDocument.sections[i];
      let revision = '';
      if (qualioDocument.sections[i]) {
        //compare two sections
        revision = diff(baseSection.content, newSection.content);
      } else {
        revision = diff(baseSection.content, '');
      }
      const selectedRevision = revisions.at(i);
      if (selectedRevision) {
        selectedRevision.content = revision;
      }
    }
  }

  return (
    <>
      <BannerPlaceholder />
      <DocumentContainer>
        <QualioQThemeProvider>
          <ToastProvider enableDelegation={true}>
            <QBodyLayout.Default>
              <QHeader>
                <QTitle>
                  {qualioDocument.title}{' '}
                  <QBadge data-cy="document-code">{qualioDocument.code}</QBadge>
                </QTitle>

                <QHorizontalMetadata>
                  <QTag
                    data-cy="document-status"
                    variantColor={documentStatusProps?.color}
                  >
                    {documentStatusProps?.label}
                  </QTag>
                  <QVersionMetadata
                    data-cy="document-version"
                    value={
                      qualioDocument.major_version +
                      '.' +
                      qualioDocument.minor_version
                    }
                  />
                  <QOwnerMetadata
                    data-cy="document-owner"
                    value={qualioDocument.owner.full_name}
                  />
                  <QLastModifiedMetadata
                    data-cy="document-last-modified"
                    value={
                      new Date(qualioDocument.content_last_modified_at * 1000)
                    }
                  />
                </QHorizontalMetadata>

                <QButtonGroup>
                  <QButton
                    onClick={() => handleBaseCompareVersionsOptionChange('')}
                  >
                    {' '}
                    Stop comparing
                  </QButton>
                </QButtonGroup>
              </QHeader>
              <QStack direction="row">
                <QBox width={'100%'}>
                  <QTabs>
                    <QTabList>
                      <QTab
                        data-testid="document-tab-compare-view"
                        data-cy="document-tab-compare-view"
                        isDisabled={true}
                      >
                        Document
                      </QTab>

                      <QStack direction="row" ml="auto" spacing="2">
                        {!isBasicUserInAccount(currentUser) && (
                          <VersionSelect
                            latestVersion={latestVersion}
                            handleOptionChange={
                              handleCompareAgainstVersionsOptionChange
                            }
                            variant="LHS"
                            version={compareAgainstVersion}
                            allVersions={lhsVersions}
                          />
                        )}

                        <VersionSelect
                          latestVersion={latestVersion}
                          handleOptionChange={
                            handleBaseCompareVersionsOptionChange
                          }
                          variant="RHS"
                          version={baseCompareVersion}
                          allVersions={versions}
                          LHSVersion={compareAgainstVersion}
                        />
                      </QStack>
                    </QTabList>
                  </QTabs>
                </QBox>
              </QStack>

              {showIncompatibleVersionsState ? (
                <>
                  <QEmptyState
                    title="Looks like we don't support this capability"
                    subtitle="Please compare an older version against the currently selected compare version."
                  />
                </>
              ) : (
                <DocumentCompareVersionsViewer sectionRevisions={revisions} />
              )}
            </QBodyLayout.Default>
          </ToastProvider>
        </QualioQThemeProvider>
      </DocumentContainer>
    </>
  );
};
