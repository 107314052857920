import {
  QAlert,
  QBox,
  QButton,
  QCheckbox,
  QLink,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
} from '@qualio/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { documentApi } from '../../../api/document';
import { QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { TagLabel } from '../TagLabel/TagLabel';
import {
  MedtechDocsVisibilityChangedError,
  MedtechDocsVisibilityChangedErrorResponse,
  MedtechUserVisiblityError,
  TagId,
  VisibilityError,
} from './types';

type WarningData = MedtechDocsVisibilityChangedError & {
  warningText: string;
  description: string;
  code: VisibilityError;
  tagsToAdd: Set<TagId>;
  tagsToRemove: Set<TagId>;
};

const WarningLabelMap: {
  [K in VisibilityError]: {
    warningText: WarningData['warningText'];
    description: WarningData['description'];
  };
} = {
  authors_lost_docs_and_docs_visibility_changed: {
    warningText: "Some users' ability to view this content will be affected.",
    description: 'These users will no longer be able to view their content:',
  },
  documents_visibility_changed: {
    warningText: "Some users' ability to view this content will be affected.",
    description: 'The following documents will be affected:',
  },
};

type ManageDocumentTagsModalProps = {
  selectedDocuments: QualioDocument[];
  tags: Tag[];
  modalDescription: string;
  onClose: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSuccess: (onSuccessProps: { removedTags: number[] }) => void;
  onError?: () => void;
};
export const ManageDocumentTagsModal = ({
  selectedDocuments,
  tags,
  modalDescription,
  onClose,
  isOpen,
  setIsOpen,
  onSuccess,
  onError,
}: ManageDocumentTagsModalProps) => {
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const [addedTagIds, setAddedTagIds] = useState(new Set<TagId>());
  const [removedTagIds, setRemovedTagIds] = useState(new Set<TagId>());
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const onWarningClose = () => setIsWarningModalOpen(false);
  const [warningData, setWarningData] = useState<WarningData>();

  const alreadyCheckedTagIds = useMemo(() => {
    return selectedDocuments.length && isOpen
      ? new Set(
          tags.reduce((acc, { id }) => {
            const isTagInAllDocs = selectedDocuments.every(({ tag_ids }) =>
              tag_ids.includes(id),
            );
            if (isTagInAllDocs) {
              acc.add(id);
            }
            return acc;
          }, new Set<TagId>()),
        )
      : new Set<TagId>();
  }, [isOpen, selectedDocuments, tags]);

  useEffect(() => {
    setAddedTagIds(alreadyCheckedTagIds);
  }, [alreadyCheckedTagIds]);

  const handleCheckboxChange = (tagId: Tag['id'], isChecked: boolean) => {
    if (isChecked) {
      setAddedTagIds(new Set(addedTagIds).add(tagId));
      if (removedTagIds.has(tagId)) {
        setRemovedTagIds((tags) => {
          const ids = new Set(tags);
          ids.delete(tagId);
          return ids;
        });
      }
    } else {
      setRemovedTagIds(new Set(removedTagIds).add(tagId));
      if (addedTagIds.has(tagId)) {
        setAddedTagIds((tags) => {
          const ids = new Set(tags);
          ids.delete(tagId);
          return ids;
        });
      }
    }
  };

  const getEffectiveTagsToAdd = (checkedTagIds: Set<TagId>) => {
    return Array.from(checkedTagIds).filter(
      (tagId) => !alreadyCheckedTagIds.has(tagId),
    );
  };

  const getUserAffectedText = (user: MedtechUserVisiblityError) => {
    const documentsAffected = user.documents.length;
    return `${user.full_name} (${documentsAffected} document${
      documentsAffected > 1 ? 's' : ''
    } affected)`;
  };

  const handleManageTags = async (
    documents: QualioDocument[],
    tagsToAdd: Set<TagId>,
    tagsToRemove: Set<TagId>,
    forceApplyTags = false,
  ): Promise<void> => {
    setIsApplyingChanges(true);
    const documentIds = documents.map(({ id }) => id);
    const addedTags = getEffectiveTagsToAdd(tagsToAdd);
    const removedTags = Array.from(tagsToRemove);
    try {
      await documentApi.applyTagChangesToDocuments(
        documentIds,
        addedTags,
        removedTags,
        forceApplyTags,
      );
      onSuccess({ removedTags });
      setWarningData(undefined);
      setIsApplyingChanges(false);
      setIsOpen(false);
      setIsWarningModalOpen(false);
    } catch (error) {
      setIsApplyingChanges(false);
      const parsedError =
        MedtechDocsVisibilityChangedErrorResponse.safeParse(error);

      if (parsedError.success) {
        const { data: visibilityError } = parsedError;
        setWarningData({
          ...WarningLabelMap[visibilityError.slug],
          ...visibilityError.error_data,
          code: visibilityError.slug,
          tagsToAdd: addedTagIds,
          tagsToRemove: removedTagIds,
        });
        setIsOpen(false);
        setIsWarningModalOpen(true);
      } else if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isOpen && (
        <QModal isOpen onClose={onClose}>
          <QModalHeader>
            <QText>Manage tags</QText>
          </QModalHeader>
          <QModalBody maxHeight="450px" style={{ overflowY: 'auto' }}>
            <QText fontSize="md" pb={'24px'}>
              {modalDescription}
            </QText>
            <QStack>
              {tags.map((tag) => (
                <QBox>
                  <QCheckbox
                    data-cy={`tag-${tag.name}`}
                    key={tag.id}
                    isChecked={addedTagIds.has(tag.id)}
                    isIndeterminate={
                      !addedTagIds.has(tag.id) &&
                      !removedTagIds.has(tag.id) &&
                      selectedDocuments.some(({ tag_ids }) =>
                        tag_ids.includes(tag.id),
                      )
                    }
                    onChange={(e) =>
                      handleCheckboxChange(tag.id, e.target.checked)
                    }
                  >
                    <TagLabel tag={tag} />
                  </QCheckbox>
                </QBox>
              ))}
            </QStack>
          </QModalBody>
          <QModalActions>
            <QButton
              data-cy="document-manage-tags-cancel-button"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </QButton>
            <QButton
              data-cy="document-manage-tags-apply-changes-button"
              isLoading={isApplyingChanges}
              onClick={() =>
                handleManageTags(selectedDocuments, addedTagIds, removedTagIds)
              }
              isDisabled={
                !getEffectiveTagsToAdd(addedTagIds).length &&
                !removedTagIds.size
              }
            >
              Apply changes
            </QButton>
          </QModalActions>
        </QModal>
      )}
      {isWarningModalOpen && warningData && (
        <QModal isOpen onClose={onWarningClose}>
          <QModalHeader>
            <QText>Manage tags</QText>
          </QModalHeader>
          <QModalBody>
            <QAlert
              status="warning"
              title="This action affects content visibility"
              description={warningData.warningText}
            />
            <QText fontSize="16px" pt="4">
              {warningData.description}
            </QText>
            <QBox p="2" pl="6" pr="6">
              {warningData.code === 'documents_visibility_changed' && (
                <QStack>
                  {warningData.documents_visibility_changed.map((docId) => (
                    <AffectedDocumentLink
                      key={docId}
                      documents={selectedDocuments}
                      documentId={docId}
                    />
                  ))}
                </QStack>
              )}
              {warningData.code ===
                'authors_lost_docs_and_docs_visibility_changed' && (
                <ul>
                  {warningData.documents_hidden_from_authors?.users.map(
                    (user) => {
                      return (
                        <li key={user.id}>
                          <QText fontSize="16px">
                            {getUserAffectedText(user)}
                          </QText>
                        </li>
                      );
                    },
                  )}
                </ul>
              )}
            </QBox>
          </QModalBody>
          <QModalActions>
            <QButton variant="outline" onClick={onWarningClose}>
              Cancel
            </QButton>
            <QButton
              isLoading={isApplyingChanges}
              onClick={() =>
                handleManageTags(
                  selectedDocuments,
                  warningData.tagsToAdd,
                  warningData.tagsToRemove,
                  true,
                )
              }
            >
              Continue
            </QButton>
          </QModalActions>
        </QModal>
      )}
    </>
  );
};

const AffectedDocumentLink = (props: {
  documents: QualioDocument[];
  documentId: QualioDocument['id'];
}) => {
  const affectedDocument = props.documents.find(
    ({ id }) => id === props.documentId,
  );

  if (!affectedDocument) {
    return null;
  }

  return (
    <QText fontSize="md">
      <QLink
        href={`/workspace/documents/${affectedDocument.id}`}
        isExternal
        tabIndex={-1}
      >
        {affectedDocument.title}
      </QLink>
    </QText>
  );
};
