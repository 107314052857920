import {
  QBox,
  QButton,
  QEmptyState,
  QSelect,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../api/document';
import { ActivityLog } from '../../../api/model/activityLog';
import {
  ACTIVITY_LOG_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { ActivityLogCardWrapper } from './ActivityLogCardWrapper';

export type ActivityType = 'all-activities';

type ActivityTypeSelectOptions = {
  label: ActivityTypeOptions;
  value: ActivityType;
};

type SortDirection = 'ascending' | 'descending';

export enum ActivityTypeOptions {
  ALL_ACTIVITIES = 'All activities',
  STATUS = 'Status',
  VERSION = 'Version',
  MODIFICATION = 'Modifications',
}

interface ActivityHistoryTabProps {
  companyId: number;
  documentId: string;
  isOpen: boolean;
}

export const ActivityHistoryTab = ({
  companyId,
  documentId,
  isOpen,
}: ActivityHistoryTabProps) => {
  const { showToast } = useToastProvider();
  const [sortDirection, setSortDirection] =
    useState<SortDirection>('descending');
  const toggleSortDirection = () => {
    if (sortDirection === 'ascending') {
      setSortDirection('descending');
    } else {
      setSortDirection('ascending');
    }
  };

  const [selectedActivityType, setSelectedActivityType] =
    useState<ActivityType>('all-activities');
  const activityTypeOptions: ActivityTypeSelectOptions[] = [
    {
      label: ActivityTypeOptions.ALL_ACTIVITIES,
      value: 'all-activities',
    },
  ];

  const onActivityTypeChange = (
    selectedActivityType: ActivityTypeSelectOptions | null,
  ) => {
    if (selectedActivityType) {
      setSelectedActivityType(selectedActivityType.value);
    }
  };

  const sortByNewestFirst = (a: ActivityLog, b: ActivityLog) =>
    b.timestamp - a.timestamp;

  const sortByOldestFirst = (a: ActivityLog, b: ActivityLog) =>
    a.timestamp - b.timestamp;

  const loadActivityLogs = async (
    companyId: number,
    documentId: number,
  ): Promise<ActivityLog[]> => {
    const logs = await documentApi.fetchActivityLogs(companyId, documentId);
    return logs.map((activityLog: ActivityLog, idx: number) => {
      activityLog.key = idx;
      return activityLog;
    });
  };

  const { isFetching: isFetchingActivity, data: activityLogs } = useQuery({
    queryKey: ['activityLogs', companyId, documentId, selectedActivityType],
    queryFn: () => loadActivityLogs(companyId, parseInt(documentId)),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-activity-log',
        title: 'Error',
        description: 'Could not retrieve activity logs',
        status: 'error',
      });
      logCustomError(ACTIVITY_LOG_FETCH_DATA_ERROR, { error });
    },
  });

  const sortedActivityLogs = useMemo(() => {
    return activityLogs?.sort(
      sortDirection === 'descending' ? sortByNewestFirst : sortByOldestFirst,
    );
  }, [activityLogs, sortDirection]);

  if (isFetchingActivity) {
    return (
      <QStack height="100%" minHeight="60vh" justify="center" align="center">
        <QSpinner />
        <QText data-testid="loading-document-history">
          Loading activity...
        </QText>
      </QStack>
    );
  }

  if (activityLogs?.length === 0) {
    if (selectedActivityType === 'all-activities') {
      return (
        <QEmptyState
          data-testid="document-history-empty-placeholder"
          title="No activity has been recorded."
          subtitle={
            "This document's activity will be tracked here as changes occur."
          }
        />
      );
    }

    return (
      <>
        <QStack fontSize="sm" pt="4" spacing="4">
          <QStack direction="row" justifyContent="space-between">
            <QBox width={160}>
              <QSelect
                size="sm"
                options={activityTypeOptions}
                onChange={onActivityTypeChange}
                value={selectedActivityType}
                aria-label="Activity type"
                data-cy="activity-type-select"
                data-testid="activity-type-select"
              ></QSelect>
            </QBox>
          </QStack>
          <QStack direction="column">
            <QEmptyState
              data-testid="document-history-empty-filter-placeholder"
              title="No activity of this type recorded."
              subtitle="Try adjusting your filter or check back later as new activities occur."
            />
          </QStack>
        </QStack>
      </>
    );
  }

  return (
    <QStack fontSize="sm" pt="4" spacing="4">
      <QStack direction="row" justifyContent="space-between">
        <QBox width={160}>
          <QSelect
            size="sm"
            options={activityTypeOptions}
            onChange={onActivityTypeChange}
            value={selectedActivityType}
            aria-label="Activity type"
            data-cy="activity-type-select"
            data-testid="activity-type-select"
          ></QSelect>
        </QBox>

        {sortDirection === 'ascending' && (
          <QButton
            rightIcon="ArrowUp"
            onClick={toggleSortDirection}
            variant="ghost"
          >
            Oldest first
          </QButton>
        )}
        {sortDirection === 'descending' && (
          <QButton
            rightIcon="ArrowDown"
            onClick={toggleSortDirection}
            variant="ghost"
          >
            Newest first
          </QButton>
        )}
      </QStack>
      <QStack direction="column">
        {sortedActivityLogs?.map((activityLog, idx) => (
          <ActivityLogCardWrapper
            key={activityLog.key}
            actionType={activityLog.action}
            actor={activityLog.actor}
            timestamp={activityLog.timestamp}
            data={activityLog.data}
          />
        ))}
      </QStack>
    </QStack>
  );
};
