import { useCurrentUser } from '@qualio/ui-components';
import { ReactNode, useEffect } from 'react';

import {
  configureDatadog,
  removeDatadog,
} from '../../../util/Analytics/AnalyticsUtils';

type AnalyticsWrapperProps = {
  children: ReactNode;
};

export const AnalyticsWrapper = ({ children }: AnalyticsWrapperProps) => {
  const currentUser = useCurrentUser();

  useEffect(() => {
    configureDatadog(currentUser);
    return () => {
      removeDatadog();
    };
  }, [currentUser]);

  return <>{children}</>;
};
