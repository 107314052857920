import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  QFilePicker,
  QFilePickerError,
  QFilePickerMode,
  QFilePickerType,
  RemoteFileMetadata,
} from '../QFilePicker';
import { useIntegrationEnabled } from '../QFilePicker/hooks/useIntegrationEnabled';

export interface IntegrationPickerProps {
  handlePick: (files: RemoteFileMetadata[]) => void;
  handlePickError: (errors: QFilePickerError[]) => void;
  mode: QFilePickerMode;
}

export const IntegrationPicker = ({
  handlePick,
  handlePickError,
  mode,
}: IntegrationPickerProps) => {
  const { integrationsOnedrive } = useFlags();
  const {
    data: isIntegrationEnabled,
    isLoading,
    isError,
  } = useIntegrationEnabled('onedrive');

  if (!integrationsOnedrive || !isIntegrationEnabled || isLoading || isError) {
    return null;
  }

  return (
    <QFilePicker
      mode={mode}
      onPick={handlePick}
      onError={handlePickError}
      types={[
        QFilePickerType.DOCUMENT,
        QFilePickerType.IMAGE,
        QFilePickerType.PRESENTATION,
      ]}
      integrationBaseUrl={process.env.REACT_APP_INTEGRATION_API_URL as string}
    />
  );
};
