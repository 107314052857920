import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import {
  QBodyLayout,
  QBox,
  QEmptyState,
  QFlex,
  QHeading,
  QInput,
  QMultiSelect,
  QSelect,
  QSelectItem,
  QSelectPlaceholder,
  QSpinner,
  QStack,
  QTab,
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
  QText,
  QualioQThemeProvider,
  ToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { backendTaskApi } from '../../../api/backendTask';
import { documentApi } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { TagApi } from '../../../api/tag';

import { useDebounce } from '../../../hooks/Debounce';

import { DocumentContainer } from '../DocumentContainer/index';
import {
  buildSortingRules,
  buildSortingValue,
  buildSortSearchParams,
  DocumentList,
  SortCallbackObj,
} from '../DocumentList/index';
import { ExportDocumentListButton } from '../Export/ExportDocumentListButton/ExportDocumentListButton';
import { TagLabel } from '../TagLabel/TagLabel';
import { BulkActions } from './BulkActions/BulkActions';
import { NewDocumentButton } from './NewDocumentButton';
import {
  ALL_OPEN_STATUSES,
  DEFAULT_PAGE_SIZE,
  FOR_TRAINING_STATUS,
  WorkspaceTabProps,
  WorkspaceTabs,
} from './types';

export const documentAllOpenStatuses = [
  DocumentStatus.Draft,
  DocumentStatus.For_Review,
  DocumentStatus.For_Approval,
  DocumentStatus.Approved,
  DocumentStatus.Approval_Declined,
  DocumentStatus.Effective,
];

export const actionableDocumentAllOpenStatusFilters = [
  'include_pending_review',
  'include_pending_approve',
  'include_pending_make_effective',
];

const getStatusFiltersDocumentsMap = (
  tab: WorkspaceTabs,
  permissions: CurrentUser['permissions'],
) => {
  return {
    // statuses other than approved and effective are only avaiable options if the user can view all docs or if the currently selected tab is "your documents"
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [ALL_OPEN_STATUSES]: {
        label: 'All open statuses',
        statusFilters: documentAllOpenStatuses,
      },
      [DocumentStatus.Draft]: {
        label: 'Draft',
        statusFilters: [DocumentStatus.Draft],
      },
      [DocumentStatus.For_Review]: {
        label: 'For review',
        statusFilters: [DocumentStatus.For_Review],
      },
      [DocumentStatus.For_Approval]: {
        label: 'For approval',
        statusFilters: [DocumentStatus.For_Approval],
      },
    }),
    [DocumentStatus.Approved]: {
      label: 'Approved',
      statusFilters: [DocumentStatus.Approved],
    },
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [DocumentStatus.Approval_Declined]: {
        label: 'Approval declined',
        statusFilters: [DocumentStatus.Approval_Declined],
      },
    }),
    [DocumentStatus.Effective]: {
      label: 'Effective',
      statusFilters: [DocumentStatus.Effective],
    },
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [DocumentStatus.For_Periodic_Review]: {
        label: 'For periodic review',
        statusFilters: [DocumentStatus.For_Periodic_Review],
      },
      [DocumentStatus.Retired]: {
        label: 'Retired',
        statusFilters: [DocumentStatus.Retired],
      },
      [DocumentStatus.Deleted]: {
        label: 'Deleted drafts',
        statusFilters: [DocumentStatus.Deleted],
      },
    }),
  };
};

const statusFiltersActionsMap = {
  [ALL_OPEN_STATUSES]: {
    label: 'All open statuses',
    statusFilters: actionableDocumentAllOpenStatusFilters,
  },
  [FOR_TRAINING_STATUS]: {
    label: 'For training',
    statusFilters: null,
  },
  [DocumentStatus.For_Review]: {
    label: 'For review',
    statusFilters: ['include_pending_review'],
  },
  [DocumentStatus.For_Approval]: {
    label: 'For approval',
    statusFilters: ['include_pending_approve'],
  },
  [DocumentStatus.Approved]: {
    label: 'Approved',
    statusFilters: ['include_pending_make_effective'],
  },
  [DocumentStatus.For_Periodic_Review]: {
    label: 'For periodic review',
    statusFilters: ['include_pending_periodic_review'],
  },
};

export const DocumentWorkspace: React.FC<{ currentUser: CurrentUser }> = ({
  currentUser,
}) => {
  const WorkspaceTabsConfig: { [key in WorkspaceTabs]: WorkspaceTabProps } =
    useMemo(
      () => ({
        [WorkspaceTabs.YOUR_DOCUMENTS]: {
          id: WorkspaceTabs.YOUR_DOCUMENTS,
          defaultFetchMethod: documentApi.fetchUserDocs,
          statusFiltersMap: getStatusFiltersDocumentsMap(
            WorkspaceTabs.YOUR_DOCUMENTS,
            currentUser.permissions,
          ),
          defaultExportTaskMethod: backendTaskApi.startUserDocumentsExportTask,
        },
        [WorkspaceTabs.YOUR_ACTIONS]: {
          id: WorkspaceTabs.YOUR_ACTIONS,
          defaultFetchMethod: documentApi.fetchUserActionableDocs,
          statusFiltersMap: statusFiltersActionsMap,
          defaultExportTaskMethod:
            backendTaskApi.startUserActionableDocumentsExportTask,
        },
        [WorkspaceTabs.ALL_DOCUMENTS]: {
          id: WorkspaceTabs.ALL_DOCUMENTS,
          defaultFetchMethod: documentApi.fetchAll,
          statusFiltersMap: getStatusFiltersDocumentsMap(
            WorkspaceTabs.ALL_DOCUMENTS,
            currentUser.permissions,
          ),
          defaultExportTaskMethod: backendTaskApi.startAllDocumentsExportTask,
        },
      }),
      [currentUser.permissions],
    );
  const [searchParams, setSearchParams] = useSearchParams();
  const requestedTagFilter = searchParams.get('filteringTag');
  const requestedSearchFilter = searchParams.get('q');
  const requestedPageNumber = searchParams.get('page');
  const requestedNumDocsPerPagePage = searchParams.get('count');
  const orderByParam = searchParams.get('order_by');
  const requestedStatus = searchParams.get('subfilter');
  const requestedTab = searchParams.get('filter');
  const [searchQuery, setSearchQuery] = useState<string | null>(
    requestedSearchFilter ?? null,
  );
  const [sortingRules, setSortingRules] = useState<SortCallbackObj[]>(
    buildSortingRules(orderByParam) as SortCallbackObj[],
  );
  const [currentPage, setCurrentPage] = useState<number>(
    requestedPageNumber ? Number(requestedPageNumber) - 1 : 0,
  );

  const [currentPageSize, setCurrentPageSize] = useState<number>(
    requestedNumDocsPerPagePage
      ? Number(requestedNumDocsPerPagePage)
      : DEFAULT_PAGE_SIZE,
  );
  const debouncedSearchQuery = useDebounce<string | null>(searchQuery);
  const paginationOffset = currentPageSize * currentPage;

  const [tagsFilter, setTagsFilter] = useState<string[]>(
    requestedTagFilter ? requestedTagFilter.split(',') : [],
  );

  const [currentTabConfig, setCurrentTabConfig] = useState<WorkspaceTabProps>(
    requestedTab
      ? WorkspaceTabsConfig[requestedTab as WorkspaceTabs]
      : WorkspaceTabsConfig[WorkspaceTabs.YOUR_DOCUMENTS],
  );

  const tabConfigKeysInDisplayedOrder = [
    WorkspaceTabs.YOUR_DOCUMENTS,
    WorkspaceTabs.YOUR_ACTIONS,
    WorkspaceTabs.ALL_DOCUMENTS,
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    tabConfigKeysInDisplayedOrder.indexOf(currentTabConfig.id),
  );

  const [selectedDocumentsMap, setSelectedDocumentsMap] = useState<
    Map<QualioDocument['id'], QualioDocument>
  >(new Map());

  const getDefaultStatus = (tab: WorkspaceTabs) => {
    return !currentUser.permissions.can_view_all_docs &&
      tab === WorkspaceTabs.ALL_DOCUMENTS
      ? DocumentStatus.Effective
      : ALL_OPEN_STATUSES;
  };

  const [selectedStatus, setSelectedStatus] = useState<string>(
    requestedStatus ?? getDefaultStatus(requestedTab as WorkspaceTabs),
  );

  const resetAllSearchParams = () => {
    searchParams.delete('filteringTag');
    searchParams.delete('q');
    searchParams.delete('page');
    searchParams.delete('count');
    searchParams.delete('subfilter');
    searchParams.delete('order_by');
  };

  const sortCallback = (sortingRules: SortCallbackObj[]) => {
    if (!sortingRules) {
      return;
    }
    const newSearchParams = buildSortSearchParams(sortingRules, searchParams);
    setSearchParams(newSearchParams);
    setSortingRules(sortingRules);
  };

  useEffect(() => {
    setSelectedDocumentsMap(new Map());
  }, [currentTabConfig.id, searchQuery, tagsFilter, selectedStatus]);

  const { statusFilters } = currentTabConfig.statusFiltersMap[selectedStatus];

  const sortingValue = useMemo(() => {
    return sortingRules.length > 0 ? buildSortingValue(sortingRules) : null;
  }, [sortingRules]);

  const exportTaskMethod =
    selectedStatus === FOR_TRAINING_STATUS
      ? backendTaskApi.startUserTrainingDocumentsExportTask
      : currentTabConfig.defaultExportTaskMethod;

  const loadDocuments = useCallback(async () => {
    const fetchMethod =
      selectedStatus === FOR_TRAINING_STATUS
        ? documentApi.fetchUserTrainingDocs
        : currentTabConfig.defaultFetchMethod;

    try {
      return await fetchMethod(
        currentUser,
        statusFilters,
        searchQuery,
        tagsFilter,
        paginationOffset,
        currentPageSize,
        sortingValue,
      );
    } catch (e) {
      return;
    }
  }, [
    currentPageSize,
    currentTabConfig,
    currentUser,
    paginationOffset,
    searchQuery,
    tagsFilter,
    selectedStatus,
    statusFilters,
    sortingValue,
  ]);

  const loadTags = async (): Promise<Tag[]> => TagApi.fetchTags(currentUser);
  const {
    error: errorDocuments,
    isLoading: isLoadingDocuments,
    data: filteredDocs = { documents: [], total: 0 },
    refetch,
  } = useQuery({
    queryFn: loadDocuments,
    queryKey: [
      'documents',
      debouncedSearchQuery,
      tagsFilter,
      paginationOffset,
      currentPageSize,
      currentTabConfig.id,
      selectedStatus,
      sortingRules,
    ],
    refetchOnWindowFocus: false,
  });

  /**
   * Handles issue where selected documents in state are stale because the table is updated
   * with new document data
   */
  useEffect(() => {
    setSelectedDocumentsMap((currentlySelectedDocs) => {
      if (!!currentlySelectedDocs.size && filteredDocs.documents.length) {
        const selectedDocsMapCopy = new Map(currentlySelectedDocs);
        currentlySelectedDocs.forEach((_, id) => {
          const updatedDoc = filteredDocs.documents.find(
            (doc) => doc.id === id,
          );
          if (updatedDoc) {
            selectedDocsMapCopy.set(id, updatedDoc);
          }
        });
        return selectedDocsMapCopy;
      }
      return currentlySelectedDocs;
    });
  }, [filteredDocs.documents]);

  const deletedDraftStatusSelected = useMemo(() => {
    return selectedStatus === DocumentStatus.Deleted;
  }, [selectedStatus]);

  const {
    error: errorTags,
    isLoading: isLoadingTags,
    data: fetchedTags = [],
  } = useQuery({
    queryFn: loadTags,
    queryKey: ['tags', currentUser.companyId],
    refetchOnWindowFocus: false,
  });

  const tagItems: QSelectItem<JSX.Element, string>[] = useMemo(
    () =>
      fetchedTags.map((tag) => ({
        label: <TagLabel tag={tag} />,
        value: String(tag.id),
      })),
    [fetchedTags],
  );

  const tagsMap = useMemo(
    () =>
      fetchedTags.reduce((acc: Map<Tag['id'], Tag>, tag: Tag) => {
        acc.set(tag.id, tag);
        return acc;
      }, new Map()),
    [fetchedTags],
  );

  const onStatusChange = (status: QSelectItem | null) => {
    if (status) {
      setSelectedStatus(status.value);
      searchParams.set('subfilter', status.value);
      setSearchParams(searchParams);
      setCurrentPage(0);
    }
  };

  const onTagFilterChange = (
    tagItems: readonly QSelectItem<JSX.Element, string>[],
  ) => {
    const tagIds = tagItems.map((tagItem) => tagItem.value);
    setTagsFilter(tagIds);
    searchParams.set('filteringTag', tagIds.join(','));
    setSearchParams(searchParams);
  };

  const paginationCallback = (pageIndex: number, pageSize: number) => {
    searchParams.set('count', pageSize.toString());
    searchParams.set('page', (pageIndex + 1).toString());
    setSearchParams(searchParams);
    setCurrentPage(pageIndex);
    setCurrentPageSize(pageSize);
  };

  const manualPagination = {
    paginationCallback,
    pageCount: filteredDocs
      ? Math.ceil((filteredDocs.total || 1) / currentPageSize)
      : -1,
    initialPageSize: currentPageSize,
    initialPageIndex: currentPage,
  };

  if (errorDocuments || errorTags) {
    console.log('Error', { errorDocuments, errorTags });
  }

  const handleTabClick = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    resetAllSearchParams();
    const tabConfig =
      WorkspaceTabsConfig[tabConfigKeysInDisplayedOrder[tabIndex]];
    setCurrentTabConfig(tabConfig);
    setSearchQuery(null);
    setTagsFilter([]);
    setSelectedStatus(getDefaultStatus(tabConfig.id));
    setCurrentPage(0);
    setSortingRules([]);
    searchParams.set('filter', tabConfig.id);
    setSearchParams(searchParams);
  };

  const onSearchFilterChange = (evt: any) => {
    searchParams.set('q', evt.target.value);
    setSearchParams(searchParams);
    setSearchQuery(evt.target.value);
  };

  const showNoResults = useMemo(
    () => filteredDocs.documents.length === 0 && !isLoadingDocuments,
    [filteredDocs.documents, isLoadingDocuments],
  );
  const showTable = useMemo(
    () => filteredDocs.documents.length > 0 && !isLoadingDocuments,
    [filteredDocs.documents, isLoadingDocuments],
  );

  return (
    <DocumentContainer>
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <QualioQThemeProvider>
          <ToastProvider>
            <QBodyLayout.Default>
              <QStack isInline justify="space-between">
                <QHeading size="lg" mb={6}>
                  Workspace
                </QHeading>
                <QBox>
                  <QStack isInline zIndex="3" position="relative">
                    <ExportDocumentListButton
                      currentUser={currentUser}
                      tagsFilter={tagsFilter}
                      isDisabled={filteredDocs?.total === 0}
                      searchQuery={searchQuery}
                      exportTaskMethod={exportTaskMethod}
                      statusFilters={statusFilters}
                      sortingValue={sortingValue}
                    ></ExportDocumentListButton>
                    <NewDocumentButton currentUser={currentUser} />
                  </QStack>
                </QBox>
              </QStack>
              <QTabs
                variant="enclosed"
                onChange={handleTabClick}
                index={currentTabIndex}
              >
                <QTabList>
                  <QTab data-cy="your-docs-tab">Your documents</QTab>
                  <QTab data-cy="your-actions-tab">Your actions</QTab>
                  <QTab data-cy="all-docs-tab">All documents</QTab>
                </QTabList>
                <QStack isInline pt="4" pb="2">
                  <QBox w="300px">
                    <QSelect
                      size="sm"
                      options={Object.keys(
                        currentTabConfig.statusFiltersMap,
                      ).map((key) => {
                        return {
                          label: currentTabConfig.statusFiltersMap[key].label,
                          value: key,
                        };
                      })}
                      onChange={onStatusChange}
                      value={selectedStatus}
                      aria-label="Document status"
                      data-cy={'workspace-status-filter'}
                      data-testid={'workspace-status-filter'}
                    ></QSelect>
                  </QBox>
                  <QBox w="300px">
                    <QMultiSelect
                      isLoading={isLoadingTags}
                      size="sm"
                      options={tagItems}
                      onChange={onTagFilterChange}
                      data-cy={'workspace-tags'}
                      value={tagsFilter}
                      filterOption={(tagItem, query) => {
                        const tag = tagsMap.get(parseInt(tagItem.value));
                        return Boolean(
                          tag?.name.toLowerCase().includes(query.toLowerCase()),
                        );
                      }}
                    >
                      <QSelectPlaceholder>
                        <QText>Filter by tags</QText>
                      </QSelectPlaceholder>
                    </QMultiSelect>
                  </QBox>
                  {!deletedDraftStatusSelected && (
                    <QInput
                      iconLeftName="Search"
                      onChange={onSearchFilterChange}
                      isClearable
                      placeholder={'Search...'}
                      data-cy={'workspace-search'}
                      data-testid={'workspace-search'}
                      value={searchQuery ?? ''}
                    ></QInput>
                  )}
                </QStack>
                {deletedDraftStatusSelected && (
                  <QStack isInline minHeight="32px" alignItems={'center'}>
                    <QText fontSize="sm">
                      Deleted items will remain in this view for 90 days
                    </QText>
                  </QStack>
                )}
                {!deletedDraftStatusSelected && (
                  <BulkActions
                    currentUser={currentUser}
                    selectedDocumentsMap={selectedDocumentsMap}
                    currentTab={currentTabConfig.id}
                    refetch={refetch}
                    setSelectedDocumentsMap={setSelectedDocumentsMap}
                    tags={fetchedTags}
                  />
                )}
                {showNoResults && (
                  <QEmptyState
                    title="No matching documents found!"
                    subtitle="Your search or filter produced no results. Please adjust and try again."
                  />
                )}
                {isLoadingDocuments && (
                  <QFlex
                    data-testid={'workspace-loading-spinner'}
                    justify="center"
                    m={10}
                  >
                    <QSpinner />
                  </QFlex>
                )}
                <QTabPanels>
                  <QTabPanel p="0">
                    {showTable &&
                      currentTabConfig.id === WorkspaceTabs.YOUR_DOCUMENTS && (
                        <DocumentList
                          filteredDocs={filteredDocs}
                          fetchedTags={fetchedTags}
                          manualPagination={manualPagination}
                          selectedDocumentsMap={selectedDocumentsMap}
                          setSelectedDocumentsMap={setSelectedDocumentsMap}
                          hideCheckboxColumn={deletedDraftStatusSelected}
                          sortCallback={sortCallback}
                          sortingRules={sortingRules}
                        ></DocumentList>
                      )}
                  </QTabPanel>
                  <QTabPanel p="0">
                    {showTable &&
                      currentTabConfig.id === WorkspaceTabs.YOUR_ACTIONS && (
                        <DocumentList
                          filteredDocs={filteredDocs}
                          fetchedTags={fetchedTags}
                          manualPagination={manualPagination}
                          selectedDocumentsMap={selectedDocumentsMap}
                          setSelectedDocumentsMap={setSelectedDocumentsMap}
                          sortCallback={sortCallback}
                          sortingRules={sortingRules}
                        ></DocumentList>
                      )}
                  </QTabPanel>
                  <QTabPanel p="0">
                    {showTable &&
                      currentTabConfig.id === WorkspaceTabs.ALL_DOCUMENTS && (
                        <DocumentList
                          filteredDocs={filteredDocs}
                          fetchedTags={fetchedTags}
                          manualPagination={manualPagination}
                          selectedDocumentsMap={selectedDocumentsMap}
                          setSelectedDocumentsMap={setSelectedDocumentsMap}
                          hideCheckboxColumn={deletedDraftStatusSelected}
                          sortCallback={sortCallback}
                          sortingRules={sortingRules}
                        ></DocumentList>
                      )}
                  </QTabPanel>
                </QTabPanels>
              </QTabs>
            </QBodyLayout.Default>
          </ToastProvider>
        </QualioQThemeProvider>
      </div>
    </DocumentContainer>
  );
};
