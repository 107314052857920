import { CurrentUser } from '@qualio/ui-components';
import {
  DocumentCategory,
  DocumentFormat,
  DocumentStatus,
  DocumentVersion,
  NextVersionDetails,
  QualioDocument,
} from '../../../api/model/document';
import {
  hasApprovedPeriodicReview,
  hasPermissions,
  isApprover,
  isApproverWithPendingApproval,
  isNormalUserInAccount,
  isOwner,
  isQualityUserInAccount,
  isReviewer,
} from '../../../util/CurrentUser';

import { Location } from 'react-router';
import { ChangeRequestItem } from '../../../api/model/changeRequestItem';
import { PeriodicReview } from '../../../api/model/periodicReview';
import { EditorMode } from '../../../util/EditModeUtils';

export const shouldShowFroalaViewer = (qualioDocument: QualioDocument) => {
  const isCKEDocument = qualioDocument.editor_version?.startsWith('ckeditor-v');
  return !isCKEDocument;
};

export const shouldShowFileUpload = (qualioDocument: QualioDocument) => {
  return qualioDocument.document_format === DocumentFormat.DocumentUpload;
};

export const isDraftOwner = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isOwner(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.Draft
  );
};

export const isForReviewOwner = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isOwner(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.For_Review
  );
};

export const isForApprovalOwner = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isOwner(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.For_Approval
  );
};

export const isApprovalDeclinedOwner = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isOwner(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.Approval_Declined
  );
};

export const isForReviewReviewer = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isReviewer(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.For_Review
  );
};

export const isForReviewQualityUser = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isQualityUserInAccount(currentUser) &&
    qualioDocument.status_id === DocumentStatus.For_Review
  );
};

export const isIssueRelated = (qualioDocument: QualioDocument) => {
  return qualioDocument.category === DocumentCategory.ISSUE_RELATED;
};

export const getDocumentLatestMajorVersion = (
  versions: DocumentVersion[],
): DocumentVersion | undefined => {
  return versions
    .filter(({ version }) => version.endsWith('.0'))
    .sort((a, b) => parseInt(a.major) - parseInt(b.major))
    .pop();
};

export const isDocumentLatestMajorVersion = (
  qualioDocument: QualioDocument,
  versions: DocumentVersion[],
) => {
  const latestMajorVersion = getDocumentLatestMajorVersion(versions);
  return qualioDocument.id === latestMajorVersion?.document_id;
};

export const isDocumentIssueRelated = (qualioDocument: QualioDocument) =>
  qualioDocument.category === DocumentCategory.ISSUE_RELATED;

export const sortVersions = (versions: DocumentVersion[]) => {
  return [...versions].sort((a, b) => {
    if (parseInt(a.major) === parseInt(b.major)) {
      return parseInt(a.minor) - parseInt(b.minor);
    }
    return parseInt(a.major) - parseInt(b.major);
  });
};

export const getVersionsToDisplayOrCompare = (
  selectedVersion: string | null | undefined,
  latestMajorVersion: string,
  versions: DocumentVersion[],
) => {
  return versions.filter((ver) => {
    const isFirstMinorOfMajorVersion = ver.minor === '0';

    if (selectedVersion) {
      return (
        (isFirstMinorOfMajorVersion || ver.major === latestMajorVersion) &&
        selectedVersion !== ver.version
      );
    }
    return isFirstMinorOfMajorVersion || ver.major === latestMajorVersion;
  });
};

export const getMostRecentDocumentVersion = (
  versions: DocumentVersion[],
): DocumentVersion | undefined => {
  const mostRecentVersion = sortVersions(versions).pop();
  return mostRecentVersion;
};

export const isMostRecentDocumentVersion = (
  qualioDocument: QualioDocument,
  versions: DocumentVersion[],
) => {
  const mostRecentVersion = getMostRecentDocumentVersion(versions);
  const currentDocumentVersion = `${qualioDocument.major_version}.${qualioDocument.minor_version}`;
  return currentDocumentVersion === mostRecentVersion?.version;
};

export const canUserEditDocument = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return isDraftOwner(qualioDocument, currentUser);
};

export const shouldRenderEditButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    canUserEditDocument(qualioDocument, currentUser) &&
    currentEditorMode === EditorMode.VIEW
  );
};

export const canUserSuggestOnDocument = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isDraftOwner(qualioDocument, currentUser) ||
    isForReviewOwner(qualioDocument, currentUser) ||
    isForReviewReviewer(qualioDocument, currentUser) ||
    isForReviewQualityUser(qualioDocument, currentUser)
  );
};

export const shouldRenderSuggestButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode === EditorMode.VIEW &&
    canUserSuggestOnDocument(qualioDocument, currentUser)
  );
};

export const shouldRenderEditSuggestToggle = (
  qualioDocument: QualioDocument,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode !== EditorMode.VIEW &&
    qualioDocument.status_id === DocumentStatus.Draft
  );
};

export const shouldRenderMakeEffectiveButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    hasPermissions(currentUser, ['can_make_doc_effective']) &&
    qualioDocument.status_id === DocumentStatus.Approved
  );
};

export const shouldShowApprovalControls = (
  currentUser: CurrentUser,
  qualioDocument: QualioDocument,
  periodicReview: PeriodicReview | undefined,
  versions: DocumentVersion[] | undefined,
) => {
  const isForApproval =
    qualioDocument.status_id === DocumentStatus.For_Approval;
  const isEffective = qualioDocument.status_id === DocumentStatus.Effective;

  if (isForApproval && versions) {
    return (
      isApproverWithPendingApproval(currentUser, qualioDocument) &&
      isMostRecentDocumentVersion(qualioDocument, versions)
    );
  }

  if (isEffective && !!periodicReview) {
    return (
      !periodicReview.round_stopped &&
      periodicReview.approvers
        .map(({ id }) => id)
        .includes(currentUser.userId) &&
      !hasApprovedPeriodicReview(currentUser, periodicReview)
    );
  }

  return false;
};

export const shouldRenderDeleteButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const deleteAllowedStatuses = [
    DocumentStatus.Draft,
    DocumentStatus.For_Approval,
    DocumentStatus.For_Review,
    DocumentStatus.Approval_Declined,
  ];

  return (
    isOwner(currentUser, qualioDocument) &&
    deleteAllowedStatuses.includes(qualioDocument.status_id as DocumentStatus)
  );
};

export const shouldRenderCreateNewDraftButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const newDraftAllowedStatuses = [
    DocumentStatus.Approved,
    DocumentStatus.Effective,
    DocumentStatus.Retired,
  ];
  return (
    currentUser.permissions.can_author_doc &&
    newDraftAllowedStatuses.includes(qualioDocument.status_id as DocumentStatus)
  );
};

export const shouldRenderSendForReviewButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode === EditorMode.VIEW &&
    isDraftOwner(qualioDocument, currentUser)
  );
};

export const shouldRenderSendForApprovalButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode === EditorMode.VIEW &&
    (isDraftOwner(qualioDocument, currentUser) ||
      isForReviewOwner(qualioDocument, currentUser))
  );
};

export const shouldRenderRevertToDraftButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode === EditorMode.VIEW &&
    (isForReviewOwner(qualioDocument, currentUser) ||
      isForApprovalOwner(qualioDocument, currentUser) ||
      isApprovalDeclinedOwner(qualioDocument, currentUser))
  );
};

export const shouldRenderHistory = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isQualityUserInAccount(currentUser) ||
    (isNormalUserInAccount(currentUser) &&
      (isOwner(currentUser, qualioDocument) ||
        isReviewer(currentUser, qualioDocument) ||
        isApprover(currentUser, qualioDocument)))
  );
};

export const shouldRenderMarkAsReviewedButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return isForReviewReviewer(qualioDocument, currentUser);
};

export const isApprovedOrEffective = (statusId: string) => {
  return [
    DocumentStatus.Approved.valueOf(),
    DocumentStatus.Effective.valueOf(),
  ].includes(statusId);
};

export const shouldShowManageTraineesButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  versions: DocumentVersion[] = [],
) => {
  const { status_id: statusId, training_available: isTrainingAvailable } =
    qualioDocument;
  const docIsApprovedOrEffective = isApprovedOrEffective(statusId);
  const canManageTrainees = 'can_manage_trainees' in currentUser.permissions;
  const isIssueRelated = isDocumentIssueRelated(qualioDocument);
  const isDocOwner = isOwner(currentUser, qualioDocument);
  const isNormalUser = isNormalUserInAccount(currentUser);
  const isLatestMajorVersion = isDocumentLatestMajorVersion(
    qualioDocument,
    versions,
  );

  const canManageOnMajorVersion =
    (canManageTrainees || (isDocOwner && isNormalUser)) &&
    docIsApprovedOrEffective &&
    isTrainingAvailable &&
    isLatestMajorVersion &&
    !isIssueRelated;

  const canManageOnDraft =
    isDocOwner &&
    isTrainingAvailable &&
    statusId === DocumentStatus.Draft.valueOf();

  return canManageOnDraft || canManageOnMajorVersion;
};

export const shouldRenderUndoDeleteButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    qualioDocument.status_id === DocumentStatus.Deleted &&
    (isQualityUserInAccount(currentUser) ||
      isOwner(currentUser, qualioDocument))
  );
};

export const shouldRenderRetireButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    qualioDocument.status_id === 'effective' &&
    !qualioDocument.issue_id &&
    currentUser.permissions.can_retire_doc
  );
};

export const shouldRenderRelatedIssues = (qualioDocument: QualioDocument) => {
  return isIssueRelated(qualioDocument) && qualioDocument.issue_id; // issue_id check is needed for deleted drafts
};

export const shouldShowManageTagsButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) =>
  (isOwner(currentUser, qualioDocument) ||
    isQualityUserInAccount(currentUser)) &&
  !isIssueRelated(qualioDocument);

export const shouldRenderExportButton = (location: Location) => {
  return !location.pathname.includes('/compare/');
};

export const shouldShowEditPropertiesButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    isDraftOwner(qualioDocument, currentUser) && !isIssueRelated(qualioDocument)
  );
};

export const shouldRenderTrainingTab = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  if (isIssueRelated(qualioDocument) || !qualioDocument.training_available) {
    return false;
  }

  return (
    isOwner(currentUser, qualioDocument) ||
    (isReviewer(currentUser, qualioDocument) &&
      qualioDocument.status_id === DocumentStatus.For_Review) ||
    (isApprover(currentUser, qualioDocument) &&
      qualioDocument.status_id === DocumentStatus.For_Approval)
  );
};

export const shouldRenderCCTab = (qualioDocument: QualioDocument) => {
  return !isIssueRelated(qualioDocument);
};

export const shouldShowChangeOwnerButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const hasChangeOwnerPermission = hasPermissions(currentUser, [
    'can_change_doc_owner',
  ]);
  const doesDocStatusAllowChangeOwner = [
    DocumentStatus.Draft.valueOf(),
    DocumentStatus.For_Review.valueOf(),
    DocumentStatus.For_Approval.valueOf(),
    DocumentStatus.Approved.valueOf(),
    DocumentStatus.Effective.valueOf(),
    DocumentStatus.For_Periodic_Review.valueOf(),
    DocumentStatus.Approval_Declined.valueOf(),
  ].includes(qualioDocument.status_id);
  return hasChangeOwnerPermission && doesDocStatusAllowChangeOwner;
};

export const shouldRenderResetEditSessionButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const doesDocStatusAllowResetSession = [
    DocumentStatus.Draft.valueOf(),
    DocumentStatus.For_Review.valueOf(),
    DocumentStatus.For_Approval.valueOf(),
  ].includes(qualioDocument.status_id);
  const isStaff = hasPermissions(currentUser, ['can_manage_app']);
  return (
    doesDocStatusAllowResetSession &&
    isStaff &&
    qualioDocument.document_format === DocumentFormat.QualioNativeDocument
  );
};

export const shouldRenderChangeFormatButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) =>
  isDraftOwner(qualioDocument, currentUser) && !isIssueRelated(qualioDocument);

export const shouldRenderApplyTemplateChangesButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => isDraftOwner(qualioDocument, currentUser);

export const shouldRenderManageApproversButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const isAllowedForStatusAsOwner = [
    DocumentStatus.For_Approval.valueOf(),
    DocumentStatus.Draft.valueOf(),
    DocumentStatus.For_Review.valueOf(),
  ].includes(qualioDocument.status_id);

  const isAllowedForStatusAsQuality = [
    DocumentStatus.For_Approval.valueOf(),
    DocumentStatus.For_Review.valueOf(),
  ].includes(qualioDocument.status_id);
  const isDocumentOwner = isOwner(currentUser, qualioDocument);
  const isQuality = isQualityUserInAccount(currentUser);

  return (
    (isAllowedForStatusAsOwner && isDocumentOwner) ||
    (isAllowedForStatusAsQuality && isQuality)
  );
};

const getDifferenceInVersions = (
  versionA: DocumentVersion,
  versionB: DocumentVersion,
) => {
  if (parseInt(versionA.major) === parseInt(versionB.major)) {
    return parseInt(versionA.minor) - parseInt(versionB.minor);
  }
  return parseInt(versionA.major) - parseInt(versionB.major);
};

export const versionIsGreaterThan = (
  versionA: DocumentVersion,
  versionB: DocumentVersion,
) => getDifferenceInVersions(versionA, versionB) > 0;

export const shouldRenderManageReviewersButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return (
    qualioDocument.status_id === DocumentStatus.Draft &&
    isOwner(currentUser, qualioDocument)
  );
};

export const shouldRenderChangeRequest = (
  currentUser: CurrentUser,
  changeRequest: ChangeRequestItem | undefined,
  hasFlag: boolean,
) => {
  return (
    (isQualityUserInAccount(currentUser) ||
      isNormalUserInAccount(currentUser)) &&
    changeRequest &&
    (changeRequest.status === 'in_progress' || hasFlag)
  );
};

export const shouldRenderPeriodicReviewButton = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
  nextVersion?: NextVersionDetails,
) => {
  return (
    !isIssueRelated(qualioDocument) &&
    isOwner(currentUser, qualioDocument) &&
    qualioDocument.status_id === DocumentStatus.Effective &&
    currentEditorMode === EditorMode.VIEW &&
    !nextVersion
  );
};

export const getTrainee = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  return qualioDocument.trainees.find((trainee) => {
    return trainee.id === currentUser.userId;
  });
};

export const shouldRenderCompareAgainst = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  if (qualioDocument.document_format === DocumentFormat.DocumentUpload) {
    return false;
  }

  if (
    isQualityUserInAccount(currentUser) ||
    isNormalUserInAccount(currentUser)
  ) {
    return true;
  }

  if (shouldCompleteTraining(qualioDocument, currentUser)) {
    return true;
  }
};

export const shouldCompleteTraining = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
) => {
  const trainee = getTrainee(qualioDocument, currentUser);

  if (!trainee) {
    return false;
  }

  return !trainee.action_done && !trainee.training_removed;
};

export const shouldRenderCompleteTraining = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  return (
    currentEditorMode === EditorMode.VIEW &&
    (qualioDocument.status_id === DocumentStatus.Effective ||
      qualioDocument.status_id === DocumentStatus.Approved) &&
    shouldCompleteTraining(qualioDocument, currentUser)
  );
};

export const documentIsInApprovalStatus = (documentStatus: string) => {
  return (
    [DocumentStatus.For_Approval, DocumentStatus.Approval_Declined] as string[]
  ).includes(documentStatus);
};

export const documentIsInTrainingStatus = (
  documentStatus: string,
  trainingAvailable: boolean,
) => {
  return (
    (
      [
        DocumentStatus.Approved,
        DocumentStatus.Effective,
        DocumentStatus.Retired,
        DocumentStatus.Deleted,
      ] as string[]
    ).includes(documentStatus) && trainingAvailable
  );
};

export const shouldRenderActivityReportItem = (
  qualioDocument: QualioDocument,
  currentUser: CurrentUser,
  currentEditorMode: EditorMode,
) => {
  if (currentEditorMode !== EditorMode.VIEW) {
    return false;
  }

  const isDocumentOwner = isOwner(currentUser, qualioDocument);
  const canViewReport = hasPermissions(currentUser, ['can_view_report']);

  const canViewTrainingReport =
    canViewReport &&
    documentIsInTrainingStatus(
      qualioDocument.status_id,
      qualioDocument.training_available,
    );

  const canViewApprovalReport =
    (isDocumentOwner || canViewReport) &&
    documentIsInApprovalStatus(qualioDocument.status_id);

  const canViewReviewReport =
    (isDocumentOwner || canViewReport) &&
    qualioDocument.status_id === DocumentStatus.For_Review;

  return canViewTrainingReport || canViewApprovalReport || canViewReviewReport;
};
