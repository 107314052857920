import { useToastProvider } from '@qualio/ui-components';
import { useState } from 'react';
import { documentApi } from '../../../../../../api/document';
import {
  DocumentStatus,
  QualioDocument,
} from '../../../../../../api/model/document';
import { redirectTo } from '../../../../../../util/PageUtils';
import { WorkspaceTabs } from '../../../types';
import {
  hasAllowedStatusesForAction,
  hasRequiredPermissionsForAction,
} from '../../BulkActions';
import { BulkActionButton } from '../BulkActionButton/index';
import { IsActionAllowedCallback } from '../ChangeOwnerButton/types';
import { CreateNewDraftProps } from './types';

export const CreateNewDraftButton = (props: CreateNewDraftProps) => {
  const { showToast } = useToastProvider();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateNewDraft = () => {
    setIsLoading(true);
    documentApi
      .createDraftFromEffectiveDoc(
        props.currentUser.companyId,
        props.selectedDocuments[0].document_matrix_id,
      )
      .then((doc: QualioDocument) => {
        setIsLoading(false);
        redirectTo(`/workspace/documents/${doc.id}/edit`);
      })
      .catch((e) => {
        setIsLoading(false);
        const failureMessage =
          e.response?.data?.message ||
          'Failed to create new draft. Please try again.';
        showToast({
          id: 'create-new-draft-error-toast',
          status: 'error',
          title: 'Error',
          description: failureMessage,
        });
      });
  };

  const canCreateNewDraft: IsActionAllowedCallback = (
    user,
    statuses,
    activeTab,
    requiredPermissions,
    selectedDocuments,
  ) => {
    if (selectedDocuments.length > 1) {
      return false;
    }
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions)
    ) {
      return false;
    }

    if (activeTab === WorkspaceTabs.YOUR_DOCUMENTS) {
      return hasAllowedStatusesForAction(
        [
          DocumentStatus.Approved,
          DocumentStatus.Effective,
          DocumentStatus.For_Retirement,
          DocumentStatus.Retired,
          DocumentStatus.For_Periodic_Review,
        ],
        statuses,
      );
    }
    return hasAllowedStatusesForAction(
      [
        DocumentStatus.Approved,
        DocumentStatus.Effective,
        DocumentStatus.For_Periodic_Review,
      ],
      statuses,
    );
  };

  return (
    <BulkActionButton
      {...props}
      isActionAllowed={canCreateNewDraft}
      onClick={handleCreateNewDraft}
      isLoading={isLoading}
    />
  );
};
