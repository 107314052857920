import { Tooltip } from '@chakra-ui/react';
import { QIconButton, QualioQThemeProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { QualioDocument } from '../../../api/model/document';
import { CKEditorInstance } from '../../../types/CKEditorInstance';
import { DocumentSectionsManager } from '../DocumentSectionsManager';
import { TableOfContents } from '../TableOfContents';
import styles from './DocumentContentEditor.module.css';

export const DocumentContentEditor: React.FC<{
  editorDocument: QualioDocument;
  companyId: number;
  currentUser: CurrentUser;
  handleAutoSave: (editor: CKEditorInstance) => Promise<void>;
  handleEditorsReady: (editors: CKEditorInstance[]) => void;
  handleContextReady: (context: any) => void;
  commentsPermission: number;
  inEditMode: boolean;
  inEditModeCallback: (inEditMode: boolean) => void;
  editorsReady: boolean;
  collaborationToken: string;
}> = ({
  editorDocument,
  companyId,
  currentUser,
  handleAutoSave,
  handleEditorsReady,
  handleContextReady,
  commentsPermission,
  inEditMode,
  inEditModeCallback,
  editorsReady,
  collaborationToken,
}) => {
  const visibilityClass = `document-editor__document__content-container__${
    editorsReady ? 'show' : 'hide'
  }`;

  const editorViewingModeClass = !inEditMode
    ? `document-editor__document__content-container__viewing`
    : '';

  return (
    <div
      className={`${styles['document-editor__document__content-container']} ${styles[visibilityClass]} ${styles[editorViewingModeClass]}`}
    >
      {editorsReady && (
        <TableOfContents
          sections={editorDocument.sections}
          sectionTitlesVisible={editorDocument.text_sections_titles_visible}
        />
      )}
      <div
        id="documentEditorContent"
        data-testid="documentEditorContent"
        className={`${
          styles['document-editor__document__section-content']
        } document ${!inEditMode ? 'non-editable-mode' : ''}`}
      >
        <DocumentSectionsManager
          document={editorDocument}
          companyId={companyId}
          currentUser={currentUser}
          handleAutoSave={handleAutoSave}
          commentsPermission={commentsPermission}
          readyHandler={handleEditorsReady}
          contextReadyCallback={handleContextReady}
          inEditModeCallback={inEditModeCallback}
          collaborationToken={collaborationToken}
          inEditMode={inEditMode}
        />
      </div>
      <div
        hidden={commentsPermission <= 1}
        id="documentCommentControl"
        data-testid="documentCommentControl"
        className={`${styles['document-editor__document__comment-control']} hidden`}
      >
        <QualioQThemeProvider>
          <Tooltip
            label={'Select content to add a comment'}
            hasArrow={true}
            placement={'top'}
          >
            <p>
              <QIconButton
                iconName={'MessageSquare'}
                aria-label="icon-button"
                size="md"
              />
            </p>
          </Tooltip>
        </QualioQThemeProvider>
      </div>
      <div
        id="commentsSidebar"
        data-testid="commentsSidebar"
        className={`${styles['document-editor__document__sidebar']}`}
      ></div>
    </div>
  );
};
