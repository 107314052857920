import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  isApprover,
  isOwner,
  isQualityUserInAccount,
  isReviewer,
} from '../../../util/CurrentUser';

import {
  QualioQThemeProvider,
  ToastProvider,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../../api/document';
import { QualioDocument } from '../../../api/model/document';
import { registerParentMessageHandler } from '../../../config/handlers/IFrameMessageHandler';
import { redirect, reload } from '../../../util/PageUtils';

interface ChangeStatusProps {
  currentDocument: QualioDocument;
  currentUser: CurrentUser;
  updateHasError: (hasError: boolean, errorMsg?: string) => void;
}

export enum ChangeAction {
  Redirect = 'redirect',
  Reload = 'reload',
}

const ToastProviderChildren = ({
  currentDocument,
  currentUser,
  updateHasError,
}: ChangeStatusProps) => {
  const [sessionDisconnected, setSessionDisconnected] = useState(false);
  const { showToast } = useToastProvider();

  const sessionDisconnectedCallback = () => {
    setSessionDisconnected(true);
  };

  useEffect(() => {
    registerParentMessageHandler({
      name: 'collab-connection-manager',
      sessionDisconnectedCallback,
    });
  }, []);

  const doDocumentChangeAction = (
    newDocument: QualioDocument,
    currentUser: CurrentUser,
    change: string,
  ) => {
    const changeAction = determineChangeAction(newDocument, currentUser);
    if (changeAction === ChangeAction.Reload) {
      showToast({
        title: `${capitalise(change)} changed`,
        description: `The ${capitalise(
          change,
        )}  of this document has changed. This page will reload to show the updated state.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      reload();
    } else if (changeAction === ChangeAction.Redirect) {
      showToast({
        title: `${capitalise(change)} changed`,
        description: `The ${change} of this document has changed. You will be redirected to the dashboard as you do not have permissions to access the latest version of this document.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      redirect();
    }
  };
  const getDocument = async () =>
    documentApi.fetch(currentDocument.id.toString());
  const { error, data: newDocument } = useQuery({
    queryKey: ['newDocument'],
    queryFn: getDocument,
    enabled: sessionDisconnected,
  });
  const isChangeOwner =
    newDocument && currentDocument.owner.id !== newDocument.owner.id;
  const isChangeStatus =
    newDocument && currentDocument.status_id !== newDocument.status_id;
  const isChangeFormat =
    newDocument &&
    currentDocument.document_format !== newDocument.document_format;
  if (
    error ||
    (newDocument && !isChangeOwner && !isChangeStatus && !isChangeFormat)
  ) {
    updateHasError(
      false,
      'There was an error with your editing session. Please reload the browser',
    );
  } else if (isChangeStatus || isChangeOwner || isChangeFormat) {
    const change = determineChange(
      isChangeOwner,
      isChangeStatus,
      isChangeFormat,
    );
    doDocumentChangeAction(newDocument, currentUser, change);
  }

  return <></>;
};

export const CollabServerConnectionManager = (props: ChangeStatusProps) => {
  return (
    <QualioQThemeProvider>
      <ToastProvider>
        <ToastProviderChildren {...props} />
      </ToastProvider>
    </QualioQThemeProvider>
  );
};

export const determineChangeAction = (
  document: QualioDocument,
  currentUser: CurrentUser,
): ChangeAction => {
  if (isQualityUserInAccount(currentUser)) {
    return ChangeAction.Reload;
  }
  if (isOwner(currentUser, document)) {
    return ChangeAction.Reload;
  }
  if (document.status_id === 'effective') {
    return ChangeAction.Reload;
  }
  if (
    isReviewer(currentUser, document) &&
    document.status_id === 'for_review'
  ) {
    return ChangeAction.Reload;
  }
  if (
    isApprover(currentUser, document) &&
    document.status_id === 'for_approval'
  ) {
    return ChangeAction.Reload;
  } else {
    return ChangeAction.Redirect;
  }
};

const determineChange = (
  isChangeOwner: boolean | undefined,
  isChangeStatus: boolean | undefined,
  isChangeFormat: boolean | undefined,
) => {
  if (isChangeOwner) {
    return 'owner';
  } else if (isChangeStatus) {
    return 'status';
  } else if (isChangeFormat) {
    return 'format';
  } else {
    return '';
  }
};

const capitalise = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
