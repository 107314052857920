import { Context } from '@ckeditor/ckeditor5-core';
import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { EventInfo } from '@ckeditor/ckeditor5-utils';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { QualioDocument } from '../../../api/model/document';
import { getIndependentContextConfig } from '../../../config/DocumentEditorConfig';
import {
  handleAddFileDocumentComment,
  handleRemoveCommentThreadV2,
} from '../../../config/handlers';
import QualioIndependentContext from '../../../editor/QualioIndependentContext';
import { useElementIsRendered } from '../../../hooks/ElementRenderedCheck';
import { getHTMLElement } from '../../../util/HTMLElementUtils';
import { refreshDisplayMode } from '../../../util/SidebarUtils';
import styles from './FileDocumentCommentsWrapper.module.css';

export const FileDocumentCommentsWrapper: React.FC<{
  currentUser: CurrentUser;
  attachmentFilename?: string;
  attachmentId?: string;
  fileDocument: QualioDocument;
  collaborationToken: string;
  commentsPermission: number;
  children: any;
  contextHandler?: (context: Context) => void;
}> = ({
  currentUser,
  attachmentFilename,
  attachmentId,
  fileDocument,
  collaborationToken,
  commentsPermission,
  children,
  contextHandler,
}) => {
  const [isSideBarRendered, sidebarElement] =
    useElementIsRendered('#commentsSidebar');
  const { qualioFrontendRefresh } = useFlags();

  const contextReadyHandler = (context: Context) => {
    refreshDisplayMode({
      instances: [context],
      windowWidth: window.innerWidth,
      breakpointOverride: qualioFrontendRefresh ? 1800 : undefined,
    });
    window.addEventListener('resize', () => {
      refreshDisplayMode({
        instances: [context],
        windowWidth: window.innerWidth,
        breakpointOverride: qualioFrontendRefresh ? 1800 : undefined,
      });
    });
    setupComments(context);
    setupCommentButton(context);
    contextHandler && contextHandler(context);
  };

  const setupCommentButton = (context: any) => {
    const channelId = context.config.get('collaboration').channelId;
    const commentsRepository = context.plugins.get('CommentsRepository');
    const commentButton = getHTMLElement(
      '#controls button[aria-label="Comment"]',
    );
    const { id } = fileDocument;

    // Only register event listener if both document id and attachment id are present
    if (id && attachmentId) {
      commentButton?.addEventListener('click', () => {
        const commentsAnchor = getHTMLElement('#commentsAnchor');
        const threadId = `${id}-${attachmentId}-${Date.now()}`;
        commentsRepository.openNewCommentThread({
          channelId,
          threadId,
          target: commentsAnchor,
        });
      });
    }
    if (commentButton?.hasAttribute('disabled')) {
      commentButton?.removeAttribute('disabled');
    }
  };
  const setupComments = (context: any) => {
    const channelId = context.config.get('collaboration').channelId;
    const commentsRepository = context.plugins.get('CommentsRepository');

    for (const thread of commentsRepository.getCommentThreads({ channelId })) {
      handleCommentThread(thread);
    }

    commentsRepository.on(
      'addCommentThread:' + channelId,
      (_evt: any, data: any) => {
        handleCommentThread(commentsRepository.getCommentThread(data.threadId));
      },
      { priority: 'low' },
    );

    commentsRepository?.on(
      'addComment',
      (evt: EventInfo, data: CommentsRepository.CommentData) => {
        if (attachmentFilename) {
          handleAddFileDocumentComment(
            evt,
            data,
            currentUser,
            attachmentFilename,
          );
        }
      },
      {
        priority: 'highest',
      },
    );

    commentsRepository?.on('removeCommentThread', handleRemoveCommentThreadV2, {
      priority: 'highest',
    });

    function handleCommentThread(thread: any) {
      const commentsAnchor = getHTMLElement('#commentsAnchor');
      if (!thread.isAttached && !thread.deletedAt) {
        thread.attachTo(commentsAnchor);
      }
    }
  };

  const contextConfig = useMemo(
    () =>
      getIndependentContextConfig({
        fileDocument,
        collaborationToken,
        commentsPermission,
        sidebarConfig: { sidebarElement, preventScrollOutOfView: true },
      }),
    [fileDocument, collaborationToken, commentsPermission, sidebarElement],
  );

  return (
    <>
      <CKEditorContext
        context={QualioIndependentContext}
        isLayoutReady={isSideBarRendered}
        config={contextConfig}
        onReady={(context: Context) => contextReadyHandler(context)}
      >
        {/*renders pdf viewer or image viewer depending on the preview type*/}
        {children}
      </CKEditorContext>
      <div
        id="commentsSidebar"
        data-testid="commentsSidebar"
        className={`${styles['file-document__sidebar']}`}
      ></div>
    </>
  );
};
