import { Controller } from 'react-hook-form';

import { Filtering, Pagination, QInput } from '@qualio/ui-components';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export type SearchFilterProps = {
  id: string;
};
export const SearchFilter = ({ id }: SearchFilterProps) => {
  const { control } = Filtering.useFilteringForm();
  const filtering = Filtering.useFiltering();
  const pagination = Pagination.usePaginationMaybe();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query');

  useEffect(() => {
    pagination?.setPageIndex(0);
    // Can't have `paginaton` as a dependency or else pagination will not work
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <Controller
      name="Search"
      control={control}
      render={({ field: { onChange, value } }) => {
        const handleChange = (e: any) => {
          onChange(e);
          filtering.setSearchTerm(e.target.value);
        };
        return (
          <QInput
            iconLeftName="Search"
            onChange={handleChange}
            placeholder={'Search...'}
            value={searchQuery ?? ''}
            data-testid={`${id}-search`}
            data-cy={`${id}-search`}
          />
        );
      }}
    />
  );
};
