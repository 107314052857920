import { useMemo } from 'react';

import {
  QBox,
  QButton,
  QFlex,
  QIcon,
  QStack,
  QText,
} from '@qualio/ui-components';

import { QualioDocumentAttachment } from '../../../api/model/document';
import { RemoteFileMetadata } from '../QFilePicker/index';

import { formatDateFromTimestampInSecondsFromEpoch } from '../../../util/DateUtils';
import fileDocumentEditorStyles from '../FileDocumentEditor/FileDocumentEditor.module.css';

export interface FileUploadedProps {
  attachment: QualioDocumentAttachment | undefined;
  isFetchingLatest: boolean;
  handleDelete: () => void;
  handleFetchLatest: (files: RemoteFileMetadata[]) => void;
  tz: string;
}

export const UploadedFile = ({
  attachment,
  isFetchingLatest,
  handleDelete,
  handleFetchLatest,
  tz,
}: FileUploadedProps) => {
  const isDriveFile = useMemo(
    () =>
      !!attachment?.external_file_source_type &&
      !!attachment?.external_file_download_url &&
      !!attachment?.external_file_url,
    [attachment],
  );

  const openDrive = () => {
    const url =
      attachment && attachment.external_file_url
        ? attachment.external_file_url
        : undefined;
    window.open(url);
  };

  const downloadUrl = attachment?.external_file_download_url
    ? attachment.external_file_download_url
    : '';
  const webUrl = attachment?.external_file_url
    ? attachment.external_file_url
    : '';

  return (
    <QBox rounded="base" borderColor={'gray.400'} p={4} borderWidth="1px">
      <QFlex justify="space-between">
        <QStack direction="row">
          <QText weight="semibold" lineHeight="8" fontSize={'sm'}>
            {attachment?.filename}
          </QText>

          {attachment?.external_file_last_fetched_at ? (
            <QText lineHeight="8" data-testid="attachment-last-fetched">
              {' • Last fetched on ' +
                formatDateFromTimestampInSecondsFromEpoch(
                  attachment.external_file_last_fetched_at,
                  tz,
                )}
            </QText>
          ) : null}
        </QStack>

        <QStack direction="row">
          {isDriveFile && (
            <div
              className={
                fileDocumentEditorStyles['one-drive-file-actions-container']
              }
            >
              <QButton
                aria-label="openFile"
                data-testid={'openFile'}
                data-cy={'openFile'}
                variant="ghost"
                onClick={openDrive}
                leftIcon="ExternalLink"
              >
                <QText>View in OneDrive</QText>
              </QButton>
              <QButton
                aria-label="fetchLatest"
                data-testid={'fetchLatest'}
                data-cy={'fetchLatest'}
                variant="outline"
                isLoading={isFetchingLatest}
                onClick={() =>
                  handleFetchLatest([
                    {
                      filename: attachment ? attachment.filename : '',
                      downloadUrl,
                      webUrl,
                      source: 'onedrive',
                    },
                  ])
                }
              >
                <QIcon iconName={'RefreshCw'}></QIcon>{' '}
                <QText>&nbsp;Fetch latest</QText>
              </QButton>
            </div>
          )}

          <QButton
            data-testid={'deleteAttachment'}
            data-cy={'deleteAttachment'}
            isDestructive={true}
            variant="ghost"
            onClick={handleDelete}
            width={'var(--chakra-sizes-8)'}
          >
            <QIcon iconName={'Trash'} aria-label="Delete" />
          </QButton>
        </QStack>
      </QFlex>
    </QBox>
  );
};
