import {
  QBox,
  QButton,
  QCheckbox,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { documentApi } from '../../../../../api/document';
import {
  DocumentFormat,
  QualioDocument,
} from '../../../../../api/model/document';
import { DocumentOverviewContext } from '../../Context';
import {
  determineFileDownloadUsergroupPermissionsFromModel,
  determineFileDownloadUsergroupsPermissionsModelValue,
  FileDownloadPermissionOptions,
} from '../utils';

type EditDocumentPropertiesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type EditDocumentPropertiesForm = {
  title: QualioDocument['title'];
  reviewPeriod: string;
  effectiveOnApproval: QualioDocument['effective_on_approval'];
  retrainingRequired: QualioDocument['training_required'];
  fileDownloadUsergroups: keyof typeof FileDownloadPermissionOptions;
};

export const EditDocumentPropertiesModal = ({
  isOpen,
  onClose,
}: EditDocumentPropertiesModalProps) => {
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { showToast } = useToastProvider();
  const [isSaving, setIsSaving] = useState(false);
  const commonToastProps = {
    id: 'edit-document-properties-toast',
    replace: true,
  };

  const hasMajorVersion = useMemo(
    () => qualioDocument.major_version > 0,
    [qualioDocument.major_version],
  );

  const defaultFormValues: EditDocumentPropertiesForm = useMemo(
    () => ({
      title: qualioDocument.title,
      reviewPeriod: (qualioDocument.review_period ?? 0).toString(),
      effectiveOnApproval: qualioDocument.effective_on_approval,
      retrainingRequired: qualioDocument.training_required,
      fileDownloadUsergroups:
        determineFileDownloadUsergroupPermissionsFromModel(qualioDocument),
    }),
    [qualioDocument],
  );

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<EditDocumentPropertiesForm>({
    defaultValues: defaultFormValues,
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const onSave = async ({
    title,
    reviewPeriod,
    effectiveOnApproval,
    retrainingRequired,
    fileDownloadUsergroups,
  }: EditDocumentPropertiesForm) => {
    setIsSaving(true);
    const updatedDocumentPayload = {
      title,
      review_period: reviewPeriod === '0' ? null : Number(reviewPeriod),
      effective_on_approval: effectiveOnApproval,
      training_required: retrainingRequired,
      file_download_user_groups:
        determineFileDownloadUsergroupsPermissionsModelValue(
          fileDownloadUsergroups,
        ),
    };
    try {
      await documentApi
        .update(updatedDocumentPayload, qualioDocument.id, true)
        .then(() => refetchDocument());
      setIsSaving(false);
      showToast({
        ...commonToastProps,
        status: 'success',
        title: 'Properties updated!',
        description: 'Successfully updated document properties.',
      });
      closeModal();
    } catch (e) {
      setIsSaving(false);
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: 'Failed to update document properties.',
      });
    }
  };

  const reviewPeriodOptions = useMemo(() => {
    const data = [];
    data.push({
      value: '0',
      label: 'None',
    });
    for (let i = 1; i <= 36; i++)
      data.push({
        value: i.toString(),
        label: i.toString(),
      });
    for (let j = 42; j <= 84; j += 6)
      data.push({
        value: j.toString(),
        label: j.toString(),
      });
    return data;
  }, []);

  const downloadPermissionsOptions = useMemo(() => {
    return Object.entries(FileDownloadPermissionOptions).map(
      ([value, label]) => ({
        value,
        label,
      }),
    );
  }, []);

  return (
    <QModal isOpen={isOpen} size="lg" onClose={closeModal}>
      <QModalHeader>
        <QText>Edit properties</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing="6">
          <QFormControl
            label="Title"
            isInvalid={!!errors.title}
            error={errors.title?.message}
          >
            <QInput
              {...register('title', {
                required: 'Title is required.',
              })}
              placeholder="Document title..."
              aria-label="Document title"
              data-testid="document-title-input"
              data-cy="document-title-input"
            />
          </QFormControl>
          <QBox width="50%">
            <QFormControl
              id="document-review-period-select"
              label="Review period"
              helper="Months from effective date"
            >
              <Controller
                name="reviewPeriod"
                control={control}
                render={({ field: { ref, onChange, ...fieldControl } }) => {
                  return (
                    <QSelect
                      {...fieldControl}
                      options={reviewPeriodOptions}
                      size="sm"
                      aria-label="Review period"
                      data-testid="document-review-period-select"
                      data-cy="document-review-period-select"
                      onChange={(selectedReviewPeriod) => {
                        onChange(selectedReviewPeriod!.value);
                      }}
                    ></QSelect>
                  );
                }}
              />
            </QFormControl>
          </QBox>
          <QFormControl helper="Automatically makes the document effective once it has been approved.">
            <Controller
              name="effectiveOnApproval"
              control={control}
              render={({ field: { ref, value, ...fieldControl } }) => {
                return (
                  <QCheckbox
                    {...fieldControl}
                    aria-label="Document effective on approval"
                    data-testid="document-effective-on-approval-checkbox"
                    data-cy="document-effective-on-approval-checkbox"
                    isChecked={getValues().effectiveOnApproval}
                  >
                    Effective on approval
                  </QCheckbox>
                );
              }}
            ></Controller>
          </QFormControl>
          {hasMajorVersion && (
            <QFormControl helper="Requires training on new document versions.">
              <Controller
                name="retrainingRequired"
                control={control}
                render={({ field: { ref, value, ...fieldControl } }) => {
                  return (
                    <QCheckbox
                      {...fieldControl}
                      aria-label="Document retraining required"
                      data-testid="document-retraining-required-checkbox"
                      data-cy="document-retraining-required-checkbox"
                      isChecked={getValues().retrainingRequired}
                    >
                      Retraining required
                    </QCheckbox>
                  );
                }}
              ></Controller>
            </QFormControl>
          )}
          {qualioDocument.document_format === DocumentFormat.DocumentUpload && (
            <QFormControl
              id="document-download-permissions-select"
              label="Download permissions"
              helper="Permissions to download the file in its original format."
            >
              <Controller
                name="fileDownloadUsergroups"
                control={control}
                render={({ field: { ref, onChange, ...fieldControl } }) => {
                  return (
                    <QSelect
                      {...fieldControl}
                      options={downloadPermissionsOptions}
                      size="sm"
                      aria-label="download permissions"
                      data-testid="document-download-permissions-select"
                      data-cy="document-download-permissions-select"
                      onChange={(selectedUsergroups) => {
                        onChange(selectedUsergroups!.value);
                      }}
                    />
                  );
                }}
              />
            </QFormControl>
          )}
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          data-cy="document-edit-properties-cancel-button"
          variant="outline"
          onClick={closeModal}
        >
          Cancel
        </QButton>
        <QButton
          data-cy="document-edit-properties-save-button"
          isLoading={isSaving}
          onClick={handleSubmit(onSave)}
        >
          Save changes
        </QButton>
      </QModalActions>
    </QModal>
  );
};
