import {
  QBox,
  QEmptyState,
  QSelect,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../api/document';
import {
  COMMENT_HISTORY_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { DocumentCommentHistoryCard } from '../DocumentCommentHistoryPanel/DocumentCommentHistoryCard/DocumentCommentHistoryCard';
import { parseFetchedCommentThreadsForDisplay } from '../DocumentCommentHistoryPanel/DocumentCommentHistoryUtil';

interface CommentHistoryTabProps {
  companyId: number;
  documentId: string;
  isOpen: boolean;
}

type CommmentContextSelectOptions = {
  label: CommmentContextOptions;
  value: CommentContext;
};

export enum CommmentContextOptions {
  DOCUMENT = 'Document',
  CHANGE_CONTROL = 'Change control',
}

export type CommentContext = 'document' | 'change-control';

export const CommentHistoryTab = ({
  companyId,
  documentId,
  isOpen,
}: CommentHistoryTabProps) => {
  const { showToast } = useToastProvider();
  const [selectedCommentContext, setSelectedCommentContext] =
    useState<CommentContext>('document');
  const commentContextOptions: CommmentContextSelectOptions[] = [
    {
      label: CommmentContextOptions.DOCUMENT,
      value: 'document',
    },
    {
      label: CommmentContextOptions.CHANGE_CONTROL,
      value: 'change-control',
    },
  ];

  const onCommentHistoryContextChange = (
    selectedCommentContext: CommmentContextSelectOptions | null,
  ) => {
    if (selectedCommentContext) {
      setSelectedCommentContext(selectedCommentContext.value);
    }
  };

  const loadComments = async (companyId: number, documentId: string) => {
    return await documentApi
      .fetchCommentThreads(companyId, documentId, selectedCommentContext)
      .then((fetchedThreads) =>
        parseFetchedCommentThreadsForDisplay(fetchedThreads),
      );
  };

  const { isFetching: isFetchingComments, data: commentThreads } = useQuery({
    queryKey: ['commentHistory', companyId, documentId, selectedCommentContext],
    queryFn: () => loadComments(companyId, documentId),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-comment-history',
        title: 'Error',
        description: 'Could not retrieve comment history',
        status: 'error',
      });
      logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
    },
  });

  if (isFetchingComments) {
    return (
      <QStack height="100%" minHeight="60vh" justify="center" align="center">
        <QSpinner />
        <QText data-testid="loading-comments-history">
          Loading comments...
        </QText>
      </QStack>
    );
  }

  return (
    <QStack fontSize="sm" pt="4">
      {!isFetchingComments && (
        <QStack direction="row" justifyContent="space-between">
          <QBox width={160}>
            <QSelect
              size="sm"
              options={commentContextOptions}
              onChange={onCommentHistoryContextChange}
              value={selectedCommentContext}
              aria-label="Comment context"
              data-cy="comment-context-select"
              data-testid="comment-context-select"
            ></QSelect>
          </QBox>
        </QStack>
      )}

      <QStack fontSize="sm">
        {commentThreads && commentThreads?.length > 0 ? (
          commentThreads?.map((commentThread: any) => (
            <DocumentCommentHistoryCard
              commentThread={commentThread}
              key={commentThread.id}
            />
          ))
        ) : (
          <QEmptyState
            data-testid="comments-history-empty-placeholder"
            title="No comments added to the current version."
            subtitle="Add a comment to begin the discussion!"
          />
        )}
      </QStack>
    </QStack>
  );
};
