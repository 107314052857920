export const sortValues = {
  code: 'code',
  Title: 'title',
  Type: 'type',
  Owner: 'author_name',
  Version: 'version',
  status_id: 'status_id',
};

type SortValue = keyof typeof sortValues;
export type SortCallbackObj = {
  id: SortValue;
  desc: boolean;
};

export const buildSortingValue = (sortingRules: SortCallbackObj[]) => {
  if (!sortingRules?.length) {
    return undefined;
  }

  return `${sortingRules[0].desc ? '-' : ''}${sortValues[sortingRules[0].id]}`;
};

export const buildSortSearchParams = (
  sortingRules: SortCallbackObj[],
  searchParams: URLSearchParams,
) => {
  const newValue = buildSortingValue(sortingRules);

  if (!newValue) {
    searchParams.delete('order_by');
  } else {
    searchParams.set('order_by', newValue);
  }

  return searchParams;
};

const getColumnIdFromSortingValue = (value: string) => {
  const normalizedValue = value.startsWith('-') ? value.substring(1) : value;
  return (Object.keys(sortValues) as SortValue[]).find(
    (key: SortValue) => sortValues[key] === normalizedValue,
  );
};

export const buildSortingRules = (value: string | null) => {
  if (!value) {
    return [];
  }

  return [
    {
      id: getColumnIdFromSortingValue(value),
      desc: value.startsWith('-'),
    },
  ];
};
