//--------------------------------------- Logged Errors ---------------------------------------//
// Error identifier pattern: {componentName?}_{errorDescription}_ERROR

import { AxiosError } from 'axios';

export type ErrorLogMetaData = {
  error?: any;
  companyId?: number;
  documentId?: number;
  entityId?: number;
};

export type ErrorLog = {
  errorIdentifier: string;
  errorMessage: string;
};

export const QUALIO_LOGGED_ERROR_TAG = '[qualio-logged-error]';

// Error message pattern: {ERROR_IDENTIFIER}: [<metadata>] <message>
export const logCustomError = (log: ErrorLog, metaData?: ErrorLogMetaData) => {
  console.error(
    `${QUALIO_LOGGED_ERROR_TAG} ${log.errorIdentifier}: ${log.errorMessage}`,
  );
  if (metaData) {
    let message = `${log.errorIdentifier}`;
    if (metaData.error?.msg) {
      message += `\nMetadata - Error Message: ${metaData.error.msg}`;
    }
    if (metaData.companyId) {
      message += `\nMetadata - Company ID: ${metaData.companyId}`;
    }
    if (metaData.documentId) {
      message += `\nMetadata - Document ID: ${metaData.documentId}`;
    }
    if (metaData.entityId) {
      message += `\nMetadata - Entity ID: ${metaData.entityId}`;
    }
    if (metaData.error instanceof AxiosError && metaData.error.response?.data) {
      message += `\nAxios Response: ${JSON.stringify(
        metaData.error.response?.data,
      )}`;
    } else {
      message += `\nMetadata - ${JSON.stringify(metaData?.error)}`;
    }
    console.error(message);
  }
};

export const INDEX_UNMOUNT_EDITOR_ERROR: ErrorLog = {
  errorIdentifier: 'INDEX_UNMOUNT_EDITOR_ERROR',
  errorMessage: 'Error unmounting document editor container',
};

export const FILE_UPLOAD_PLUGIN_ATTACHMENT_UPLOAD_ERROR: ErrorLog = {
  errorIdentifier: 'FILE_UPLOAD_PLUGIN_ATTACHMENT_UPLOAD_ERROR',
  errorMessage: 'Error creating inline attachment in document',
};

export const SMART_LINK_PLUGIN_BULK_RESOLVE_ERROR: ErrorLog = {
  errorIdentifier: 'SMART_LINK_PLUGIN_BULK_RESOLVE_ERROR',
  errorMessage: 'Error bulk-resolving existing smart links',
};

export const SMART_LINK_POSTFIXER_ERROR: ErrorLog = {
  errorIdentifier: 'SMART_LINK_POSTFIXER_ERROR',
  errorMessage: 'Error fixing partial smart links',
};

export const DOCUMENT_REDUCER_SAVE_ERROR: ErrorLog = {
  errorIdentifier: 'DOCUMENT_REDUCER_SAVE_ERROR',
  errorMessage: 'Error saving the document',
};

export const DOCUMENT_CONVERSION_CHANGE_FORMAT_ERROR: ErrorLog = {
  errorIdentifier: 'DOCUMENT_CONVERSION_CHANGE_FORMAT_ERROR',
  errorMessage: 'Error converting to file format',
};

export const COMMENT_HISTORY_FETCH_DATA_ERROR: ErrorLog = {
  errorIdentifier: 'COMMENT_HISTORY_FETCH_DATA_ERROR',
  errorMessage: 'Error fetching existing data for comment history',
};

export const ACTIVITY_LOG_FETCH_DATA_ERROR: ErrorLog = {
  errorIdentifier: 'ACTIVITY_LOG_FETCH_DATA_ERROR',
  errorMessage: 'Error fetching existing data for activity log',
};

export const ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR: ErrorLog = {
  errorIdentifier: 'ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR',
  errorMessage: 'Error processing unsupported activity log type',
};

export const PATCH_SUGGESTION_DATA_ERROR: ErrorLog = {
  errorIdentifier: 'PATCH_SUGGESTION_DATA_ERROR',
  errorMessage:
    'Error accepting or declining suggestion. Document id cannot be retrieved from editor config',
};

export const PATCH_COMMENT_DATA_ERROR: ErrorLog = {
  errorIdentifier: 'PATCH_COMMENT_DATA_ERROR',
  errorMessage:
    'Error resolving a comment. Company id or document id cannot be retrieved from editor config.',
};

export const API_ATTACHMENT_UPLOAD_ERROR: ErrorLog = {
  errorIdentifier: 'API_ATTACHMENT_UPLOAD_ERROR',
  errorMessage: 'Error uploading an attachment',
};

export const API_ATTACHMENT_EXTERNAL_SOURCE_ERROR: ErrorLog = {
  errorIdentifier: 'API_ATTACHMENT_EXTERNAL_SOURCE_ERROR',
  errorMessage: 'Error adding an attachment from an external source',
};

export const API_FETCH_ATTACHMENT_BLOB_ERROR: ErrorLog = {
  errorIdentifier: 'API_FETCH_ATTACHMENT_BLOB_ERROR',
  errorMessage: 'Error fetching attachment data',
};

export const CKE_EDITOR_ERROR: ErrorLog = {
  errorIdentifier: 'CKE_EDITOR_ERROR',
  errorMessage: 'Error with editor',
};

export const BULK_FILE_UPLOAD_IMPORT_ERROR: ErrorLog = {
  errorIdentifier: 'BULK_FILE_UPLOAD_IMPORT_ERROR',
  errorMessage: 'Error during bulk import process',
};

export const BULK_FILE_UPLOAD_IMPORT_AUTH_ERROR: ErrorLog = {
  errorIdentifier: 'BULK_FILE_UPLOAD_IMPORT_AUTH_ERROR',
  errorMessage: 'Error with user authorization during bulk import process',
};

export const BULK_FILE_UPLOAD_ADD_FILE_ERROR: ErrorLog = {
  errorIdentifier: 'BULK_FILE_UPLOAD_ADD_FILE_ERROR',
  errorMessage: 'Error during adding files to bulk import',
};

export const EXTERNAL_SOURCE_FILE_ERROR: ErrorLog = {
  errorIdentifier: 'EXTERNAL_SOURCE_FILE_ERROR',
  errorMessage: 'Error during adding file/s from an external source',
};

export const DOCMENT_TEMPLATE_UPGRADE_PREVIEW: ErrorLog = {
  errorIdentifier: 'DOCMENT_TEMPLATE_UPGRADE_PREVIEW',
  errorMessage:
    'Error during creating the preview for upgrading document to new template version',
};

export const DOCMENT_TEMPLATE_UPGRADE: ErrorLog = {
  errorIdentifier: 'DOCMENT_TEMPLATE_UPGRADE',
  errorMessage: 'Error during upgrading document to new template version',
};

export const EDITOR_READY_ERROR: ErrorLog = {
  errorIdentifier: 'EDITOR_READY_ERROR',
  errorMessage: 'Error creating onReady handler for editor',
};

export const EDITOR_CONTEXT_READY_ERROR: ErrorLog = {
  errorIdentifier: 'CONTEXT_READY_ERROR',
  errorMessage: 'Error creating onReady handler for editor context',
};
