import {
  QAlert,
  QButton,
  QButtonProps,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
} from '@qualio/ui-components';
import { useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';

export interface CredentialConfirmationModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  handleStatusChange: (
    email: string,
    password: string,
    comment?: string,
  ) => Promise<void>;
  handleError?: (error: unknown) => void;
  modalHeading: string;
  modalText: string | JSX.Element;
  buttonActionLabel: string;
  buttonProps?: QButtonProps;
  withComment?: boolean;
}

type CredentialsForm = {
  email: string;
  password: string;
  comment?: string;
};

const CredentialsError = (email: string) =>
  z.object({
    response: z.object({
      data: z.object({
        message: z.union([
          z.literal(
            `Email mismatch! User email provided (${email}) is not correct!`,
          ),
          z.literal('Wrong password!'),
          z.literal(
            `Email mismatch! User email provided (${email}) is not\ncorrect!`,
          ),
        ]),
      }),
    }),
  });

export const CredentialConfirmationModal = ({
  isOpen,
  onCloseModal,
  handleStatusChange,
  handleError,
  modalHeading,
  modalText,
  buttonActionLabel,
  buttonProps = {
    isDestructive: false,
    variant: 'solid',
  },
  withComment = true,
}: CredentialConfirmationModalProps) => {
  const methods = useForm<CredentialsForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const [hasInvalidCredentials, setHasInvalidCredentials] = useState(false);
  const [isSigningOff, setIsSigningOff] = useState(false);

  const handleClose = () => {
    reset();
    setHasInvalidCredentials(false);
    onCloseModal();
  };

  const handleConfirm: SubmitHandler<CredentialsForm> = async ({
    email,
    password,
    comment,
  }) => {
    setHasInvalidCredentials(false);
    setIsSigningOff(true);
    await handleStatusChange(email, password, comment)
      .then(() => {
        reset();
      })
      .catch((error: unknown) => {
        const credentialsErrorSchema = CredentialsError(email);
        if (credentialsErrorSchema.safeParse(error).success) {
          setHasInvalidCredentials(true);
        } else if (handleError) {
          handleError(error);
        }
      })
      .finally(() => {
        setIsSigningOff(false);
      });
  };

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{modalHeading}</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing="4">
          {hasInvalidCredentials && (
            <QAlert
              status="error"
              title="Invalid credentials"
              description={
                <QText textTransform="initial">
                  The email or password is incorrect. Please try again.
                </QText>
              }
            />
          )}
          <QText fontSize="md">{modalText}</QText>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleConfirm)}>
              <QFormControl
                label="Email"
                isInvalid={Boolean(errors.email)}
                error={errors.email?.message}
              >
                <Controller
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      {...register('email', {
                        required: 'Please enter a valid email.',
                      })}
                      placeholder="Email"
                      data-testid={'confirmation-email'}
                      data-cy={'confirmation-email'}
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Password"
                isInvalid={Boolean(errors.password)}
                error={errors.password?.message}
              >
                <Controller
                  name="password"
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      {...register('password', {
                        required: 'This field is required.',
                      })}
                      type="password"
                      placeholder="Password"
                      data-testid={'confirmation-password'}
                      data-cy={'confirmation-password'}
                    />
                  )}
                />
              </QFormControl>
              {withComment && (
                <QFormControl label="Comment (optional)">
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <QTextarea
                          aria-label="Comment"
                          onChange={onChange}
                          value={value}
                          data-testid={'confirmation-comment'}
                          data-cy={'confirmation-comment'}
                          placeholder="Add an optional comment..."
                        />
                      );
                    }}
                  ></Controller>
                </QFormControl>
              )}
              {/* this is to submit the form on hitting enter */}
              <button hidden type="submit"></button>
            </form>
          </FormProvider>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={handleClose}>
          Cancel
        </QButton>
        <QButton
          {...buttonProps}
          data-testid={'confirm-with-credential-button'}
          data-cy={'confirmation-button'}
          isLoading={isSigningOff}
          onClick={handleSubmit(handleConfirm)}
        >
          {buttonActionLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};
