import { QPerson, QStack, QText, useCurrentUser } from '@qualio/ui-components';
import { useContext } from 'react';
import { convertEpochToDate } from '../../../../../util/DateUtils';
import { DocumentOverviewContext } from '../../Context';
import { DocumentUsersProps } from './types';
import { getDocumentUserProps } from './utils';

export const DocumentUsers = ({ userType, users = [] }: DocumentUsersProps) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { formatDate } = useCurrentUser();

  if (!users.length) {
    return (
      <QStack mt={3}>
        <QText>None</QText>
      </QStack>
    );
  }

  return (
    <QStack spacing={3} mt={3}>
      {users.map((user) => {
        const { status, timestamp, comment } = getDocumentUserProps({
          userType,
          qualioDocument,
          user,
        });
        if (status === 'pending' || status === 'default' || !timestamp) {
          return <QPerson fullName={user.full_name} status={status} />;
        } else {
          return (
            <QStack spacing={1}>
              <QPerson
                key={`${user.id}-${status}`}
                status={status}
                fullName={user.full_name}
                actionTimestamp={formatDate(
                  convertEpochToDate(timestamp),
                  true,
                )}
              />
              {comment && (
                <QText as="i" fontSize="xs" mt={1} color={'gray.700'}>
                  {comment}
                </QText>
              )}
            </QStack>
          );
        }
      })}
    </QStack>
  );
};
