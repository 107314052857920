import { Selecting } from '@qualio/ui-components';

type ListSelectionWrapperProps = {
  isReadOnly: boolean;
};

export const ListSelectionWrapper = ({
  isReadOnly,
  children,
}: React.PropsWithChildren<ListSelectionWrapperProps>) => {
  if (isReadOnly) {
    return <>{children}</>;
  }

  return (
    <Selecting.DefaultSelectionProvider onChange={() => undefined}>
      {children}
    </Selecting.DefaultSelectionProvider>
  );
};
