import React, { useCallback } from 'react';

export const ClickPropagationBarrier: React.FC<
  React.HTMLProps<HTMLSpanElement>
> = ({ children, ...rest }) => {
  const onClick = useCallback(
    (event: React.MouseEvent) => event.stopPropagation(),
    [],
  );

  return (
    <span {...rest} onClick={onClick}>
      {children}
    </span>
  );
};
