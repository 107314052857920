import { QToastProps } from '@qualio/ui-components';
import { AxiosError } from 'axios';
import {
  MigrationDTO,
  MigrationFinishedStatusLabelMap,
  MigrationStatusValueEnum,
} from '../../../api/model/migration';
import {
  BULK_FILE_UPLOAD_IMPORT_AUTH_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import {
  analyticsHandler,
  BulkUploadEvents,
} from '../../../util/Analytics/AnalyticsUtils';
import { getHttpError } from '../../../util/ErrorManagementUtils';
import {
  BULK_FILE_UPLOAD_FILES_LIMIT,
  checkFileExtensionMatchesAcceptedMimeTypes,
  FILE_UPLOAD_SIZE_LIMIT_MB,
} from '../../../util/FileUploadUtils';
import { reload } from '../../../util/PageUtils';
import { RemoteFileMetadata } from '../QFilePicker/index';

export const validateFilesBeforeUpload = (
  fileList: File[] | RemoteFileMetadata[],
  analytics: SegmentAnalytics.AnalyticsJS,
  currentBulkSize: number,
  showToast: (opts: QToastProps) => void,
  singleFile = false,
  isExternal = false,
) => {
  const sizeTitle = singleFile
    ? 'Failed to upload file'
    : 'Failed to add files';
  const sizeDescription = singleFile
    ? 'File is'
    : 'One or more of the added files are';

  if (!isExternal) {
    const hasFileOverSizeLimit = fileList.some(
      (file) =>
        file instanceof File &&
        file.size > FILE_UPLOAD_SIZE_LIMIT_MB * 1024 * 1024,
    );
    if (hasFileOverSizeLimit) {
      analyticsHandler(BulkUploadEvents.ExceedSizeLimit, analytics);
      showToast({
        id: 'bulk-upload-error',
        status: 'error',
        title: sizeTitle,
        description: `${sizeDescription} over ${FILE_UPLOAD_SIZE_LIMIT_MB}MB.`,
      });
      return false;
    }
  }

  if (!singleFile) {
    const hasInvalidFileAmount =
      fileList.length + currentBulkSize > BULK_FILE_UPLOAD_FILES_LIMIT;
    if (hasInvalidFileAmount) {
      showToast({
        status: 'warning',
        title: 'Maximum number of files added',
        description: `The maximum number of files per bulk import is ${BULK_FILE_UPLOAD_FILES_LIMIT}.`,
        id: 'maximum-files-warning-toast',
      });
      return false;
    }
  }

  const hasInvalidFormat = fileList.some((file) => {
    let filename = '';
    if (file instanceof File) {
      filename = file.name;
    } else {
      filename = file.filename;
    }
    return !checkFileExtensionMatchesAcceptedMimeTypes(filename);
  });
  if (hasInvalidFormat) {
    analyticsHandler(BulkUploadEvents.UnsuppportedFileType, analytics);
    showToast({
      status: 'error',
      title: 'Failed to add files',
      description:
        'One or more of the added files are of an unsupported file format.',
      id: 'unsupported-file-format-error-toast',
    });
    return false;
  }

  return true;
};

export const isMigrationComplete = (m: MigrationDTO | undefined) =>
  (m && m.status in MigrationFinishedStatusLabelMap) ?? false;

export const MigrationStatusCompleteUserMessageMap: {
  [status: string]: (n: number) => {
    message: QToastProps['description'];
    status: QToastProps['status'];
    title: QToastProps['title'];
  };
} = {
  [MigrationStatusValueEnum.COMPLETE]: (fileLength: number) => ({
    title: 'Import complete!',
    message: `Successfully imported ${fileLength} ${
      fileLength === 1 ? 'file' : 'files'
    }.`,
    status: 'success',
  }),
  [MigrationStatusValueEnum.ERROR]: (fileLength: number) => ({
    title: 'Import failed!',
    message: `Failed to import ${fileLength} ${
      fileLength === 1 ? 'file' : 'files'
    }.`,
    status: 'error',
  }),
};

export const handleMigrationError = (
  error: unknown,
  message: string,
  showToast: (opts: QToastProps) => void,
) => {
  if (error instanceof AxiosError && error.response?.status === 403) {
    showToast({
      title: 'Outdated User Information',
      description:
        'Active company has been switched, the page will reload shortly.',
      status: 'warning',
      id: `error-migration-toast`,
    });
    logCustomError(BULK_FILE_UPLOAD_IMPORT_AUTH_ERROR, { error });
    reload();
    return;
  }
  return getHttpError(error, message);
};
