import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { Group } from '../../../../../../api/model/group';
import { Tag } from '../../../../../../api/model/tag';
import { DocumentOverviewContext } from '../../../Context';
import { shouldShowManageTagsButton } from '../../../RenderLogic';
import { ManageTagsModal } from '../ManageTagsModal/index';

export type ManageTagsButtonProps = {
  tags: Tag[];
  groups: Group[];
};
export const ManageTagsButton = ({ tags, groups }: ManageTagsButtonProps) => {
  const { showToast } = useToastProvider();
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const canManageTags = useMemo(
    () => shouldShowManageTagsButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const commonToastProps = {
    id: 'overview-manage-tags-toast',
    replace: true,
  };

  const onSuccessCallback = () => {
    showToast({
      ...commonToastProps,
      status: 'success',
      title: 'Tags updated!',
      description: 'Successfully applied tag changes to document.',
    });
    refetchDocument();
  };

  const onErrorCallback = (error: unknown) => {
    const { message } = error as Error;
    const hasNoAccessToTag = message.includes('accessing');
    const defaultDescription =
      'Failed to apply tag changes to document. Please try again.';
    const description = hasNoAccessToTag ? message : defaultDescription;
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Error',
      description,
    });
  };

  if (!canManageTags) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy="document-manage-tags-button"
        variant="ghost"
        onClick={() => setIsModalOpen(true)}
      >
        Manage
      </QButton>
      <ManageTagsModal
        onClose={() => setIsModalOpen(false)}
        qualioDocument={qualioDocument}
        tags={tags}
        isOpen={isModalOpen}
        onSuccess={onSuccessCallback}
        onError={onErrorCallback}
        groups={groups}
      />
    </>
  );
};
