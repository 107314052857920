import { CurrentUser, QStack, QText } from '@qualio/ui-components';
import { useMemo } from 'react';
import { DocumentStatus, QualioDocument } from '../../../../api/model/document';
import { Tag } from '../../../../api/model/tag';
import { WorkspaceTabs } from '../types';
import { AssignTraineesButton } from './BulkActionButtons/AssignTrainees/AssignTraineesButton';
import { ChangeOwnerButton } from './BulkActionButtons/ChangeOwnerButton/index';
import { DeleteButton } from './BulkActionButtons/ChangeStatusButtons/DeleteButton/index';
import { MakeEffectiveButton } from './BulkActionButtons/ChangeStatusButtons/MakeEffectiveButton/index';
import { RetireButton } from './BulkActionButtons/ChangeStatusButtons/RetireButton/index';
import { RevertToDraftButton } from './BulkActionButtons/ChangeStatusButtons/RevertToDraftButton/index';
import { SendForApprovalButton } from './BulkActionButtons/ChangeStatusButtons/SendForApprovalButton/index';
import { SendForReviewButton } from './BulkActionButtons/ChangeStatusButtons/SendForReviewButton/index';
import { CreateNewDraftButton } from './BulkActionButtons/CreateNewDraftButton/index';
import { ManageTagsButton } from './BulkActionButtons/ManageTagsButton/ManageTagsButton';

export type BulkActionsProps = {
  currentUser: CurrentUser;
  selectedDocumentsMap: Map<QualioDocument['id'], QualioDocument>;
  currentTab: WorkspaceTabs;
  tags: Tag[];
  refetch: () => void;
  setSelectedDocumentsMap: (
    selectedDocuments: Map<QualioDocument['id'], QualioDocument>,
  ) => void;
};

export const hasRequiredPermissionsForAction = (
  requiredPermissions: readonly string[],
  userPermissions: CurrentUser['permissions'],
) =>
  requiredPermissions.some((permission) =>
    Object.keys(userPermissions).includes(permission),
  );

export const hasAllowedStatusesForAction = (
  allowedStatuses: DocumentStatus[],
  selectedStatuses: string[],
) =>
  selectedStatuses.length
    ? selectedStatuses.every((status) =>
        allowedStatuses.map((s) => s.valueOf()).includes(status),
      )
    : false;

export const isValidTabForAction = (
  allowedTabs: WorkspaceTabs[],
  activeTab: WorkspaceTabs,
) => allowedTabs.map((t) => t.valueOf()).includes(activeTab);

export const BulkActions = (props: BulkActionsProps) => {
  const { selectedStatuses, selectedDocuments } = useMemo(() => {
    const selectedDocuments = Array.from(props.selectedDocumentsMap.values());
    const selectedStatuses = Array.from(
      new Set(selectedDocuments.map(({ status_id }) => status_id)),
    );
    return {
      selectedStatuses,
      selectedDocuments,
    };
  }, [props.selectedDocumentsMap]);

  return (
    <QStack isInline minHeight="32px">
      {!!selectedDocuments.length && (
        <QText pt="1" fontWeight="semibold">
          {selectedDocuments.length} selected
        </QText>
      )}
      <SendForReviewButton
        label="Send for review"
        requiredPermissions={['can_author_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={Array.from(props.selectedDocumentsMap.values())}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <SendForApprovalButton
        label="Send for approval"
        requiredPermissions={['can_author_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <DeleteButton
        label="Delete"
        requiredPermissions={['can_change_doc_owner']} // This ensures that normal users don't see the delete button
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={Array.from(props.selectedDocumentsMap.values())}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <RevertToDraftButton
        label="Revert to draft"
        requiredPermissions={['can_author_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <CreateNewDraftButton
        label="Create new draft"
        requiredPermissions={['can_author_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
      />
      <MakeEffectiveButton
        label="Make effective"
        requiredPermissions={['can_make_doc_effective']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <RetireButton
        label="Retire"
        requiredPermissions={['can_retire_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
      <ChangeOwnerButton
        label="Change owner"
        requiredPermissions={['can_change_doc_owner']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        refetch={props.refetch}
        selectedDocuments={selectedDocuments}
        tags={props.tags}
      />
      <AssignTraineesButton
        requiredPermissions={['can_author_doc']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        tags={props.tags}
      />
      <ManageTagsButton
        requiredPermissions={['can_manage_tags']}
        currentUser={props.currentUser}
        currentTab={props.currentTab}
        statuses={selectedStatuses}
        selectedDocuments={selectedDocuments}
        tags={props.tags}
        refetch={props.refetch}
        setSelectedDocumentsMap={props.setSelectedDocumentsMap}
      />
    </QStack>
  );
};
