import axios, { AxiosInstance } from 'axios';
import { QualioDocumentSection } from '../../../api/model/document';
import { ChangeControlDTO } from '../../../types/DocumentCreate';

export const aiApiClient: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? 'https://ai.app.qualio.com'
      : 'https://ai.staging.qualio.com',
});

export const aiApi = {
  async generateCC(
    title: string,
    currentDocument: QualioDocumentSection[],
    previousDocument: QualioDocumentSection[],
    currentCC: ChangeControlDTO,
  ): Promise<any> {
    const partialDocument = (section: QualioDocumentSection) => ({
      title: section.title,
      content: section.content,
      position: section.position,
      section_attachments: section.section_attachments,
    });
    const currentDocumentSections = currentDocument.map(partialDocument);
    const previousDocumentSections = previousDocument.map(partialDocument);
    return aiApiClient
      .post<any>(`/change-control`, {
        currentDocument: { sections: currentDocumentSections },
        previousDocument: { sections: previousDocumentSections },
        currentCC: { sections: currentCC.sections },
        title,
      })
      .then((res) => res.data);
  },

  async generateTrainingAssessment(
    title: string,
    document: QualioDocumentSection[],
  ): Promise<string> {
    return aiApiClient
      .post<string>(`/training-assessment`, {
        document: { sections: document },
        title,
      })
      .then((res) => res.data);
  },
};
