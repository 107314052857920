import {
  QMenuButton,
  QMenuItem,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { backendTaskApi } from '../../../../api/backendTask';
import { ExportMethodType } from '../../DocumentWorkspace/types';
import { exportViaMedtechTask } from '../export';
import { ExportFormat } from './types';

const exportFormats = Object.values(ExportFormat);

export const ExportDocumentListButton: React.FC<{
  currentUser: CurrentUser;
  tagsFilter: string[];
  isDisabled: boolean;
  searchQuery: string | null;
  sortingValue: string | null | undefined;
  statusFilters?: string[] | null | undefined;
  exportTaskMethod?: ExportMethodType;
}> = ({
  currentUser,
  tagsFilter,
  isDisabled,
  searchQuery,
  sortingValue,
  statusFilters,
  exportTaskMethod,
}) => {
  const { showToast } = useToastProvider();
  const toastCommonProps = {
    replace: true,
  };
  const onWaitingForExport = (taskId: string) => {
    showToast({
      id: `export-document-list-toast-${taskId}`,
      status: 'info',
      description: 'Export in progress. You can use Qualio while you wait.',
      duration: 70000,
      ...toastCommonProps,
    });
  };

  const onSuccess = (taskId: string, downloadLink: string) => {
    showToast({
      id: `export-document-list-toast-${taskId}`,
      status: 'success',
      description: buildToastDescription(downloadLink),
      duration: 70000,
      ...toastCommonProps,
    });
  };

  const buildToastDescription = (downloadLink: string) => {
    return (
      <>
        Export ready. Click{' '}
        <a target="_blank" rel="noreferrer" href={downloadLink}>
          here
        </a>{' '}
        to download.
      </>
    );
  };

  const onFailure = (taskId: string) => {
    showToast({
      id: `export-document-list-toast-${taskId}`,
      status: 'error',
      description: 'Export failed - please try again or contact support.',
      ...toastCommonProps,
    });
  };

  const handleExport = (format: ExportFormat) => {
    if (exportTaskMethod === undefined) {
      exportTaskMethod = backendTaskApi.startAllDocumentsExportTask;
    }

    void exportViaMedtechTask(
      currentUser,
      format,
      tagsFilter,
      searchQuery,
      onWaitingForExport,
      onSuccess,
      onFailure,
      exportTaskMethod,
      sortingValue,
      statusFilters,
    );
  };

  return (
    <QMenuButton
      isDisabled={isDisabled}
      data-cy="exportButton"
      buttonLabel="Export"
      variant={'outline'}
    >
      {exportFormats.map((exportFormat) => (
        <QMenuItem
          key={exportFormat}
          data-testid={`exportFormat${exportFormat.toUpperCase()}`}
          data-cy={`exportFormat${exportFormat.toUpperCase()}Button`}
          onClick={() => handleExport(exportFormat)}
          fontSize={'sm'}
        >
          {exportFormat.toUpperCase()}
        </QMenuItem>
      ))}
    </QMenuButton>
  );
};
