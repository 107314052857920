import { useToastProvider } from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QualioDocument } from '../../../../../../api/model/document';
import { Tag } from '../../../../../../api/model/tag';
import { ManageDocumentTagsModal } from '../../../../ManageDocumentTagsModal/index';
import { hasRequiredPermissionsForAction } from '../../BulkActions';
import {
  BulkActionButton,
  BulkActionButtonProps,
} from '../BulkActionButton/index';

type ManageTagsButtonProps = Omit<
  BulkActionButtonProps,
  'isActionAllowed' | 'label' | 'onClick'
> & {
  selectedDocuments: QualioDocument[];
  tags: Tag[];
  refetch: () => void;
  setSelectedDocumentsMap: (
    selectedDocuments: Map<QualioDocument['id'], QualioDocument>,
  ) => void;
};

export const ManageTagsButton = (props: ManageTagsButtonProps) => {
  const { selectedDocuments, tags, setSelectedDocumentsMap } = props;
  const { showToast } = useToastProvider();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const commonToastProps = {
    id: 'workspace-manage-tags-toast',
    replace: true,
  };

  const tagsFilter = useMemo(() => {
    const value = searchParams.get('filteringTag');
    if (value) {
      return value.split(',').map((id) => +id);
    }
    return [];
  }, [searchParams]);

  const { modalDescription, successToastDescription } = useMemo(() => {
    const documentCount = selectedDocuments.length;
    const documentsText = documentCount > 1 ? 'documents' : 'document';
    return {
      modalDescription: `Manage tags for ${documentCount} ${documentsText}.`,
      successToastDescription: `Successfully applied changes to tags on the selected ${documentsText}.`,
    };
  }, [selectedDocuments]);

  const canManageTags: BulkActionButtonProps['isActionAllowed'] = (
    user,
    statuses,
    _activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !statuses.length
    ) {
      return false;
    }
    return true;
  };

  const onSuccessCallback = ({ removedTags }: { removedTags: number[] }) => {
    if (removedTags.some((id) => tagsFilter.includes(id))) {
      setSelectedDocumentsMap(new Map());
    }
    showToast({
      ...commonToastProps,
      status: 'success',
      title: 'Tags updated!',
      description: successToastDescription,
    });
    props.refetch();
  };

  const onErrorCallback = () => {
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Error',
      description: 'Failed to apply changes. Please try again.',
    });
  };

  return (
    <>
      <BulkActionButton
        {...props}
        label="Manage tags"
        isActionAllowed={canManageTags}
        onClick={() => setIsModalOpen(true)}
      />
      <ManageDocumentTagsModal
        onClose={() => setIsModalOpen(false)}
        selectedDocuments={selectedDocuments}
        tags={tags}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        modalDescription={modalDescription}
        onSuccess={onSuccessCallback}
        onError={onErrorCallback}
      />
    </>
  );
};
