import { QBox } from '@qualio/ui-components';
import React from 'react';
import { TrainingAssessmentEmptyState } from './TrainingAssessmentEmptyState';
import { TrainingAssessmentQuestionCard } from './TrainingAssessmentQuestionCard';
import { TrainingAssessment as TrainingAssessmentType } from './types/trainingAssessment';

type TrainingAssessmentProps = {
  questions?: TrainingAssessmentType['questions'];
};

export const TrainingAssessment: React.VFC<TrainingAssessmentProps> = ({
  questions,
}) => {
  if (!questions || questions.length === 0) {
    return <TrainingAssessmentEmptyState />;
  }

  return (
    <QBox marginTop="16px" data-testid="training-assessment-layout">
      {questions?.map(({ question, answers, id }, index) => (
        <TrainingAssessmentQuestionCard
          key={id}
          questionLabel={`Question ${index + 1}`}
          question={question}
          answers={answers}
        />
      ))}
    </QBox>
  );
};
