import {
  QButton,
  QFormControl,
  QHeading,
  QIconButton,
  QInput,
  QRadio,
  QStack,
  QText,
} from '@qualio/ui-components';
import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type TrainingAssessmentFormQuestionProps = {
  index: number;
};

export const TrainingAssessmentFormQuestion: React.VFC<
  TrainingAssessmentFormQuestionProps
> = ({ index }) => {
  const { setValue } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    name: `questions.${index}.answers`,
  });

  const setCorrectAnswer = useCallback(
    (correctIndex: number) => {
      for (let item = 0; item < fields.length; item++) {
        setValue(
          `questions.${index}.answers.${item}.is_correct`,
          item === correctIndex,
        );
      }
    },
    [fields, setValue, index],
  );

  return (
    <QStack gap={3} data-cy="training-assessment-form-question">
      <QHeading size="sm" color="gray.900">
        Question {index + 1}
      </QHeading>
      <QFormControl label="Question">
        <Controller
          render={({ field: { onChange, value } }) => (
            <QInput
              onChange={onChange}
              value={value}
              placeholder="Enter question..."
            />
          )}
          name={`questions.${index}.question`}
        />

        <QStack gap={1} marginTop="16px">
          <QHeading size="xs" color="gray.700">
            Options
          </QHeading>
          <QText fontSize="xs" color="gray.500">
            Please choose the correct answer by selecting the corresponding
            radio button.
          </QText>
          <QStack direction="column" gap="2">
            {fields.map((field, answerIndex) => (
              <QStack
                direction="row"
                key={field.id}
                data-testid="training-assessment-answers"
              >
                <Controller
                  name={`questions.${index}.answers.${answerIndex}.is_correct`}
                  render={({ field: { value } }) => (
                    <QRadio
                      onChange={() => setCorrectAnswer(answerIndex)}
                      isChecked={value}
                    />
                  )}
                />
                <Controller
                  name={`questions.${index}.answers.${answerIndex}.answer`}
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      onChange={onChange}
                      value={value}
                      placeholder={`Option ${answerIndex + 1}...`}
                    />
                  )}
                />
                <QIconButton
                  iconName="ArrowUp"
                  aria-label="Move Up"
                  isDisabled={answerIndex === 0}
                  onClick={() => move(answerIndex, answerIndex - 1)}
                />
                <QIconButton
                  iconName="ArrowDown"
                  aria-label="Move Down"
                  isDisabled={answerIndex === fields.length - 1}
                  onClick={() => move(answerIndex, answerIndex + 1)}
                />
                <QIconButton
                  iconName="Trash"
                  aria-label="Remove"
                  data-testid="remove-answer"
                  isDisabled={fields.length <= 2}
                  onClick={() => remove(answerIndex)}
                />
              </QStack>
            ))}
            <QButton
              variant="ghost"
              leftIcon="Plus"
              data-testid="add-answer"
              onClick={() => append({ answer: '', is_correct: false })}
            >
              Add option
            </QButton>
          </QStack>
        </QStack>
      </QFormControl>
    </QStack>
  );
};
