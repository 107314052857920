import { useEffect, useMemo, useRef, useState } from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack5';

export interface PDFPageProps {
  width: number | undefined;
  height: number;
  isPaginated: boolean;
  zoom: number;
  pageNumber: number;
  pageCount: number;
}

export const PDFPage = ({
  width,
  height,
  isPaginated,
  pageNumber,
  pageCount,
  zoom,
}: PDFPageProps) => {
  const [isRendered, setIsRrendered] = useState<boolean>(false);
  const canvasRef = useRef<HTMLElement>();

  const handleRenderSucces = () => {
    setIsRrendered(true);
  };

  useEffect(() => {
    if (canvasRef.current) {
      const documentElement = document.querySelector(
        '.react-pdf__Document',
      ) as HTMLElement;
      if (documentElement) {
        documentElement.style.height = `${canvasRef.current.clientHeight}px`;
      }
    }
  }, [isRendered]);

  useMemo(() => {
    const documentElement = document.querySelector(
      '.react-pdf__Document',
    ) as HTMLElement;
    documentElement.style.height = '';
    // eslint-disable-next-line
  }, [isPaginated]);

  if (!isPaginated) {
    return (
      <div style={{ maxWidth: '100%', transform: `scale(${zoom})` }}>
        <>
          {Array.from(Array(pageCount)).map((_, idx) => (
            <Page
              key={`page-${idx}`}
              pageNumber={idx + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={width}
              height={height}
            />
          ))}
        </>
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: '100%',
        transform: `scale(${zoom})`,
      }}
    >
      <Page
        // eslint-disable-next-line
        // @ts-ignore
        canvasRef={canvasRef}
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        onRenderSuccess={handleRenderSucces}
        width={width}
        scale={zoom}
      />
    </div>
  );
};
