import React from 'react';

export interface MedtechToolbarToggler {
  enableButtons: () => void;
  disableButtons: () => void;
}

let originalTextContent: string | null;
let hasMoreChanges = 0;

const enableTabs = () => {
  const tabs = document.querySelectorAll('#documentContentTab ul li');
  tabs.forEach((tab) => {
    if (!tab.classList.contains('active')) {
      // eslint-disable-next-line
      // @ts-ignore
      tab.style.cssText = '';
    }
  });
};

const disableTabs = () => {
  const tabs = document.querySelectorAll('#documentContentTab ul li');
  tabs.forEach((tab) => {
    if (!tab.classList.contains('active')) {
      const styles =
        'cursor: not-allowed; pointer-events: none; opacity: 0.65; filter: alpha(opacity=65);';
      // eslint-disable-next-line
      // @ts-ignore
      tab.style.cssText = styles;
    }
  });
};

const setSavingMode = (buttons: NodeListOf<Element>) => {
  buttons.forEach((button) => {
    button.setAttribute('disabled', '');
    if (button.id === 'documentModeToggle') {
      const icon = button.querySelector('i');
      const buttonText = button.childNodes[0];
      originalTextContent =
        buttonText.textContent === 'Saving '
          ? originalTextContent
          : buttonText.textContent;
      if (buttonText.textContent === 'Saving ') {
        hasMoreChanges += 1;
      } else {
        icon?.classList.remove('ng-hide');
        buttonText.textContent = 'Saving ';
        disableTabs();
      }
    }
  });
};

const unsetSavingMode = (buttons: NodeListOf<Element>) => {
  buttons.forEach((button) => {
    if (hasMoreChanges) {
      hasMoreChanges -= 1;
    } else {
      button.removeAttribute('disabled');
      if (button.id === 'documentModeToggle') {
        const icon = button.querySelector('i');
        const buttonText = button.childNodes[0];
        buttonText.textContent = originalTextContent;
        icon?.classList.add('ng-hide');
        enableTabs();
      }
    }
  });
};

export function useMedtechToolbarToggler(): MedtechToolbarToggler {
  const findButtons = React.useCallback(
    () => document.querySelectorAll('.document-menu-wrapper button'),
    [],
  );
  const enableButtons = React.useCallback(
    () => unsetSavingMode(findButtons()),
    [findButtons],
  );
  const disableButtons = React.useCallback(
    () => setSavingMode(findButtons()),
    [findButtons],
  );
  return { enableButtons, disableButtons };
}
