import { useEffect, useMemo, useState } from 'react';

import { Context } from '@ckeditor/ckeditor5-core';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { saveAs } from 'file-saver';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  documentApi,
  QualioAttachmentInfoResponse,
  QualioAttachmentPreviewType,
} from '../../../api/document';
import { QualioDocument } from '../../../api/model/document';
import { qualioAttachmentService } from '../../../api/qualioAttachmentService';
import {
  API_FETCH_ATTACHMENT_BLOB_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import {
  analyticsHandler,
  FileUploadEvents,
} from '../../../util/Analytics/AnalyticsUtils';
import {
  setElementDisabledState,
  toggleDisableClass,
  toggleFakeLinkDisableClass,
} from '../../../util/HTMLElementUtils';
import { printFile } from '../../../util/PrintFile';
import { refreshDisplayMode } from '../../../util/SidebarUtils';
import {
  getAttachmentPreviewUrl,
  getAttachmentUrl,
} from '../../../util/UrlUtils';
import { FileDocumentCommentsWrapper } from './FileDocumentCommentsWrapper';
import { FileDocumentLoadingIndicator } from './FileDocumentLoadingIndicator';
import styles from './FileDocumentPreviewer.module.css';
import { ImageDocumentViewer } from './ImageDocumentViewer';
import { PDFDocumentViewer } from './PDFDocumentViewer';

export const UNSUPPORTED_PREVIEW_TEXT =
  'Preview for this file type is not supported.';

const attachmentConversionSuccessful = (
  attachmentConversionStatus: QualioAttachmentInfoResponse,
): boolean =>
  attachmentConversionStatus &&
  qualioAttachmentService.attachmentPreviewSuccessful(
    attachmentConversionStatus,
  );

export const FileDocumentPreviewer: React.FC<{
  currentUser: CurrentUser;
  attachmentId: string;
  attachmentFilename?: string;
  showDownloadButton: boolean;
  collaborationToken?: string;
  commentsPermission?: number;
  fileDocument?: QualioDocument;
  isInlineAttachment?: boolean;
  analytics?: SegmentAnalytics.AnalyticsJS;
}> = ({
  currentUser,
  attachmentId,
  attachmentFilename = '',
  showDownloadButton,
  collaborationToken,
  commentsPermission = 0,
  fileDocument,
  isInlineAttachment,
  analytics,
}) => {
  const [previewReady, setPreviewReady] = useState<boolean>(false);
  const [previewError, setPreviewError] = useState<boolean>(false);
  const [context, setContext] = useState<Context>();
  const [previewType, setPreviewType] = useState<QualioAttachmentPreviewType>();
  const [mimeType, setMimeType] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const { qualioFrontendRefresh } = useFlags();

  const attachmentPreviewUrl = useMemo(() => {
    return getAttachmentPreviewUrl(attachmentId);
    // eslint-disable-next-line
  }, [attachmentId, previewReady, previewError]);

  const attachmentUrl = useMemo(() => {
    return getAttachmentUrl(attachmentId);
  }, [attachmentId]);

  const downloadAttachmentUrl = useMemo(
    () => getAttachmentUrl(attachmentId, true),
    [attachmentId],
  );

  useEffect(() => {
    const awaitConversionResults = async () => {
      if (attachmentId) {
        setPreviewReady(false);
        setPreviewError(false);
        const conversionResult =
          await qualioAttachmentService.pollAttachmentInfoUntilCompletion(
            attachmentId,
          );
        if (
          conversionResult &&
          attachmentConversionSuccessful(conversionResult)
        ) {
          setPreviewReady(true);
          if (conversionResult.preview_type) {
            setPreviewType(conversionResult.preview_type);
          }
          setMimeType(
            qualioAttachmentService.determinePreviewContentMimeType(
              conversionResult.filename,
            ),
          );
          setFilename(conversionResult.filename);
        } else {
          setPreviewError(true);
        }
      }
    };

    void awaitConversionResults();
  }, [attachmentId]);

  const handleDownload = () => {
    if (!isInlineAttachment && analytics) {
      analyticsHandler(FileUploadEvents.Download, analytics);
    }
    documentApi
      .fetchAttachmentDataAsBlob(attachmentId, mimeType)
      .then((res) => {
        if (res && filename) {
          saveAs(res, filename);
        }
      })
      .catch((error) => {
        logCustomError(API_FETCH_ATTACHMENT_BLOB_ERROR, {
          error,
          entityId: +attachmentId,
        });
      });
  };

  const handlePrint = () => {
    void printFile(attachmentPreviewUrl, previewType);
  };

  const exportButton = document.getElementById('documentEditorExport');
  const convertButton = document.getElementById('documentConversion');

  const showComments =
    commentsPermission > 0 &&
    collaborationToken &&
    fileDocument?.is_most_recent_version;

  if (previewReady) {
    toggleDisableClass(exportButton, false);
    setElementDisabledState(convertButton, false);
    toggleFakeLinkDisableClass(convertButton, false);
  }

  const resizeOnZoom = (zoom: number) => {
    refreshDisplayMode({
      instances: context ? [context] : [],
      windowWidth: window.innerWidth,
      forceNarrow: zoom > 1.1,
      breakpointOverride: qualioFrontendRefresh ? 1800 : undefined,
    });
  };

  return (
    <>
      {!previewError && !previewReady && <FileDocumentLoadingIndicator />}
      {previewError && (
        <p
          id={'errorMessage'}
          data-testid="previewError"
          className={styles['file-preview-info-text']}
        >
          {UNSUPPORTED_PREVIEW_TEXT}
          <br />
          You can still open the file by clicking{' '}
          <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>
            here
          </a>{' '}
          or <a href={downloadAttachmentUrl}>download</a> it.
        </p>
      )}
      {previewReady && !showComments && (
        <div id={'fileDocumentPreviewer'}>
          {previewType === 'document' && (
            <PDFDocumentViewer
              attachmentPreviewUrl={attachmentPreviewUrl}
              handleDownload={handleDownload}
              showDownloadButton={showDownloadButton}
              commentsPermission={0}
              handlePrint={isInlineAttachment ? handlePrint : undefined}
            />
          )}
          {previewType === 'image' && (
            <ImageDocumentViewer
              attachmentPreviewUrl={attachmentPreviewUrl}
              handleDownload={handleDownload}
              showDownloadButton={showDownloadButton}
              commentsPermission={0}
              handlePrint={isInlineAttachment ? handlePrint : undefined}
            />
          )}
        </div>
      )}
      {previewReady && showComments && (
        <div
          id={'fileDocumentPreviewer'}
          className={styles['file-document-previewer__wrapper-sidebar']}
        >
          <FileDocumentCommentsWrapper
            currentUser={currentUser}
            attachmentFilename={attachmentFilename}
            attachmentId={attachmentId}
            fileDocument={fileDocument}
            collaborationToken={collaborationToken}
            commentsPermission={commentsPermission}
            contextHandler={setContext}
          >
            {previewType === 'document' && (
              <PDFDocumentViewer
                attachmentPreviewUrl={attachmentPreviewUrl}
                handleDownload={handleDownload}
                showDownloadButton={showDownloadButton}
                commentsPermission={commentsPermission}
                handleZoomChange={resizeOnZoom}
                renderFileDocumentLoadingIndicator={false}
              />
            )}
            {previewType === 'image' && (
              <ImageDocumentViewer
                attachmentPreviewUrl={attachmentPreviewUrl}
                handleDownload={handleDownload}
                showDownloadButton={showDownloadButton}
                commentsPermission={commentsPermission}
                handleZoomChange={resizeOnZoom}
              />
            )}
          </FileDocumentCommentsWrapper>
        </div>
      )}
    </>
  );
};
