import { useContext } from 'react';

import { QButton, QIcon, QText, useCurrentUser } from '@qualio/ui-components';

import { EditorMode } from '../../../../../util/EditModeUtils';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';
import { shouldRenderSuggestButton } from '../../RenderLogic';

type EditButtonProps = {
  setEditorMode: (value: EditorMode) => void;
};

export const SuggestButton = ({ setEditorMode }: EditButtonProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled } = useContext(ButtonGroupContext);
  const { docEditorStatus, ccEditorStatus } = useContext(EditorStatusContext);

  if (
    !shouldRenderSuggestButton(qualioDocument, currentUser, currentEditorMode)
  ) {
    return null;
  }

  const isConnectingToEditingSession =
    docEditorStatus === EditorStatus.CONNECTING ||
    ccEditorStatus === EditorStatus.CONNECTING;

  return (
    <QButton
      isDisabled={isDisabled || isConnectingToEditingSession}
      onClick={() => setEditorMode(EditorMode.TRACKCHANGES)}
      aria-label="suggest-button"
      variant="outline"
      data-testid="document-suggest-button"
      data-cy="document-suggest-button"
    >
      <QIcon iconName={'MessageSquare'} /> <QText pl={1}>Suggest</QText>
    </QButton>
  );
};
