import { MutableRefObject, useMemo, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';

import { useElementSize } from '../../../hooks/ElementSize';
import { useZoomHandler } from '../../../hooks/ZoomHandler';
import { FileDocumentControls } from './FileDocumentControls';
import { FileDocumentLoadingIndicator } from './FileDocumentLoadingIndicator';
import { PDFPage } from './PDFPage';

import styles from './PDFDocumentViewer.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PDFDocumentViewer = ({
  attachmentPreviewUrl,
  showDownloadButton,
  handleDownload,
  handlePrint,
  commentsPermission,
  handleZoomChange,
  renderFileDocumentLoadingIndicator = true,
}: {
  attachmentPreviewUrl: string;
  showDownloadButton: boolean;
  handleDownload: () => void;
  handlePrint?: () => void;
  commentsPermission: number;
  renderFileDocumentLoadingIndicator?: boolean;
  handleZoomChange?: (zoom: number) => void;
}) => {
  const [documentPageCount, setDocumentPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [zoom, setZoom] = useState<number>(1.0);
  const [isPaginated, setIsPaginated] = useState(false);
  const wrapper = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const { width, height = 0 } = useElementSize(wrapper);
  const file = useMemo(
    () => ({
      url: attachmentPreviewUrl,
      withCredentials: true,
    }),
    [attachmentPreviewUrl],
  );

  const handleNavigate = (offset: number) => {
    setPageNumber((prevValue) => prevValue + offset);
  };

  const handleZoom = useZoomHandler(setZoom, handleZoomChange);

  const handlePagination = () => {
    setIsPaginated(!isPaginated);
  };

  const onLoadSuccess = (pdf: any) => {
    setDocumentPageCount(pdf.numPages);
  };

  return (
    <>
      <FileDocumentControls
        handleNavigate={handleNavigate}
        handleZoom={handleZoom}
        handleDownload={handleDownload}
        totalPages={documentPageCount}
        currentPage={pageNumber}
        handlePagination={handlePagination}
        isPaginated={isPaginated}
        zoom={zoom}
        showDownloadButton={showDownloadButton}
        handlePrint={handlePrint}
        showCommentButton={commentsPermission > 1}
      />
      <div
        id={'fileWrapper'}
        data-testid="pdfDocumentViewer"
        className={
          styles[
            commentsPermission > 0
              ? 'file-document__pdf-wrapper-comments'
              : 'file-document__pdf-wrapper'
          ]
        }
        ref={wrapper}
      >
        <Document
          className={styles['react-pdf-element']}
          file={file}
          onLoadSuccess={onLoadSuccess}
          loading={
            renderFileDocumentLoadingIndicator ? (
              <FileDocumentLoadingIndicator />
            ) : (
              ''
            )
          }
          error="Failed to load preview"
        >
          <PDFPage
            pageNumber={pageNumber}
            pageCount={documentPageCount}
            isPaginated={isPaginated}
            width={width}
            height={height}
            zoom={zoom}
          />
        </Document>
      </div>
    </>
  );
};
