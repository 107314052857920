import { medtechApiClient } from '../../../api';
import {
  ChangeControlAIResponse,
  QualioDocument,
} from '../../../api/model/document';
import { ChangeControlDTO } from '../../../types/DocumentCreate';
import { aiApi } from './api';

export const aiService = {
  async generateChangeControl(
    document: QualioDocument,
    currentCC: ChangeControlDTO,
  ) {
    const versionsResponse = await medtechApiClient.get<DocumentVersion[]>(
      `documents/${document.id}/versions`,
    );
    const versions = versionsResponse.data;

    const majorVersions = versions.filter((doc) => doc.minor === '0');
    majorVersions.sort((a, b) => parseInt(b.major) - parseInt(a.major));

    const lastMajorVersion = majorVersions[0];

    if (!lastMajorVersion) {
      throw new Error('no prior major version');
    }

    const documentResponse = await medtechApiClient.get<QualioDocument>(
      `documents/${lastMajorVersion.document_id}`,
    );
    const lastMajorVersionDocument = documentResponse.data;
    const aiChangeControl = await aiApi.generateCC(
      document.title,
      document.sections,
      lastMajorVersionDocument.sections,
      currentCC,
    );

    return ChangeControlAIResponse.parse(aiChangeControl);
  },

  async generateTrainingAssessment(document: QualioDocument) {
    const aiTrainingAssessment = await aiApi.generateTrainingAssessment(
      document.title,
      document.sections,
    );
    return aiTrainingAssessment;
  },
};

export type DocumentVersion = {
  version: string;
  major: string;
  minor: string;
  document_id: number;
  status_id: string;
};
