import { useContext, useState } from 'react';

import { QButton, useCurrentUser } from '@qualio/ui-components';

import { PeriodicReview } from '../../../../../api/model/periodicReview';
import { MedtechUserV2 } from '../../../../../api/user';

import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';

import { NextVersionDetails } from '../../../../../api/model/document';
import { shouldRenderPeriodicReviewButton } from '../../RenderLogic';
import { PeriodicReviewModal } from './PeriodicReviewModal';

export const PeriodicReviewButton = ({
  periodicReview,
  users,
  refetchPeriodicReview,
  nextVersion,
}: {
  periodicReview?: PeriodicReview;
  users: MedtechUserV2[];
  refetchPeriodicReview: () => void;
  nextVersion?: NextVersionDetails;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentUser = useCurrentUser();
  const { qualioDocument, currentEditorMode, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);

  const submissionCallback = () => {
    refetchDocument();
    refetchPeriodicReview();
  };

  const handlePeriodicReview = () => {
    setIsModalOpen(true);
    setDisabled(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setDisabled(false);
  };

  if (
    !shouldRenderPeriodicReviewButton(
      qualioDocument,
      currentUser,
      currentEditorMode,
      nextVersion,
    )
  ) {
    return null;
  }

  const periodicReviewText = periodicReview
    ? 'Stop periodic review'
    : 'Start periodic review';

  const buttonVariant = periodicReview ? 'outline' : 'solid';

  return (
    <>
      <QButton
        onClick={handlePeriodicReview}
        variant={buttonVariant}
        isDisabled={isDisabled}
        data-testid="doc-overview-preiodic-review-button"
        data-cy="doc-overview-periodic-review-button"
      >
        {periodicReviewText}
      </QButton>
      <PeriodicReviewModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        periodicReview={periodicReview}
        currentUser={currentUser}
        qualioDocument={qualioDocument}
        users={users}
        submissionCallback={submissionCallback}
      />
    </>
  );
};
