import { QualioDocumentSection } from '../../../../api/model/document';
import { aiApiClient } from '../../AiPoc/api';
import { TrainingAssessmentAIResponse } from '../types/trainingAssessment';

export const TrainingAiAssessmentApi = {
  async generateTrainingAssessment(
    title: string,
    document: QualioDocumentSection[],
  ): Promise<TrainingAssessmentAIResponse> {
    const response = await aiApiClient.post(`/training-assessment`, {
      document: { sections: document },
      title,
    });

    return TrainingAssessmentAIResponse.parse(response.data);
  },
};
