import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useCallback, useContext, useMemo, useState } from 'react';
import { documentApi } from '../../../../../../api/document';
import {
  DocumentTrainee,
  DocumentVersion,
} from '../../../../../../api/model/document';
import { Group } from '../../../../../../api/model/group';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../../api/user';
import { DocumentOverviewContext } from '../../../Context';
import { shouldShowManageTraineesButton } from '../../../RenderLogic';
import {
  canUserAccessAtLeastOnePrivateTag,
  getDocumentTagIdsToGroupIdsMap,
} from '../../utils';
import { TraineesLookup } from '../TraineesLookup/index';

type ManageTraineesButtonProps = {
  versions?: DocumentVersion[];
  groups: Group[];
  users: MedtechUserV2[];
  tags: Tag[];
};
export const ManageTraineesButton = ({
  versions,
  groups,
  users,
  tags,
}: ManageTraineesButtonProps) => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );

  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const closeLookup = useCallback(() => {
    setIsLookupModalOpen(false);
  }, []);
  const canManageTrainees = shouldShowManageTraineesButton(
    qualioDocument,
    currentUser,
    versions,
  );

  const currentTraineesMap = useMemo(
    () =>
      qualioDocument.trainees.reduce(
        (acc: Map<DocumentTrainee['id'], DocumentTrainee>, trainee) => {
          if (!trainee.training_removed) {
            acc.set(trainee.id, trainee);
          }
          return acc;
        },
        new Map(),
      ),
    [qualioDocument.trainees],
  );

  const documentTagIdsToGroupIdsMap = useMemo(
    () => getDocumentTagIdsToGroupIdsMap(qualioDocument, tags),
    [qualioDocument, tags],
  );

  // Filter users to ones that can view the document
  const potentialTrainees = useMemo(
    () =>
      users.filter(
        (user) =>
          documentTagIdsToGroupIdsMap.size === 0 ||
          canUserAccessAtLeastOnePrivateTag(
            user,
            Array.from(documentTagIdsToGroupIdsMap.values()),
          ),
      ),
    [documentTagIdsToGroupIdsMap, users],
  );

  const isTraineeRowPreselected = (user: MedtechUserV2) =>
    currentTraineesMap.has(user.id);

  const isTraineeRowDisabled = (user: MedtechUserV2) => {
    const currentTrainee = currentTraineesMap.get(user.id);
    return Boolean(currentTrainee?.training_plan);
  };

  const updateTrainees = async (selections: readonly MedtechUserV2[]) => {
    const commonToastProps = {
      id: 'manage-trainees-overview-toast',
      replace: true,
    };
    try {
      await documentApi.updateDocumentTrainees(
        qualioDocument.id,
        selections.map(({ id }) => id),
      );
      showToast({
        ...commonToastProps,
        status: 'success',
        title: 'Trainees assigned!',
        description: `Successfully updated document trainees.`,
      });
      refetchDocument();
      closeLookup();
    } catch (error) {
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: 'Failed to apply changes to trainees. Please try again.',
      });
      // Re-throw error so that QLookup does not reset the selections
      throw error;
    }
  };

  if (!canManageTrainees) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy="document-manage-trainees-button"
        variant="ghost"
        onClick={() => setIsLookupModalOpen(true)}
      >
        Manage
      </QButton>
      <TraineesLookup
        title="Manage trainees"
        isOpen={isLookupModalOpen}
        onClose={closeLookup}
        trainees={potentialTrainees}
        groups={groups}
        updateTrainees={updateTrainees}
        isTraineePreselected={isTraineeRowPreselected}
        isTraineeDisabled={isTraineeRowDisabled}
      />
    </>
  );
};
