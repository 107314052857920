import { Filtering } from '@qualio/ui-components';
import { BulkActions, BulkActionsProps } from './BulkActions/BulkActions';

export type BulkActionsWrapperProps = Omit<BulkActionsProps, 'refetch'>;

export const BulkActionsWrapper = (props: BulkActionsWrapperProps) => {
  const filtering = Filtering.useFilteringMaybe();

  const handleRefetch = () => {
    filtering?.setSearchTerm(null, Date.now());
  };

  return <BulkActions {...props} refetch={handleRefetch} />;
};
