import {
  QCenter,
  QDrawer,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { documentApi } from '../../../api/document';
import { DocumentCommentHistoryCard } from './DocumentCommentHistoryCard/DocumentCommentHistoryCard';
import { parseFetchedCommentThreadsForDisplay } from './DocumentCommentHistoryUtil';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import {
  COMMENT_HISTORY_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { OverviewTabs } from '../DocumentOverview/DocumentOverview';
import styles from './DocumentCommentHistoryPanel.module.css';

type DocumentCommentHistoryPanelProps = {
  isOpen: boolean;
  onClose: any;
  companyId: number;
  documentId: string;
};

export const DocumentCommentHistoryPanel = ({
  isOpen,
  onClose,
  companyId,
  documentId,
}: DocumentCommentHistoryPanelProps) => {
  const { showToast } = useToastProvider();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? OverviewTabs.DOCUMENT;
  const { qualioFrontendRefresh } = useFlags();
  const title = qualioFrontendRefresh
    ? `Comment history - ${
        tab === OverviewTabs.CHANGE_CONTROL ? 'Change control' : 'Document'
      }`
    : 'Comment history';

  const loadComments = async (companyId: number, documentId: string) => {
    const context = tab === 'change-control' ? 'change-control' : 'document';
    return await documentApi
      .fetchCommentThreads(companyId, documentId, context)
      .then((fetchedThreads) =>
        parseFetchedCommentThreadsForDisplay(fetchedThreads),
      );
  };

  const { isFetching, data: commentThreads } = useQuery({
    queryKey: ['commentHistory', companyId, documentId],
    queryFn: () => loadComments(companyId, documentId),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-comment-history',
        title: 'Error',
        description: 'Could not retrieve comment history',
        status: 'error',
      });
      logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
    },
  });

  return (
    <QDrawer
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={
        <div
          className={styles['Document-Comment-History-Panel__drawer__title']}
          data-cy={'comment-history-title'}
        >
          {title}
        </div>
      }
    >
      <QStack fontSize="sm">
        {isFetching && (
          <QStack
            height="100%"
            minHeight="60vh"
            justify="center"
            align="center"
          >
            <QSpinner />
            <QText data-testid="loading-comments-history">
              Loading comments...
            </QText>
          </QStack>
        )}
        {!isFetching && commentThreads?.length === 0 && (
          <QCenter
            data-testid="comments-history-empty-placeholder"
            height="100%"
          >
            There are no comments yet
          </QCenter>
        )}
        {!isFetching &&
          commentThreads?.map((commentThread: any) => (
            <DocumentCommentHistoryCard
              commentThread={commentThread}
              key={commentThread.id}
            />
          ))}
      </QStack>
    </QDrawer>
  );
};
