export const reload = () => {
  setTimeout(() => {
    window.location.reload();
  }, 5000);
};

export const redirect = () => {
  setTimeout(() => {
    window.location.replace('/');
  }, 5000);
};

export const redirectTo = (path: string) => {
  window.location.replace(path);
};
