import { Tooltip } from '@chakra-ui/react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  QualioDocument,
  QualioDocumentSection,
} from '../../../api/model/document';
import { CKEditorConfig } from '../../../config/CKEditorConfig';
import { getEditorConfig } from '../../../config/DocumentEditorConfig';
import { handleError } from '../../../config/handlers';
import { getQualioEditor } from '../../../editor/QualioEditor';
import {
  CKE_EDITOR_ERROR,
  logCustomError,
} from '../../../messages/LogErrorMessages';
import { genericApplicationErrorMessage } from '../../../messages/UserErrorMessages';
import { CKEditorInstance } from '../../../types/CKEditorInstance';
import { useErrorHandler } from '../ErrorHandler';

export interface DocumentSectionEditorProps {
  section: QualioDocumentSection;
  document: QualioDocument;
  companyId: number;
  handleAutoSave: (editor: CKEditorInstance) => Promise<void>;
  inEditMode: boolean;
  commentsPermission: number;
}

const determineTitleClasses = (
  showTitle: boolean,
  inEditMode: boolean,
): string => {
  let classes = 'section-heading';
  if (!inEditMode && !showTitle) {
    classes += ' section-heading__hide';
  }
  if (inEditMode && !showTitle) {
    classes += ' section-heading__placeholder';
  }
  return classes;
};

export const DocumentSectionEditor = ({
  section,
  document,
  companyId,
  handleAutoSave,
  inEditMode,
  commentsPermission,
}: DocumentSectionEditorProps) => {
  const { smartlinkEverything, multilevelListEnabled } = useFlags();

  const { updateHasError } = useErrorHandler();
  const config: CKEditorConfig = useMemo(() => {
    return getEditorConfig({
      collaborationEntityId: document.id.toString(),
      collaborationEntityName: 'document',
      sectionId: section.id,
      documentId: document.id,
      documentStatus: document.status,
      documentOwner: document.owner,
      companyId: companyId,
      handleAutoSave,
      commentsPermission,
      entityType: 'document.section',
      smartlinkEverythingEnabled: smartlinkEverything,
      multilevelListEnabled: multilevelListEnabled,
    });
    // eslint-disable-next-line
  }, [companyId, document, section, handleAutoSave, commentsPermission]);

  const showTitle = document.text_sections_titles_visible;
  const sectionTitle = showTitle
    ? `${section.position}. ${section.title}`
    : `Section ${section.position}`;
  const titleClasses = determineTitleClasses(showTitle, inEditMode);

  return (
    <div>
      <h1 className={titleClasses}>
        <span className="locked-section-headers">
          {sectionTitle}
          {!showTitle && (
            <Tooltip
              color="gray"
              placement="right"
              label="Only visible while editing"
            >
              <i
                data-testid="hidden-section-icon"
                className="fa fa-eye-slash"
              ></i>
            </Tooltip>
          )}
        </span>
      </h1>

      <CKEditor
        editor={getQualioEditor(smartlinkEverything)}
        config={config}
        data={section.content}
        onError={(e: any) => {
          logCustomError(CKE_EDITOR_ERROR, { error: e });
          handleError(genericApplicationErrorMessage);
          updateHasError(true);
        }}
      />
    </div>
  );
};
