import React from 'react';

const defaultStyle: React.CSSProperties = {
  margin: '24px',
};

export const LoadingSpinner: React.FC<{
  style?: React.CSSProperties;
  message?: string;
}> = ({ style, message }) => (
  <>
    <i
      aria-label="loading-spinner"
      data-testid={'loadingSpinner'}
      className="icon ion-loading-c"
      data-cy="doc-editor-loading-spinner"
      style={{ ...defaultStyle, ...style }}
    />
    {message}
  </>
);
