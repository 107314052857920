import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  isApprover,
  isOwner,
  isQualityUserInAccount,
  isReviewer,
} from '../../../util/CurrentUser';

import {
  QualioQThemeProvider,
  ToastProvider,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../../api/document';
import { QualioDocument } from '../../../api/model/document';
import { redirect } from '../../../util/PageUtils';
import { DocumentOverviewContext } from '../DocumentOverview/Context';

interface CollaborationSessionManagerProps {
  didReceiveDisconnectionEvent: boolean;
}

type ChangeOptions = {
  isChangeOwner?: boolean;
  isChangeStatus?: boolean;
  isChangeFormat?: boolean;
  isChangeTemplateVersion?: boolean;
};

export enum ChangeAction {
  Redirect = 'redirect',
  Reload = 'reload',
}

export const CollabServerConnectionManagerV2 = (
  props: CollaborationSessionManagerProps,
) => (
  <QualioQThemeProvider>
    <ToastProvider enableDelegation={true}>
      <ToastProviderChildren {...props} />
    </ToastProvider>
  </QualioQThemeProvider>
);

const ToastProviderChildren = ({
  didReceiveDisconnectionEvent,
}: CollaborationSessionManagerProps) => {
  const [sessionDisconnected, setSessionDisconnected] = useState(false);
  const { showToast } = useToastProvider();
  const currentUser = useCurrentUser();
  const { qualioDocument: currentDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const sessionDisconnectedCallback = () => {
    setSessionDisconnected(true);
  };

  useEffect(() => {
    if (didReceiveDisconnectionEvent) {
      sessionDisconnectedCallback();
    }
  }, [didReceiveDisconnectionEvent]);

  const actOnSessionDisconnectionEvent = (fetchedDocument: QualioDocument) => {
    const isChangeOwner =
      fetchedDocument && currentDocument.owner.id !== fetchedDocument.owner.id;
    const isChangeStatus =
      fetchedDocument &&
      currentDocument.status_id !== fetchedDocument.status_id;
    const isChangeFormat =
      fetchedDocument &&
      currentDocument.document_format !== fetchedDocument.document_format;
    const isChangeTemplateVersion =
      fetchedDocument &&
      currentDocument.template_id !== fetchedDocument.template_id;
    if (
      isChangeStatus ||
      isChangeOwner ||
      isChangeFormat ||
      isChangeTemplateVersion
    ) {
      const change = determineChange({
        isChangeOwner,
        isChangeStatus,
        isChangeFormat,
        isChangeTemplateVersion,
      });
      doDocumentChangeAction(fetchedDocument, currentUser, change);
    }
  };

  const doDocumentChangeAction = (
    newDocument: QualioDocument,
    currentUser: CurrentUser,
    change: string,
  ) => {
    const changeAction = determineChangeAction(newDocument, currentUser);
    if (changeAction === ChangeAction.Reload) {
      showToast({
        title: `${capitalise(change)} changed`,
        description: `The ${change} of this document has changed. This page will reload to show the updated state.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      setTimeout(() => {
        refetchDocument();
      }, 5000);
    } else if (changeAction === ChangeAction.Redirect) {
      showToast({
        title: `${capitalise(change)} changed`,
        description: `The ${change} of this document has changed. You will be redirected to the dashboard as you do not have permissions to access the latest version of this document.`,
        duration: 10000,
        status: 'warning',
        id: `warning-status-toast-1`,
        replace: true,
      });
      redirect();
    }
  };

  const getDocument = async () =>
    await documentApi.fetch(currentDocument.id.toString());

  useQuery({
    queryKey: ['newDocument'],
    queryFn: getDocument,
    enabled: sessionDisconnected,
    onSuccess: (fetchedDocument) =>
      actOnSessionDisconnectionEvent(fetchedDocument),
  });
  return <></>;
};

export const determineChangeAction = (
  document: QualioDocument,
  currentUser: CurrentUser,
): ChangeAction => {
  if (isQualityUserInAccount(currentUser)) {
    return ChangeAction.Reload;
  }
  if (isOwner(currentUser, document)) {
    return ChangeAction.Reload;
  }
  if (document.status_id === 'effective') {
    return ChangeAction.Reload;
  }
  if (
    isReviewer(currentUser, document) &&
    document.status_id === 'for_review'
  ) {
    return ChangeAction.Reload;
  }
  if (
    isApprover(currentUser, document) &&
    document.status_id === 'for_approval'
  ) {
    return ChangeAction.Reload;
  } else {
    return ChangeAction.Redirect;
  }
};

const determineChange = ({
  isChangeOwner,
  isChangeStatus,
  isChangeFormat,
  isChangeTemplateVersion,
}: ChangeOptions) => {
  if (isChangeOwner) {
    return 'owner';
  } else if (isChangeStatus) {
    return 'status';
  } else if (isChangeFormat) {
    return 'format';
  } else if (isChangeTemplateVersion) {
    return 'template version';
  } else {
    return '';
  }
};

const capitalise = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
