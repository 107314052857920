import { QStack, QText, useCurrentUser } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

type DisplayTimestampProps = {
  type: 'Date created' | 'Date closed' | 'Modified';
  date: number;
  userFullName?: string;
};

const formatDate = (
  timestamp: number | null,
  user_tz?: string,
  withTime = false,
): string => {
  if (!timestamp) return '';
  try {
    const date = new Date(timestamp * 1000);
    const dateFormat = withTime ? 'MMM d, yyyy hh:mm a' : 'MMM d, yyyy';
    return user_tz
      ? `${formatInTimeZone(date, user_tz, dateFormat)}`
      : `${format(date, dateFormat)}`;
  } catch (e: any) {
    return String(timestamp);
  }
};

export const DisplayTimestamp: React.FC<DisplayTimestampProps> = ({
  type,
  date,
  userFullName,
}) => {
  const user = useCurrentUser() as unknown as CurrentUser & { tz: string };

  const displayLabel = type === 'Modified' ? 'Last modified' : type;
  const displayDate = formatDate(date, user.tz, true);
  const displayUser = userFullName ? ` by ${userFullName}` : null;
  const dataCy = displayLabel.replace(/\s+/g, '-').toLowerCase();
  return (
    <QStack
      direction="row"
      alignItems="center"
      data-cy={`display-timestamp-${dataCy}`}
      data-testid={`display-timestamp-${dataCy}`}
    >
      <QText fontSize="xs" color="gray.800" weight="semibold">
        {displayLabel}
      </QText>
      <QText fontSize="xs" color="gray.500">
        {displayDate}
        {displayUser}
      </QText>
    </QStack>
  );
};
