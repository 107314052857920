import { useContext } from 'react';
import { useMutation } from 'react-query';
import { DocumentOverviewContext } from '../../DocumentOverview/Context';
import { TrainingAiAssessmentApi } from '../api/trainingAi';

export const useGenerateTrainingAssessment = () => {
  const { qualioDocument } = useContext(DocumentOverviewContext);

  return useMutation({
    mutationFn: () =>
      TrainingAiAssessmentApi.generateTrainingAssessment(
        qualioDocument.title,
        qualioDocument.sections,
      ),
  });
};
