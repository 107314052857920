import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import axios, { AxiosProgressEvent, CancelToken, HttpStatusCode } from 'axios';
import {
  API_ATTACHMENT_EXTERNAL_SOURCE_ERROR,
  API_ATTACHMENT_UPLOAD_ERROR,
  logCustomError,
} from '../messages/LogErrorMessages';
import { DocumentPropertiesDTO } from '../types/DocumentCreate';
import {
  ResolvedSmartLinkInformation,
  SmartLinksSuggest,
} from '../util/SmartLinkUtils';
import { ModifiedRemoteFileMetadata } from '../views/components/QFilePicker/index';
import { Question } from '../views/components/TrainingAssessment/types/trainingAssessment';
import { medtechApiClient } from './index';
import { ActivityLog } from './model/activityLog';
import {
  DocumentPreviewResponse,
  DocumentSectionRevision,
  DocumentStatus,
  DocumentTemplateUpgradeIdList,
  DocumentTemplateUpgradeInfo,
  DocumentVersion,
  NextVersionDetails,
  PreviewItems,
  QualioDocument,
  QualioDocumentAttachment,
  QualioDocumentPatch,
  QualioDocumentStatus,
  SmartLinkedDocumentSuggestion,
} from './model/document';
import {
  CreatePeriodicReviewRequest,
  PeriodicReview,
} from './model/periodicReview';
import { Tag } from './model/tag';
import { MedtechUserV2 } from './user';

export type FetchAllResult = { documents: QualioDocument[]; total: number };

export type DocumentApiQueryParams = {
  status_id?: string[] | null;
  query: string | null;
  tag_id: string[];
  offset?: number | undefined;
  limit?: number | undefined;
  max_age_days?: number;
  current_major_version_only?: boolean;
  include_pending_review?: boolean;
  include_pending_periodic_review?: boolean;
  order_by?: string | null | undefined;
};

export type UserCredentialsPayload = {
  email?: string;
  password?: string;
  comment?: string;
};

export type ChangeStatusPayload = UserCredentialsPayload & {
  status: QualioDocument['status'];
};

export type AssignTraineesToDocumentsResponse = {
  done: Pick<MedtechUserV2, 'email' | 'full_name' | 'id'> &
    { notified_about: number }[];
  suppressed: {
    id: QualioDocument['id'];
    code: QualioDocument['code'];
    suppressed: unknown[]; // Set to unknown because we do not care about the response for now
  }[];
};

export type ChangeStatusResponse = Pick<
  QualioDocument,
  | 'company_id'
  | 'id'
  | 'major_version'
  | 'minor_version'
  | 'status'
  | 'status_id'
  | 'title'
>;

interface DocumentApiOptions {
  params: DocumentApiQueryParams;
  paramsSerializer: {
    indexes: null;
  };
}

export type TrainingAssessmentValidation = {
  documentId: number;
  questions: Question[];
  id: number;
};

const buildActionableDocumentsStatusQueryParams = (statusFilters: string[]) => {
  return statusFilters.reduce(
    (acc: { [key: string]: boolean }, val: string) => {
      acc[val] = true;
      return acc;
    },
    {},
  );
};

export const getOptionsWithStatusFiltersForActionableDocs = (
  statusFilters: string[],
  options: DocumentApiOptions,
) => {
  options.params = {
    ...options.params,
    ...buildActionableDocumentsStatusQueryParams(statusFilters),
  };
  return options;
};

const getActionableDocsOptions = (
  searchQuery: string | null,
  tagIds: string[],
  orderBy: string | null | undefined,
  offset?: number | undefined,
  limit?: number | undefined,
  statusFilters?: string[] | null | undefined,
) => {
  const params: DocumentApiQueryParams = {
    offset,
    limit,
    query: searchQuery,
    tag_id: tagIds,
    order_by: orderBy,
  };

  const options = {
    params,
    paramsSerializer: {
      indexes: null,
    },
  };

  if (statusFilters === null || statusFilters === undefined) {
    return options;
  }

  return getOptionsWithStatusFiltersForActionableDocs(statusFilters, options);
};

interface StatusFilterParams {
  max_age_days?: number;
  current_major_version_only?: boolean;
  include_pending_review?: boolean;
  include_pending_periodic_review?: boolean;
  status_id?: string[] | null;
}

const isFilteringByDeletedStatusOnly = (statusFilters: string[] | null) => {
  return (
    statusFilters?.length === 1 && statusFilters[0] === DocumentStatus.Deleted
  );
};

export const getStatusFilterParamsForDocs = (
  statusFilters: string[] | null,
  current_major_version_only: boolean,
) => {
  const params: StatusFilterParams = {};
  if (isFilteringByDeletedStatusOnly(statusFilters)) {
    params.max_age_days = 90;
    if (current_major_version_only) {
      params.current_major_version_only = true;
    }
  }
  if (
    statusFilters?.length === 1 &&
    statusFilters[0] === DocumentStatus.For_Periodic_Review
  ) {
    params.include_pending_periodic_review = true;
  } else {
    params.status_id = statusFilters;
  }

  return params;
};

const getFetchDocsParamsObject = (
  statusFilters: string[] | null,
  offset: number,
  limit: number,
  searchQuery: string | null,
  tagIds: string[],
  orderBy: string | null | undefined,
  current_major_version_only = false,
) => {
  const params: DocumentApiQueryParams = {
    offset,
    limit,
    query: searchQuery,
    tag_id: tagIds,
    order_by: orderBy,
  };

  if (isFilteringByDeletedStatusOnly(statusFilters)) {
    params.query = null;
  }

  return {
    params: {
      ...params,
      ...getStatusFilterParamsForDocs(
        statusFilters,
        current_major_version_only,
      ),
    },
    paramsSerializer: {
      indexes: null,
    },
  };
};

export const documentApi = {
  async fetch(
    id: string,
    process_references = 1,
    include_export_data = 0,
  ): Promise<QualioDocument> {
    return medtechApiClient
      .get(
        `/documents/${id}?include_attachments=true&process_references=${process_references}&include_export_data=${include_export_data}`,
      )
      .then((res) => res.data);
  },
  async fetchAll(
    currentUser: CurrentUser,
    statusFilters: string[] | null,
    searchQuery: string | null,
    tagIds: string[],
    offset: number,
    limit: number,
    orderBy: string | null | undefined,
  ): Promise<FetchAllResult> {
    const exportTaskUrl = `v2/${currentUser.companyId}/documents`;
    const params = getFetchDocsParamsObject(
      statusFilters,
      offset,
      limit,
      searchQuery,
      tagIds,
      orderBy,
    );
    return medtechApiClient.get(exportTaskUrl, params).then((res) => res.data);
  },
  async fetchUserDocs(
    currentUser: CurrentUser,
    statusFilters: string[] | null,
    searchQuery: string | null,
    tagIds: string[],
    offset: number,
    limit: number,
    orderBy: string | null | undefined,
  ): Promise<FetchAllResult> {
    const current_major_version_only = true;
    const exportTaskUrl = `v2/${currentUser.companyId}/users/${currentUser.userId}/documents`;
    const params = getFetchDocsParamsObject(
      statusFilters,
      offset,
      limit,
      searchQuery,
      tagIds,
      orderBy,
      current_major_version_only,
    );
    return medtechApiClient.get(exportTaskUrl, params).then((res) => res.data);
  },
  async fetchUserActionableDocs(
    currentUser: CurrentUser,
    statusFilters: string[] | null,
    searchQuery: string | null,
    tagIds: string[],
    offset: number,
    limit: number,
    orderBy: string | null | undefined,
  ): Promise<FetchAllResult> {
    const options = getActionableDocsOptions(
      searchQuery,
      tagIds,
      orderBy,
      offset,
      limit,
      statusFilters,
    );

    return medtechApiClient
      .get(
        `v2/${currentUser.companyId}/users/${currentUser.userId}/actionable_documents`,
        options,
      )
      .then((res) => res.data);
  },
  async fetchUserTrainingDocs(
    currentUser: CurrentUser,
    statusFilters: string[] | null,
    searchQuery: string | null,
    tagIds: string[],
    offset?: number | undefined,
    limit?: number | undefined,
    orderBy?: string | null | undefined,
  ): Promise<FetchAllResult> {
    const options = getActionableDocsOptions(
      searchQuery,
      tagIds,
      orderBy,
      offset,
      limit,
      statusFilters,
    );
    return medtechApiClient
      .get(
        `v2/${currentUser.companyId}/users/${currentUser.userId}/training`,
        options,
      )
      .then((res) => res.data);
  },
  async fetchSpecificVersion(
    id: string,
    version: string,
  ): Promise<QualioDocument> {
    return medtechApiClient
      .get(
        `/documents/${id}/versions/${version}?include_sections=true&include_attachments=true&process_references=1`,
      )
      .then((res) => res.data);
  },
  async fetchNextVersion(
    documentId: number,
    companyId: number,
  ): Promise<NextVersionDetails> {
    return medtechApiClient
      .get(`v2/${companyId}/documents/${documentId}/next_version_details`)
      .then((res) => res.data);
  },
  async fetchByIdOrVersion(
    id: string,
    version: string,
    process_references = 1,
    include_export_data = 0,
  ): Promise<QualioDocument> {
    if (version) {
      return documentApi.fetchSpecificVersion(id, `${version}`);
    }
    return documentApi.fetch(id, process_references, include_export_data);
  },
  async fetchStatus(id: string): Promise<QualioDocumentStatus> {
    return medtechApiClient
      .get(`documents/${id}/status`)
      .then((res) => res.data);
  },
  async update(
    payload: QualioDocumentPatch,
    documentId: number,
    updateModifiedTime = true,
  ): Promise<void> {
    if (!updateModifiedTime) {
      return medtechApiClient.patch(
        `/documents/${documentId}?update_content_last_modified=0`,
        payload,
      );
    } else {
      return medtechApiClient.patch(`/documents/${documentId}`, payload);
    }
  },
  async fetchAttachmentToken(companyId: number): Promise<string> {
    return medtechApiClient
      .get(`/${companyId}/attachment-token`)
      .then((res) => res.data);
  },
  async fetchAttachmentInfo(id: string): Promise<QualioAttachmentInfoResponse> {
    return medtechApiClient
      .get(`/attachmentinfo/${id}`)
      .then((res) => res.data);
  },
  async fetchAttachmentDataAsBase64(id: string): Promise<string> {
    return medtechApiClient
      .get(`/attachmentdata/${id}`)
      .then((res) => res.data);
  },
  async fetchAttachmentDataAsBlob(id: string, mimeType: string): Promise<Blob> {
    return medtechApiClient
      .get(`/attachments/${id}`, { responseType: 'blob' })
      .then(
        (res) =>
          new Blob([res.data], {
            type: mimeType,
          }),
      );
  },

  async deleteDocument(documentId: number): Promise<void> {
    await medtechApiClient.delete(`/documents/${documentId}`);
  },
  async uploadAttachment(
    file: File,
    setProgress: (progress: AxiosProgressEvent) => void,
    cancelToken: CancelToken,
    formData?: { [key: string]: string },
    entityId?: number,
    entityType?: string,
  ): Promise<QualioDocumentAttachment | undefined> {
    const data = new FormData();
    data.append('file_1', file);
    if (formData) {
      for (const key in formData) {
        data.append(key, formData[key]);
      }
    }
    let httpResp: any;
    let uploadUrl = '/attachments';
    if (entityId && entityType) {
      uploadUrl += `?entity=${entityType}&entity_id=${entityId}`;
    }
    try {
      httpResp = await medtechApiClient.post(uploadUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: setProgress,
        cancelToken,
      });
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        logCustomError(API_ATTACHMENT_UPLOAD_ERROR, { error, entityId });
        throw error;
      }
    }
    return httpResp?.data;
  },
  async addExternalSourceFileAsAttachment(
    entityId: number,
    companyId: number,
    modifiedRemoteFileMetadata: ModifiedRemoteFileMetadata,
  ): Promise<QualioDocumentAttachment> {
    const addUrl = `${companyId}/attachment/from-external-source`;
    let httpResp: any;
    const data = {
      remote_file_metadata: {
        download_url: modifiedRemoteFileMetadata.downloadUrl,
        web_url: modifiedRemoteFileMetadata.webUrl,
        source: 'one_drive',
        presigned_url: modifiedRemoteFileMetadata.presignedUrl,
        filename: modifiedRemoteFileMetadata.filename,
        last_modified_at: modifiedRemoteFileMetadata.lastModifiedAt,
      },
      entity_id: entityId,
      entity: 'document.section',
    };
    try {
      httpResp = await medtechApiClient.post(addUrl, data);
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        logCustomError(API_ATTACHMENT_EXTERNAL_SOURCE_ERROR, {
          error,
          entityId,
        });
        throw error;
      }
    }
    return httpResp?.data;
  },
  async smartLinksSuggest(
    companyId: number,
    payload: SmartLinksSuggest,
  ): Promise<SmartLinkedDocumentSuggestion[]> {
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/smartlinks/suggest?search=${payload.search}`,
    );
    return httpResp.data;
  },
  async smartLinksResolve(
    companyId: number,
    codes: Set<string>,
  ): Promise<ResolvedSmartLinkInformation[]> {
    const codesAsQueryParam = '?codes=' + [...codes].join(',');
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/smartlinks/resolve-bulk${codesAsQueryParam}`,
    );
    return httpResp.data;
  },
  async smartLinksMatrixResolve(
    companyId: number,
    matrixIds: Set<string>,
  ): Promise<ResolvedSmartLinkInformation[]> {
    const matrixIdsAsQueryParam = '?ids=' + [...matrixIds].join(',');
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/smartlinks/resolve-bulk-matrix${matrixIdsAsQueryParam}`,
    );
    return httpResp.data;
  },
  async listCollaborationSessionsByDocument(
    companyId: number,
    documentId: number,
  ) {
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/collaboration/sessions/document/${documentId}`,
    );
    return httpResp.data;
  },
  async listCollaborationSessionsByCompany(companyId: number) {
    const httpResp: any = await medtechApiClient.get(
      `/v2/${companyId}/collaboration/sessions/company/`,
    );
    return httpResp.data;
  },
  async deleteDocumentCollaborationSession(
    companyId: number,
    documentId: number,
    force: boolean,
  ) {
    const httpResp: any = await medtechApiClient.delete(
      `/v2/${companyId}/collaboration/sessions/document/${documentId}?force=${force.toString()}`,
    );
    return httpResp.data;
  },
  async deleteCollaborationSession(
    companyId: number,
    collaborationSessionId: string,
    force: boolean,
  ) {
    const httpResp: any = await medtechApiClient.delete(
      `/v2/${companyId}/collaboration/sessions/id/${collaborationSessionId}?force=${force.toString()}`,
    );
    return httpResp.data;
  },
  async fetchCommentThreads(
    companyId: number,
    documentId: string,
    context?: 'document' | 'change-control',
  ): Promise<CommentsRepository.CommentThreadFetched[]> {
    const url = `/v2/${companyId}/documents/${documentId}/comment_threads`;
    let httpResp;
    if (context) {
      httpResp = await medtechApiClient.get(url, {
        params: { entity: context },
      });
    } else {
      httpResp = await medtechApiClient.get(url);
    }
    return httpResp.data;
  },
  async setDeletedByUserCommentThreads(
    companyId: number,
    documentId: string,
    threadId: string,
    channelId: string,
  ): Promise<any> {
    const payLoad = { channelId: channelId, action: 'add_deleted_by_user' };
    const httpResp = await medtechApiClient.patch(
      `/v2/${companyId}/documents/${documentId}/comment_threads/${threadId}`,
      payLoad,
    );

    return httpResp.data;
  },
  async updateSuggestionAttributes(
    companyId: number,
    documentId: string,
    suggestionId: string,
    channelId: string,
    attributes: Map<string, any>,
  ): Promise<any> {
    const payLoad = {
      channelId: channelId,
      attributes: Object.fromEntries(attributes),
      action: 'add_attributes',
    };
    const httpResp = await medtechApiClient.patch(
      `/v2/${companyId}/documents/${documentId}/suggestions/${suggestionId}`,
      payLoad,
    );

    return httpResp.data;
  },
  async convertToQualioDocument(doc: QualioDocument): Promise<any> {
    const { company_id, id } = doc;
    const httpResp = await medtechApiClient.post(
      `/v2/${company_id}/documents/${id}/convert_document_format`,
    );

    return httpResp.data;
  },
  async fetchNextAvailableDocumentCode(
    companyId: number,
    templateId: number,
  ): Promise<string> {
    const httpResp = await medtechApiClient.get<string>(
      `/v2/${companyId}/template/${templateId}/next_document_code`,
    );
    return httpResp.data;
  },
  async convertToFileDocument(
    companyId: number,
    documentId: number,
    attachmentId?: number,
  ) {
    const baseEndpoint = `v2/${companyId}/documents/${documentId}/convert_document_attachment`;
    const endpoint = attachmentId
      ? `${baseEndpoint}/${attachmentId}`
      : baseEndpoint;
    return medtechApiClient.post(endpoint);
  },
  async createDraftFromEffectiveDoc(
    companyId: number,
    documentMatrixId: string,
  ) {
    const payload = {
      company_id: companyId,
      document_matrix_id: documentMatrixId,
    };
    const httpResp = await medtechApiClient.post('/documents', payload);

    return httpResp.data;
  },
  async createDraft(companyId: number, document: DocumentPropertiesDTO) {
    const payload = {
      ...document,
      company_id: companyId,
    };
    const httpResp = await medtechApiClient.post('/documents', payload);
    return httpResp.data;
  },
  async changeStatus(
    documentId: number,
    payload: ChangeStatusPayload,
  ): Promise<ChangeStatusResponse> {
    return medtechApiClient
      .put(`/documents/${documentId}/change-status`, payload)
      .then(({ data }) => data);
  },
  async review(documentId: number): Promise<ChangeStatusResponse> {
    return medtechApiClient
      .put(`/documents/${documentId}/review`)
      .then(({ data }) => data);
  },
  async applyTagChangesToDocuments(
    documents: QualioDocument['id'][],
    addedTagIds: Tag['id'][],
    removedTagIds: Tag['id'][],
    force = false,
  ) {
    const payload = {
      documents,
      add_tags: addedTagIds,
      remove_tags: removedTagIds,
      force,
    };
    return medtechApiClient.post('documents/tags/', payload);
  },
  async assignTraineesToDocuments(
    currentUser: CurrentUser,
    documentIds: QualioDocument['id'][],
    userIds: MedtechUserV2['id'][],
  ) {
    return medtechApiClient
      .post<AssignTraineesToDocumentsResponse>(
        `/v2/${currentUser.companyId}/assign_training`,
        {
          document_ids: documentIds,
          user_ids: userIds,
        },
      )
      .then(({ data }) => data);
  },
  async getDocumentUpgradeInformation(
    companyId: number,
    documentId: number,
  ): Promise<DocumentTemplateUpgradeInfo> {
    return medtechApiClient
      .get<DocumentTemplateUpgradeInfo>(
        `/v2/${companyId}/documents/${documentId}/check_upgradable`,
      )
      .then(({ data }) => data);
  },
  async createDocumentUpgradePreview(
    companyId: number,
    documentId: number,
    target_template_id: number,
  ): Promise<PreviewItems> {
    return medtechApiClient
      .post<DocumentPreviewResponse>(
        `/v2/${companyId}/document_templates/upgrade_documents`,
        {
          documents_ids: [documentId],
          preview: true,
          target_template_id: target_template_id,
        },
      )
      .then(({ data }) => {
        const { preview_document_sections, ...rest } = data;
        return {
          preview_result_per_version_change: preview_document_sections,
          ...rest,
        };
      });
  },
  async upgradeDocument(
    companyId: number,
    documentId: number,
    target_template_id: number,
  ): Promise<QualioDocument['id'][]> {
    return medtechApiClient
      .post<DocumentTemplateUpgradeIdList>(
        `/v2/${companyId}/document_templates/upgrade_documents`,
        {
          documents_ids: [documentId],
          target_template_id: target_template_id,
        },
      )
      .then(({ data }) => data.updated_documents_ids);
  },
  updateDocumentTrainees(
    documentId: QualioDocument['id'],
    userIds: MedtechUserV2['id'][],
  ) {
    return medtechApiClient
      .patch(`/documents/${documentId}/training_group`, {
        users: userIds,
      })
      .then(({ data }) => data);
  },
  getPeriodicReviewByDocumentId(
    currentUser: CurrentUser,
    documentId: QualioDocument['id'],
  ) {
    return medtechApiClient
      .get<PeriodicReview>(
        `/v2/${currentUser.companyId}/documents/${documentId}/periodic_review`,
      )
      .then((res) => {
        if (res.status === HttpStatusCode.NoContent) {
          return undefined;
        } else {
          return res.data;
        }
      });
  },
  concludePeriodicReview(companyId: number, documentId: QualioDocument['id']) {
    return medtechApiClient
      .patch<PeriodicReview>(
        `/v2/${companyId}/documents/${documentId}/periodic_review_concluded`,
      )
      .then((res) => {
        return res.data;
      });
  },
  createPeriodicReview(
    currentUser: CurrentUser,
    documentId: QualioDocument['id'],
    data: CreatePeriodicReviewRequest,
  ) {
    return medtechApiClient
      .post<PeriodicReview>(
        `/v2/${currentUser.companyId}/documents/${documentId}/periodic_review`,
        data,
      )
      .then((res) => {
        return res.data;
      });
  },
  approvePeriodicReview(
    currentUser: CurrentUser,
    documentId: QualioDocument['id'],
    data: UserCredentialsPayload,
  ) {
    return medtechApiClient
      .patch<{ status: string }>(
        `/v2/${currentUser.companyId}/documents/${documentId}/periodic_review`,
        { ...data, approved: true },
      )
      .then(({ data }) => data);
  },
  declinePeriodicReview(
    currentUser: CurrentUser,
    documentId: QualioDocument['id'],
    data: UserCredentialsPayload,
  ) {
    return medtechApiClient
      .patch<{ status: string }>(
        `/v2/${currentUser.companyId}/documents/${documentId}/periodic_review`,
        { ...data, approved: false },
      )
      .then(({ data }) => data);
  },
  getDocumentVersionsByDocumentId(
    documentId: QualioDocument['id'],
    options: { include_minor?: boolean } = {},
  ) {
    const { include_minor = true } = options;
    return medtechApiClient
      .get<DocumentVersion[]>(`/documents/${documentId}/versions`, {
        params: {
          include_minor,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }) => data);
  },
  async undoDelete(id: string, version: string): Promise<QualioDocument> {
    const data = {
      id: version,
      status: 'draft',
    };

    return medtechApiClient
      .put(`/documents/${id}/versions/${version}`, data)
      .then((res) => res.data);
  },
  updateDocumentApprovers(
    companyId: number,
    documentId: QualioDocument['id'],
    data: {
      addedUsercompanyIds?: MedtechUserV2['usercompany_id'][];
      removedUsercompanyIds?: MedtechUserV2['usercompany_id'][];
      comment?: string;
    },
  ) {
    const {
      addedUsercompanyIds = [],
      removedUsercompanyIds = [],
      comment,
    } = data;
    return medtechApiClient
      .post<void>(`/v3/${companyId}/documents/${documentId}/manage-approvers`, {
        added_usercompany_ids: addedUsercompanyIds,
        removed_usercompany_ids: removedUsercompanyIds,
        comment,
      })
      .then(({ data }) => data);
  },
  async getDocumentRevisionsBetweenVersions(
    documentId: QualioDocument['id'],
    againstVersion: string,
    baseVersion: string,
  ) {
    return medtechApiClient
      .get<DocumentSectionRevision[]>(`/documents/${documentId}/revisions`, {
        params: {
          against: againstVersion,
          base: baseVersion,
          mode: 'contextual',
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }) => data);
  },
  async signOffTraining(
    documentId: QualioDocument['id'],
    email: string,
    password: string,
    assessment?: TrainingAssessmentValidation,
  ) {
    const payload: any = {
      email,
      password,
      ...assessment,
    };

    return medtechApiClient.put(`/documents/${documentId}/train`, payload);
  },

  async validateAssessment(
    documentId: QualioDocument['id'],
    assessmentToValidate: TrainingAssessmentValidation,
  ) {
    return medtechApiClient.put(
      `/documents/${documentId}/assessment/validate`,
      assessmentToValidate,
    );
  },

  async fetchActivityLogs(
    companyId: CurrentUser['companyId'],
    documentId: QualioDocument['id'],
  ): Promise<ActivityLog[]> {
    return medtechApiClient
      .get(`v2/${companyId}/documents/${documentId}/activity`)
      .then((res) => res.data.data);
  },
};

type QualioAttachmentPreviewState =
  | 'n/a'
  | 'waiting'
  | 'processing'
  | 'done'
  | 'failed';

export type QualioAttachmentPreviewType = 'document' | 'image' | 'unsupported';

export type QualioAttachmentInfoResponse = {
  id: number;
  filename: string;
  date_added: number;
  external_file_last_fetched_at: number | null;
  updated_at: number | null;
  preview_state: QualioAttachmentPreviewState | null;
  preview_type: QualioAttachmentPreviewType | null;
  external_file_download_url: QualioDocumentAttachment['external_file_download_url'];
  external_file_source_type: QualioDocumentAttachment['external_file_source_type'];
  external_file_url: QualioDocumentAttachment['external_file_url'];
};
