import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { documentApi } from '../../../../../../api/document';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../../api/user';
import { ChangeDocumentOwnerModal } from '../../../../../ChangeDocumentOwnerModal/ChangeDocumentOwnerModal';
import { ButtonGroupContext } from '../../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../../Context';
import {
  canUserAccessAtLeastOnePrivateTag,
  getDocumentTagIdsToGroupIdsMap,
} from '../../../DocumentProperties/utils';
import { shouldShowChangeOwnerButton } from '../../../RenderLogic';

type ChangeOwnerButtonProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
  users: MedtechUserV2[];
  tags: Tag[];
};

export const ChangeOwnerButton = ({
  renderClickableItem,
  users,
  tags,
}: ChangeOwnerButtonProps) => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );
  const { setDisabled } = useContext(ButtonGroupContext);
  const { showToast } = useToastProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const commonToastProps = {
    id: 'doc-overview-change-owner-toast',
    replace: true,
  };

  const canChangeOwner = useMemo(
    () => shouldShowChangeOwnerButton(qualioDocument, currentUser),
    [qualioDocument, currentUser],
  );

  const handleChangeOwner = (ownerId: number) => {
    return documentApi.update({ owner_id: ownerId }, qualioDocument.id);
  };

  const onSuccess = () => {
    showToast({
      ...commonToastProps,
      title: 'Owner changed!',
      status: 'success',
      description: 'Successfully changed the document owner.',
    });
    setDisabled(false);
    refetchDocument(false);
    setIsModalOpen(false);
  };

  const onError = () => {
    showToast({
      ...commonToastProps,
      title: 'Error',
      status: 'error',
      description: 'Failed to change the document owner. Please try again.',
    });
    setDisabled(false);
  };

  const documentTagIdsToGroupIdsMap = useMemo(
    () => getDocumentTagIdsToGroupIdsMap(qualioDocument, tags),
    [qualioDocument, tags],
  );

  const potentialOwners = useMemo(
    () =>
      users.filter(
        (user) =>
          documentTagIdsToGroupIdsMap.size === 0 ||
          canUserAccessAtLeastOnePrivateTag(
            user,
            Array.from(documentTagIdsToGroupIdsMap.values()),
          ),
      ),
    [documentTagIdsToGroupIdsMap, users],
  );

  if (!canChangeOwner) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => {
        setDisabled(true);
        setIsModalOpen(true);
      })}
      {isModalOpen && (
        <ChangeDocumentOwnerModal
          isModalOpen={true}
          modalDescription="Change owner for the document."
          users={potentialOwners}
          onClose={() => setDisabled(false, setIsModalOpen)}
          handleChangeOwner={handleChangeOwner}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </>
  );
};
