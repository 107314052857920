import { Avatar } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Parser } from 'html-to-react';
import React, { ReactElement, useMemo, useState } from 'react';

import {
  QBox,
  QCenter,
  QDivider,
  QLink,
  QStack,
  QText,
} from '@qualio/ui-components';
import {
  extractTextFromTargets,
  getCardColor,
} from '../DocumentCommentHistoryUtil';
import { CommentedOnSuggestionText } from './CommentedOnText/CommentedOnSuggestionText';
import styles from './DocumentCommentHistoryCard.module.css';

interface QualioCommentThreadToDisplay
  extends CommentsRepository.CommentThreadToDisplay {
  comments: any[];
}

const letterAmountToDisplay = 35;

const htmlToReactParser = new Parser();

function htmlToReact(str: string): ReactElement {
  return htmlToReactParser.parse(str);
}

function reformatDateString(dateStr?: string): string {
  if (dateStr) {
    return format(Date.parse(dateStr), 'PPp');
  }

  return '';
}

function displayPartialText(commentedOn: string) {
  if (commentedOn.length > letterAmountToDisplay) {
    return commentedOn.slice(0, letterAmountToDisplay) + '...';
  }

  return commentedOn;
}

const isOpenSuggestion = (commentThread: QualioCommentThreadToDisplay) => {
  const { status, comments, contentCommentedOn } = commentThread;

  return (
    status === 'Open' &&
    !contentCommentedOn &&
    comments.some((comment) => comment?.attributes?.suggestionId)
  );
};

type DocumentCommentHistoryCardProps = {
  commentThread: QualioCommentThreadToDisplay;
};

export const DocumentCommentHistoryCard: React.FC<
  DocumentCommentHistoryCardProps
> = ({ commentThread }) => {
  const [ifShowFullCommentedOn, setIfShowFullCommentedOn] = useState(false);

  const commentedOn = useMemo(() => {
    return extractTextFromTargets(commentThread.contentCommentedOn);
  }, [commentThread.contentCommentedOn]);

  const isResolved = useMemo(
    () =>
      commentThread.status === 'Accepted' ||
      commentThread.status === 'Rejected' ||
      commentThread.status === 'Resolved',
    [commentThread.status],
  );

  const resolvedBy = useMemo(() => {
    return commentThread.suggestionContent
      ? commentThread.lastUpdatedBy
      : commentThread.deletedByUser;
  }, [
    commentThread.suggestionContent,
    commentThread.deletedByUser,
    commentThread.lastUpdatedBy,
  ]);

  const resolvedAt = useMemo(() => {
    const resolvedAt = commentThread.suggestionContent
      ? commentThread.suggestionDeletedAt
      : commentThread.deletedAt;

    return reformatDateString(resolvedAt);
  }, [
    commentThread.suggestionContent,
    commentThread.suggestionDeletedAt,
    commentThread.deletedAt,
  ]);

  const resolvedText = useMemo(() => {
    return `${
      commentThread.status === 'Rejected' ? 'Discarded' : commentThread.status
    } this ${commentThread.suggestionContent ? 'suggestion' : 'comment'}.`;
  }, [commentThread.status, commentThread.suggestionContent]);

  const cardColor = getCardColor(commentThread);

  const cardClasses = `${styles['Document-Comment-History__Card']} ${
    styles[`Document-Comment-History__Card__color__${cardColor}`]
  } ${isResolved ? styles['Document-Comment-History__Card__resolved'] : ''}`;

  if (isOpenSuggestion(commentThread)) {
    return null;
  }

  return (
    <QCenter pb={6}>
      <QBox className={cardClasses} data-cy={'comment-history-thread'}>
        <QBox
          bg="gray.100"
          className={styles['Document-Comment-History__Card__thread-status']}
        >
          {!commentThread.suggestionContent && (
            <QBox display="flex" p={2}>
              {ifShowFullCommentedOn && (
                <QBox
                  display="flex"
                  data-cy="comment-history-thread-commented-on"
                >
                  <QText fontWeight="bold" whiteSpace="nowrap">
                    Commented on:&nbsp;
                  </QText>
                  <QText wordBreak="break-word">{commentedOn}&nbsp;</QText>
                  <span onClick={() => setIfShowFullCommentedOn(false)}>
                    <QLink data-cy="showLess">
                      <QText whiteSpace="nowrap">show less</QText>
                    </QLink>
                  </span>
                </QBox>
              )}
              {!ifShowFullCommentedOn && (
                <QBox
                  display="flex"
                  data-cy="comment-history-thread-commented-on-short"
                >
                  <QText fontWeight="bold" whiteSpace="nowrap">
                    Commented on:&nbsp;
                  </QText>
                  <QText wordBreak="break-word">
                    {displayPartialText(commentedOn)}
                  </QText>
                </QBox>
              )}
              {!ifShowFullCommentedOn &&
                commentedOn.length > letterAmountToDisplay && (
                  <QBox pl={1} onClick={() => setIfShowFullCommentedOn(true)}>
                    <QLink data-cy="showMore">
                      <QText whiteSpace="nowrap">show more</QText>
                    </QLink>
                  </QBox>
                )}
            </QBox>
          )}
          {commentThread.suggestionContent && (
            <CommentedOnSuggestionText
              commentThread={commentThread}
              letterAmountToDisplay={letterAmountToDisplay}
            />
          )}
        </QBox>
        {commentThread.suggestionContent && (
          <QBox display="flex" pt={2} pb={2}>
            <QText pl={2}>
              <b>{commentThread.authorFullName}</b>
            </QText>
            <QText pl={2}>
              {reformatDateString(commentThread.suggestionCreatedAt)}
            </QText>
          </QBox>
        )}
        {commentThread.comments.length > 0 && (
          <QCenter>
            <QDivider
              orientation="horizontal"
              color={'gray.200'}
              width={'100%'}
            />
          </QCenter>
        )}
        {commentThread.comments.map((comment, index) => (
          <>
            <QStack
              key={index}
              direction={'row'}
              data-cy={'comment-history-thread-comment-element'}
            >
              <QBox pl={2} pt={2}>
                <Avatar size="sm" name={comment.author} />
              </QBox>
              <QBox>
                <QBox display="flex" pl={2} pt={2}>
                  <QText data-cy="commentAuthor">{comment.author}</QText>
                  <QText pl={2} color="gray.500">
                    {reformatDateString(comment.commentTime)}
                  </QText>
                </QBox>
                <QBox
                  p={2}
                  wordBreak="break-word"
                  data-cy={'comment-history-thread-comment-element-text'}
                >
                  {htmlToReact(comment.comment)}
                </QBox>
              </QBox>
            </QStack>
            {index + 1 < commentThread.comments.length && (
              <QCenter>
                <QDivider
                  orientation="horizontal"
                  color={'gray.200'}
                  width={'90%'}
                />
              </QCenter>
            )}
          </>
        ))}
        {isResolved && (
          <>
            <QCenter>
              <QDivider
                orientation="horizontal"
                color={'gray.200'}
                width={'100%'}
              />
            </QCenter>
            <QStack
              direction={'row'}
              data-cy={'comment-history-thread-comment-element-resolved'}
            >
              <QBox pl={2} pt={2}>
                <Avatar size="sm" name={resolvedBy} />
              </QBox>
              <QBox>
                <QBox display="flex" pl={2} pt={2}>
                  <QText data-cy="commentAuthor">{resolvedBy}</QText>
                  <QText pl={2} color="gray.500">
                    {resolvedAt}
                  </QText>
                </QBox>
                <QBox
                  p={2}
                  wordBreak="break-word"
                  data-cy="comment-history-thread-resolved-statement"
                >
                  <i>{resolvedText}</i>
                </QBox>
              </QBox>
            </QStack>
          </>
        )}
      </QBox>
    </QCenter>
  );
};
