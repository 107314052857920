import { useContext, useEffect, useState } from 'react';

import {
  QButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QSelectItem,
  QSelectPlaceholder,
  QText,
} from '@qualio/ui-components';

import { DocumentCategory, QualioDocument } from '../../api/model/document';
import { MedtechUserV2 } from '../../api/user';
import { sortByLabel } from '../../util/SelectionOptionUtils';

import { DocumentOverviewContext } from '../components/DocumentOverview/Context';

type ChangeDocumentOwnerModalProps = {
  isLoading?: boolean;
  isModalOpen: boolean;
  modalDescription: string;
  users: MedtechUserV2[];
  onClose: () => void;
  handleChangeOwner: (ownerId: number) => Promise<any>;
  onSuccess: (data: any) => void;
  onError?: () => void;
};

const getPotentialOwnerOptions = (
  users: MedtechUserV2[],
  qualioDocument?: QualioDocument,
) => {
  return users
    .filter((user) => {
      if (
        qualioDocument &&
        qualioDocument.category === DocumentCategory.ISSUE_RELATED
      ) {
        return user.permissions.includes('can_work_on_issue');
      }
      return user.permissions.includes('can_author_doc');
    })
    .map(({ id, full_name }) => ({
      value: id.toString(),
      label: full_name,
    }))
    .sort(sortByLabel);
};

export const ChangeDocumentOwnerModal = ({
  isLoading,
  isModalOpen,
  modalDescription,
  users,
  onClose,
  handleChangeOwner,
  onSuccess,
  onError,
}: ChangeDocumentOwnerModalProps) => {
  const [selectedOwnerId, setSelectedOwnerId] = useState<string>();
  const [isChangingOwner, setIsChangingOwner] = useState(false);
  const { qualioDocument } = useContext(DocumentOverviewContext);

  const potentialOwnerOptions: QSelectItem[] = getPotentialOwnerOptions(
    users,
    qualioDocument,
  );

  useEffect(() => {
    setSelectedOwnerId(undefined);
  }, [isModalOpen]);

  const onSubmit = async () => {
    if (!selectedOwnerId) {
      return;
    }
    setIsChangingOwner(true);
    try {
      const data = await handleChangeOwner(+selectedOwnerId);
      onSuccess(data);
      setIsChangingOwner(false);
    } catch (error) {
      setIsChangingOwner(false);
      if (onError) {
        onError();
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <QModal isOpen onClose={onClose}>
          <QModalHeader>
            <QText>Change owner</QText>
          </QModalHeader>
          <QModalBody>
            <QText>{modalDescription}</QText>
            <QText marginTop={5} marginBottom={1} weight="semibold">
              New owner
            </QText>
            <QSelect
              aria-label="Select new owner"
              size="sm"
              options={potentialOwnerOptions}
              onChange={(value) => setSelectedOwnerId(value?.value)}
              value={selectedOwnerId}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              <QSelectPlaceholder>
                <QText>Select new owner...</QText>
              </QSelectPlaceholder>
            </QSelect>
          </QModalBody>
          <QModalActions>
            <QButton
              isDisabled={isChangingOwner}
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </QButton>
            <QButton
              isLoading={isChangingOwner}
              isDisabled={!selectedOwnerId}
              onClick={onSubmit}
            >
              Apply changes
            </QButton>
          </QModalActions>
        </QModal>
      )}
    </>
  );
};
