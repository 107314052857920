import { QStack, QText } from '@qualio/ui-components';
import {
  ActivityLogAction,
  activityLogDetailMap,
} from '../../../api/model/activityLog';

export const FormattedActionHeader = ({
  actionType,
}: {
  actionType: ActivityLogAction;
}) => {
  return (
    <QStack
      data-testid="activity-log-heading"
      data-cy="activity-log-heading"
      direction="row"
      spacing={1}
    >
      <QText>{activityLogDetailMap[actionType].verb}</QText>
      <QText ml="2px" weight="semibold" color="gray.700">
        {activityLogDetailMap[actionType].entity}
      </QText>
    </QStack>
  );
};
