import {
  QLookup,
  QModalHeader,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { documentApi } from '../../../../../../api/document';
import { GroupApi } from '../../../../../../api/group';
import { DocumentStatus } from '../../../../../../api/model/document';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2, userApi } from '../../../../../../api/user';
import { canUserAccessAtLeastOnePrivateTag } from '../../../../DocumentOverview/DocumentProperties/utils';
import { hasRequiredPermissionsForAction } from '../../BulkActions';
import { BulkActionButton, BulkActionButtonProps } from '../BulkActionButton';
import { GroupsCell } from './GroupsCell/GroupsCell';

type AssignTraineesButtonProps = Omit<
  BulkActionButtonProps,
  'isActionAllowed' | 'onClick' | 'label'
> & { tags: Tag[] };

export const AssignTraineesButton = (props: AssignTraineesButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { showToast } = useToastProvider();
  const commonToastProps = {
    id: 'assign-trainees-toast',
    replace: true,
  };
  const { selectedDocuments, currentUser, tags } = props;

  const privateTagIdsToGroupIdsMap = useMemo(
    () =>
      tags.reduce((acc: Map<Tag['id'], Tag['group_ids']>, tag) => {
        if (tag.group_ids.length > 0) {
          acc.set(tag.id, tag.group_ids);
        }
        return acc;
      }, new Map()),
    [tags],
  );

  const selectedDocumentsPrivateTagIdsToGroupIdsMap = useMemo(() => {
    return selectedDocuments.reduce(
      (acc: Map<Tag['id'], Tag['group_ids']>, document) => {
        document.tag_ids.forEach((tagId) => {
          if (privateTagIdsToGroupIdsMap.has(tagId)) {
            acc.set(tagId, privateTagIdsToGroupIdsMap.get(tagId)!);
          }
        });
        return acc;
      },
      new Map(),
    );
  }, [selectedDocuments, privateTagIdsToGroupIdsMap]);

  const { successToastDescription, documentsText } = useMemo(() => {
    const documentsCount = selectedDocuments.length;
    const documentsText = documentsCount > 1 ? 'documents' : 'document';
    return {
      successToastDescription: `${documentsCount} ${documentsText} successfully assigned trainees.`,
      documentsText,
    };
  }, [selectedDocuments.length]);

  const { data: users = [] } = useQuery({
    queryFn: async () => {
      const users = await userApi.fetchUsers(currentUser.companyId);
      return users ?? [];
    },
    queryKey: ['userList'],
    refetchOnWindowFocus: false,
  });

  const { data: groupData = [] } = useQuery({
    queryFn: async () => {
      const groups = await GroupApi.fetchGroups();
      return groups ?? [];
    },
    queryKey: ['groups'],
    refetchOnWindowFocus: false,
  });

  const potentialTrainees = useMemo(() => {
    if (selectedDocumentsPrivateTagIdsToGroupIdsMap.size > 0) {
      const groupIds: Tag['group_ids'][] = Array.from(
        selectedDocumentsPrivateTagIdsToGroupIdsMap.values(),
      );
      return users.filter((user) =>
        canUserAccessAtLeastOnePrivateTag(user, groupIds),
      );
    }
    return users;
  }, [selectedDocumentsPrivateTagIdsToGroupIdsMap, users]);

  const canAssignTrainees: BulkActionButtonProps['isActionAllowed'] = (
    user,
    statuses,
    _activeTab,
    requiredPermissions,
  ) => {
    if (
      !hasRequiredPermissionsForAction(requiredPermissions, user.permissions) ||
      !statuses.length
    ) {
      return false;
    }
    return [
      DocumentStatus.Retired.valueOf(),
      DocumentStatus.Deleted.valueOf(),
    ].every((disallowedStatus) => !statuses.includes(disallowedStatus));
  };

  const filterOption = (
    item: MedtechUserV2,
    searchTerm: string | undefined,
  ) => {
    const groups = groupData
      .filter(({ id }) => item.groups.includes(id))
      .map(({ name }) => name);
    if (!searchTerm) {
      return true;
    }
    return `${item.full_name} ${item.email} ${groups}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  };

  const assignTraineesToDocuments = async (
    selectedUsers: readonly MedtechUserV2[],
  ) => {
    const documentIds = selectedDocuments.map(({ id }) => id);
    const userIds = selectedUsers.map(({ id }) => id);
    try {
      await documentApi.assignTraineesToDocuments(
        currentUser,
        documentIds,
        userIds,
      );
      showToast({
        ...commonToastProps,
        status: 'success',
        title: 'Trainees assigned!',
        description: successToastDescription,
      });
      setIsOpen(false);
    } catch (error) {
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: 'Failed to assign trainees. Please try again.',
      });
    }
  };

  const userDataView: QLookup.DataView<MedtechUserV2> = useMemo(
    () => ({
      full_name: {
        header: 'Full name',
        width: 'auto',
      },
      email: {
        header: 'Email',
        width: 'auto',
      },
      groups: {
        header: 'Groups',
        width: 'auto',
        render: (groups: MedtechUserV2['groups'], user: MedtechUserV2) => (
          <GroupsCell
            groupIds={groups}
            availableGroups={groupData}
            identifier={user.id}
          />
        ),
      },
    }),
    [groupData],
  );

  return (
    <QLookup.DataProvider.Fixed
      data={potentialTrainees}
      filterOption={filterOption}
    >
      <BulkActionButton
        {...props}
        isActionAllowed={canAssignTrainees}
        onClick={() => setIsOpen(true)}
        label="Assign trainees"
      />
      <QLookup.MultiSelect<MedtechUserV2>
        isOpen={isOpen}
        action="Assign"
        searchPlaceholder="Filter by name, email or group name..."
        accessors={{
          id: 'id',
        }}
        onSelect={assignTraineesToDocuments}
        onCancel={() => setIsOpen(false)}
        view={userDataView}
        defaultSortBy={{ id: 'full_name' }}
      >
        <QModalHeader>
          <QText fontSize="xl">Assign trainees</QText>
          <QText fontWeight="400" fontSize="md" pt="6">
            Assign trainees to {selectedDocuments.length} {documentsText}.
          </QText>
        </QModalHeader>
      </QLookup.MultiSelect>
    </QLookup.DataProvider.Fixed>
  );
};
