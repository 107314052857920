import { useContext } from 'react';

import {
  QBadge,
  QBox,
  QButtonGroup,
  QDivider,
  QHeading,
  QLink,
  QPropertiesPanel,
  QStack,
  QText,
  QTogglePropertiesPanelButton,
  useCurrentUser,
} from '@qualio/ui-components';

import { DocumentFormat } from '../../../../api/model/document';

import { ChangeControl } from '../../../../types/DocumentCreate';
import { convertEpochToDate } from '../../../../util/DateUtils';
import { DocumentOverviewContext } from '../Context';
import { isIssueRelated } from '../RenderLogic';
import { ChangeRequest } from './ChangeRequest';
import styles from './DocumentProperties.module.css';
import { EditPropertiesButton } from './EditPropertiesButton';
import {
  ManageApproversProps,
  ManageOtherApprovers,
  ManageQualityApprovers,
} from './ManageApprovers';
import { ManageReviewers } from './ManageReviewers';
import { ManageTags, ManageTagsProps } from './ManageTags';
import { ManageTrainees, ManageTraineesProps } from './ManageTrainees';
import {
  determineFileDownloadUsergroupPermissionsFromModel,
  FileDownloadPermissionOptions,
} from './utils';
import { ViewIssue } from './ViewIssue';

type ChangeControlSubType = {
  changeId: ChangeControl['id'] | undefined;
  changeControlReference: ChangeControl['change_control_reference'] | undefined;
};

type DocumentPropertiesProps = {
  changeControl: ChangeControlSubType;
} & ManageTraineesProps &
  ManageTagsProps &
  Pick<ManageApproversProps, 'tags' | 'groups' | 'users'>;

export const DocumentProperties = ({
  tags,
  versions,
  groups,
  users,
  changeControl,
}: DocumentPropertiesProps) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const { formatDate } = useCurrentUser();

  const formatToText = {
    [DocumentFormat.QualioNativeDocument]: 'Qualio Document',
    [DocumentFormat.DocumentUpload]: 'Uploaded Document',
  };

  const sanitiseProperty = (property: any) => {
    if (!property) {
      return '-';
    }

    return property;
  };

  const format = (date: number | null) => {
    if (date === null) {
      return null;
    }
    return formatDate(convertEpochToDate(date));
  };

  const reviewPeriod = qualioDocument.review_period
    ? `${qualioDocument.review_period} ${
        qualioDocument.review_period > 1 ? 'months' : 'month'
      }`
    : '-';
  const effectiveOnApproval = qualioDocument.effective_on_approval
    ? 'Yes'
    : 'No';
  const trainingRequired = qualioDocument.training_required ? 'Yes' : 'No';

  return (
    <QPropertiesPanel openOnMount>
      <QBox
        className={styles['document-properties-variable-width']}
        fontSize="sm"
      >
        <QStack
          isInline
          justifyContent="space-between"
          spacing="2"
          alignItems="center"
        >
          <QHeading style={{ textWrap: 'nowrap' } as any} fontSize="md" pb={4}>
            Properties
          </QHeading>
          <QButtonGroup>
            <EditPropertiesButton />
            <QTogglePropertiesPanelButton />
          </QButtonGroup>
        </QStack>
        <QStack spacing="4">
          <QStack spacing="1">
            <QText fontWeight="semibold">Author</QText>
            <QText data-cy={'author-name'}>
              {sanitiseProperty(qualioDocument?.author?.full_name)}
            </QText>
          </QStack>
          <QStack spacing="1">
            <QText fontWeight="semibold">Type</QText>
            <QText data-cy={'document-type'}>
              {sanitiseProperty(qualioDocument?.type)}
            </QText>
          </QStack>
          <QStack spacing="1">
            <QText fontWeight="semibold">Format</QText>
            <QText data-cy={'document-format'}>
              {sanitiseProperty(formatToText[qualioDocument?.document_format])}
            </QText>
          </QStack>
          {!isIssueRelated(qualioDocument) && (
            <QStack spacing="2">
              <QText fontWeight="semibold">Change ID</QText>
              {changeControl.changeId ? (
                <QBadge data-cy={'change-id'}>
                  <QLink
                    isCrossMFE
                    href={`/change-management/change-controls/${changeControl.changeId}`}
                  >
                    {changeControl.changeControlReference}
                  </QLink>
                </QBadge>
              ) : (
                <QText>-</QText>
              )}
            </QStack>
          )}
          <QStack spacing="1">
            <QText fontWeight="semibold">Approval date</QText>
            <QText data-cy={'approval-date'}>
              {sanitiseProperty(format(qualioDocument.approval_date))}
            </QText>
          </QStack>
          <QStack spacing="1">
            <QText fontWeight="semibold">Effective date</QText>
            <QText data-cy={'effective-date'}>
              {sanitiseProperty(format(qualioDocument.effective_date))}
            </QText>
          </QStack>
          {!isIssueRelated(qualioDocument) && (
            <>
              <QStack spacing="1">
                <QText fontWeight="semibold">Next review</QText>
                <QText data-cy={'review-date'}>
                  {sanitiseProperty(format(qualioDocument.review_date))}
                </QText>
              </QStack>
              <QStack spacing="1">
                <QText fontWeight="semibold">Review period</QText>
                <QText data-cy={'review-period'}>{reviewPeriod}</QText>
              </QStack>
              <QStack spacing="1">
                <QText fontWeight="semibold">Effective on approval</QText>
                <QText data-cy={'effective-on-approval'}>
                  {effectiveOnApproval}
                </QText>
              </QStack>
            </>
          )}
          {!isIssueRelated(qualioDocument) && (
            <QStack spacing="1">
              <QText fontWeight="semibold">Training required</QText>
              <QText data-cy={'training-required'}>{trainingRequired}</QText>
            </QStack>
          )}
          {qualioDocument.document_format === DocumentFormat.DocumentUpload && (
            <QStack data-cy="document-download-permissions" spacing="1">
              <QText fontWeight="semibold">Download permissions</QText>
              <QText data-cy="document-download-permissions-label">
                {
                  FileDownloadPermissionOptions[
                    determineFileDownloadUsergroupPermissionsFromModel(
                      qualioDocument,
                    )
                  ]
                }
              </QText>
            </QStack>
          )}
        </QStack>
        <QDivider mt="6" mb="6" />
        <ViewIssue />
        <ChangeRequest />
        <ManageQualityApprovers
          approvers={qualioDocument.audit_groups.approver_group}
          tags={tags}
          groups={groups}
          users={users}
        />
        <QDivider mt="6" mb="6" />
        <ManageOtherApprovers
          approvers={qualioDocument.audit_groups.approver_group}
          tags={tags}
          groups={groups}
          users={users}
        />
        <QDivider mt="6" mb="6" />
        <ManageReviewers
          reviewers={qualioDocument.audit_groups.review_group}
          tags={tags}
          groups={groups}
          users={users}
        />
        <QDivider mt="6" mb="6" />
        <ManageTags tags={tags} groups={groups} />
        {!isIssueRelated(qualioDocument) && (
          <>
            <QDivider mt="6" mb="6" />
            <ManageTrainees
              versions={versions}
              groups={groups}
              tags={tags}
              users={users}
            />
          </>
        )}
      </QBox>
    </QPropertiesPanel>
  );
};
