import { ToastProvider, useCurrentUser } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useState } from 'react';
import { DocumentCommentHistoryPanel } from '../../../DocumentCommentHistoryPanel';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderHistory } from '../../RenderLogic';

type CommentHistoryActionProps = {
  renderClickableItem: (onClick: () => void) => JSX.Element;
};

export const CommentHistoryAction = ({
  renderClickableItem,
}: CommentHistoryActionProps) => {
  const [isCommentHistoryOpen, setIsCommentHistoryOpen] = useState(false);
  const { qualioDocument } = useContext(DocumentOverviewContext);
  const currentUser = useCurrentUser();
  const { qualioFrontendRefresh } = useFlags();

  if (!shouldRenderHistory(qualioDocument, currentUser)) {
    return null;
  }

  return (
    <>
      {renderClickableItem(() => setIsCommentHistoryOpen(true))}
      {isCommentHistoryOpen && (
        <ToastProvider enableDelegation={qualioFrontendRefresh}>
          <DocumentCommentHistoryPanel
            isOpen={true}
            onClose={() => setIsCommentHistoryOpen(false)}
            companyId={currentUser.companyId}
            documentId={qualioDocument.id.toString()}
          />
        </ToastProvider>
      )}
    </>
  );
};
