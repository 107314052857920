import React, { useEffect, useRef, useState } from 'react';

export type ToolbarWrapperProps = {
  id?: string;
  className?: string;
  widthRef: React.RefObject<HTMLElement>;
};

/** Assumes that if we find an angular app, that we're in MTFE. */
const isMTFE = !!(window as any)['angular'];

/**
 * A wrapper component that ensures the toolbar is always visible.
 * The toolbar will be fixed to the top of the screen when the user scrolls past it.
 * Its width is synced to the given `widthRef` element.
 *
 * Does nothing except render the div if MTFE is detected (to preserve backwards
 * compatibility).
 */
export const ToolbarWrapper: React.FC<ToolbarWrapperProps> = ({
  children,
  id = 'toolbarWrapper',
  className,
  widthRef,
}) => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [forcedRenderCount, setForceRender] = useState(0);

  useEffect(() => {
    // We don't want to do anything if we are in MTFE,
    // and we can't do anything without a valid ref.
    if (isMTFE || !toolbarRef.current) {
      return;
    }

    const toolbar = toolbarRef.current;
    const originalOffsetTop = toolbar.offsetTop;

    if (originalOffsetTop === 0) {
      // We have not been layed out yet
      // (we might be in a hidden tab or
      // have just switched tab).
      // We will try again in 2s.
      const handle = setTimeout(() => setForceRender((n) => n + 1), 2000);
      return () => {
        clearTimeout(handle);
      };
    }

    // Most likely these are undefined but we'll be
    // defensive about it just in case. When we stop
    // manually updating the toolbar position,
    // we'll restore these values.
    const originalPositionStyle = toolbar.style.position;
    const originalWidthStyle = toolbar.style.width;

    // Creates an observer that will sync the toolbar width
    // to the width of the element being observed.
    let observed: HTMLElement | null = null;
    const observer = new ResizeObserver(
      (entries) =>
        (toolbar.style.width = entries[0].borderBoxSize[0].inlineSize + 'px'),
    );

    const onScroll = () => {
      // If we have scrolled beyond the top of the toolbar,
      // start managing it's width and position.
      if (window.scrollY > originalOffsetTop) {
        toolbar.style.position = 'fixed';
        if (!observed && widthRef.current) {
          observed = widthRef.current;
          observer.observe(observed);
        }
      } else {
        // If we have scrolled back to the top, stop managing
        // the toolbar's width and position.
        if (observed) {
          observer.unobserve(observed);
          observed = null;
        }
        toolbar.style.position = originalPositionStyle;
        toolbar.style.width = originalWidthStyle;
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', onScroll);
    };
  }, [toolbarRef, widthRef, forcedRenderCount]);

  return (
    <div ref={toolbarRef} className={className} id={id}>
      {children}
    </div>
  );
};
