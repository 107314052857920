import slugify from 'slugify';
import {
  DocumentSectionRevision,
  QualioDocument,
  QualioDocumentSection,
} from '../api/model/document';
import { ChangeControlSection } from '../types/DocumentCreate';
import { parseHTMLString } from './HTMLElementUtils';
import { StringEscapeUtils } from './StringEscapeUtils';

import DOMPurify from 'dompurify';
import { ChangeRequestSectionType } from '../views/components/ChangeRequestSideDrawer/types/ChangeRequest';

interface SectionMetadata {
  title: string;
  position: number;
}

const MAIL_TO_REGEX = /<mailto:(.+?)>[\s?]/;
const TEL_REGEX = /<tel:\+?(\d+)>/g;
const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

const domPurifyConfig = {
  KEEP_CONTENT: true,
  ADD_ATTR: ['multilevel'],
};

export class DocumentPresentationUtils {
  static determineHtmlDocumentContentFromDocument(
    editorDocument: QualioDocument,
    includeCollabData = true,
    forExport = false,
    useLegacyExport = false,
    sanitizeHtmlWithDomPurify = false,
  ): string {
    return editorDocument.sections
      .map((section) => {
        const showTitle = editorDocument.text_sections_titles_visible;
        const sectionTitle = DocumentPresentationUtils.determineSectionTitleTag(
          section,
          showTitle,
        );

        let content = section.content;
        if (!includeCollabData) {
          content = DocumentPresentationUtils.stripCollabData(content);
        }

        if (!sanitizeHtmlWithDomPurify) {
          if (MAIL_TO_REGEX.test(content)) {
            content = DocumentPresentationUtils.stripMailtoContent(content);
          }
          if (TEL_REGEX.test(content)) {
            content = DocumentPresentationUtils.stripTelContent(content);
          }
          if (EMAIL_REGEX.test(content)) {
            content = DocumentPresentationUtils.stripEmailContent(content);
          }

          content = DocumentPresentationUtils.addFigureToTables(content);

          if (!showTitle && forExport && !useLegacyExport) {
            return `${sectionTitle}${content}`;
          } else if (!showTitle && forExport && useLegacyExport) {
            return content;
          }
        } else {
          content = DocumentPresentationUtils.addFigureToTables(content);

          if (!showTitle && forExport && !useLegacyExport) {
            return DOMPurify.sanitize(
              `${sectionTitle}${content}`,
              domPurifyConfig,
            );
          } else if (!showTitle && forExport && useLegacyExport) {
            return DOMPurify.sanitize(content, domPurifyConfig);
          }
        }

        content = `${sectionTitle}${DocumentPresentationUtils.addSectionWrappingHtml(
          content,
        )}`;

        if (sanitizeHtmlWithDomPurify) {
          return DOMPurify.sanitize(content, domPurifyConfig);
        }
        return content;
      })
      .join('');
  }

  static disableCheckboxesInSectionContent = (section: string) => {
    const changeRequestWrapperDiv = document.createElement('div');
    changeRequestWrapperDiv.innerHTML = section;
    const checkboxes = changeRequestWrapperDiv.querySelectorAll(
      '.ck-content input[type=checkbox]',
    );
    checkboxes.forEach((checkbox) => {
      checkbox.classList.add('non-editable-mode-wrapper');
    });

    return changeRequestWrapperDiv.innerHTML;
  };

  static determineHtmlContentFromSections(
    sections:
      | QualioDocumentSection[]
      | DocumentSectionRevision[]
      | ChangeControlSection[]
      | ChangeRequestSectionType[],
    includeCollabData = true,
    forExport = false,
    useLegacyExport = false,
    showSectionTitles = true,
    sanitizeHtmlWithDomPurify = false,
    disableCheckBoxes = false,
  ): string {
    return sections
      .map((section) => {
        const sectionTitle = DocumentPresentationUtils.determineSectionTitleTag(
          section,
          showSectionTitles,
        );

        let content = includeCollabData
          ? section.content
          : DocumentPresentationUtils.stripCollabData(section.content);

        content = DocumentPresentationUtils.addFigureToTables(content);

        if (!sanitizeHtmlWithDomPurify) {
          if (!showSectionTitles && forExport && !useLegacyExport) {
            return `${sectionTitle}${content}`;
          } else if (!showSectionTitles && forExport && useLegacyExport) {
            return content;
          }
        } else {
          if (!showSectionTitles && forExport && !useLegacyExport) {
            return DOMPurify.sanitize(
              `${sectionTitle}${content}`,
              domPurifyConfig,
            );
          } else if (!showSectionTitles && forExport && useLegacyExport) {
            return DOMPurify.sanitize(content, domPurifyConfig);
          }
        }

        content = `${sectionTitle}${DocumentPresentationUtils.addSectionWrappingHtml(
          content,
        )}`;

        if (disableCheckBoxes) {
          content =
            DocumentPresentationUtils.disableCheckboxesInSectionContent(
              content,
            );
        }

        if (sanitizeHtmlWithDomPurify) {
          return DOMPurify.sanitize(content, domPurifyConfig);
        }

        return content;
      })
      .join('');
  }

  static addSectionWrappingHtml(editorContent: string): string {
    const contentToWrap =
      editorContent !== ''
        ? editorContent
        : '<p><br data-cke-filler="true"></p>';
    return (
      `<div data-testid="sectionContent" class="ck-restricted-editing_mode_restricted ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred" >` +
      contentToWrap +
      '</div>'
    );
  }

  static determineSectionTitleTag(
    sectionMetadata: SectionMetadata,
    showTitle = true,
  ): string {
    let classes = 'section-heading';
    if (!showTitle) {
      classes += ' section-heading__hide';
    }

    return `<h1 class="${classes}" id="${slugify(
      sectionMetadata.title.toLowerCase(),
    )}"><span class="locked-section-headers">${
      sectionMetadata.position
    }. ${DocumentPresentationUtils.processTitleForView(
      sectionMetadata.title,
    )}</span></h1>`;
  }

  static processTitleForView(title: string): string {
    // replace multiple spaces with a single space
    const titleWithNoDoubleSpaces = title.replace(/\s\s+/g, ' ');
    return StringEscapeUtils.specialEscapeForCkEditor(
      titleWithNoDoubleSpaces,
    ).trim();
  }

  static getDocumentSectionsTitles(
    sections: QualioDocumentSection[] | ChangeControlSection[],
  ) {
    return sections.map(({ title }, index) => {
      return `${index + 1}. ${title}`;
    });
  }

  static stripCollabData(sectionContent: string): string {
    const parsedContent = parseHTMLString(sectionContent);
    const result = DocumentPresentationUtils.removeCollabAttributesFromContent(
      DocumentPresentationUtils.removeCollabTagsFromContent(parsedContent),
    );

    return result.body.innerHTML;
  }

  // documents converted from Froala but not edited
  // don't get its table elements wrapped in a <figure>
  // tag and therefore css is not correctly applied
  static addFigureToTables(sectionContent: string): string {
    const parsedContent = parseHTMLString(sectionContent);
    const sectionTables = parsedContent.querySelectorAll('table');
    Array.from(sectionTables).forEach((table) => {
      const parent = table.parentElement;

      if (parent === null) {
        return;
      }

      // Need to remove ckeditor's styling from the figure when table properties
      // are modified - https://github.com/ckeditor/ckeditor5/issues/15486
      if (parent.tagName === 'FIGURE') {
        const styleAttribute = parent.getAttribute('style');
        if (styleAttribute) {
          parent.style.height = '';
        }
        return;
      }

      const figure = document.createElement('figure');
      figure.className = 'table';
      const clonedTable = table.cloneNode(true);
      figure.appendChild(clonedTable);
      parent?.insertBefore(figure, table);
      table.remove();
    });

    return parsedContent.body.innerHTML;
  }

  static endOfLineInsertion(el: Element, closingTag: string): Element | null {
    let parent = el.parentElement;
    let hasClosingTag = parent?.querySelector(closingTag) !== null;
    while (parent && parent.nextSibling === null && !hasClosingTag) {
      parent = parent.parentElement;
      hasClosingTag = parent?.querySelector(closingTag) !== null;
    }
    return parent?.nextElementSibling ?? null;
  }

  static removeCollabSuggestedInsertion(
    suggestionStart: Element[],
    closingTag: string,
  ): void {
    suggestionStart.forEach((el) => {
      const name = el.getAttribute('name');
      if (name?.includes('insertion')) {
        let next: Element | null =
          (el.nextSibling as Element) ||
          DocumentPresentationUtils.endOfLineInsertion(el, closingTag);
        while (
          next &&
          (!next.tagName ||
            (next.tagName && next.tagName.toLocaleLowerCase() !== closingTag))
        ) {
          const sibling = next.nextSibling as Element;
          const hasClosingTag: boolean =
            next.nodeType === 3
              ? false
              : next.querySelector(closingTag) !== null;
          next.remove();
          next = hasClosingTag ? null : sibling;
        }
      }
    });
  }

  static replaceTDCollabTagsFromContent(
    suggestedTDs: Element[],
    content: Document,
  ): void {
    const startSuggestionAttr = 'data-suggestion-start-before';
    const endBeforeSuggestionAttr = 'data-suggestion-end-before';
    suggestedTDs.forEach((el) => {
      const suggestionAttr =
        el.getAttribute(startSuggestionAttr) ??
        el.getAttribute(endBeforeSuggestionAttr);
      if (suggestionAttr?.includes('deletion')) {
        el.parentElement?.insertAdjacentHTML('afterbegin', el.innerHTML);
      }
    });
  }

  static removeCollabTagsFromContent(content: Document): Document {
    const commentStartTag = 'comment-start';
    const commentEndTag = 'comment-end';
    const suggestionStartTag = 'suggestion-start';
    const suggestionEndTag = 'suggestion-end';
    const commentStart = Array.from(
      content.getElementsByTagName(commentStartTag),
    );
    const commentEnd = Array.from(content.getElementsByTagName(commentEndTag));
    const suggestionStart = Array.from(
      content.getElementsByTagName(suggestionStartTag),
    );
    const suggestionEnd = Array.from(
      content.getElementsByTagName(suggestionEndTag),
    );
    const startSuggestionInline = Array.from(
      content.querySelectorAll('[data-suggestion-start-before^=insertion]'),
    );
    const startAfterSuggestionInline = Array.from(
      content.querySelectorAll('[data-suggestion-start-after^=insertion]'),
    );
    const suggestedTDs = Array.from(
      content.getElementsByTagName('suggestion-td'),
    );
    DocumentPresentationUtils.replaceTDCollabTagsFromContent(
      suggestedTDs,
      content,
    );
    DocumentPresentationUtils.removeCollabSuggestedInsertion(
      suggestionStart,
      suggestionEndTag,
    );

    const toRemove = commentStart.concat(
      commentEnd,
      suggestionStart,
      suggestionEnd,
      startSuggestionInline,
      startAfterSuggestionInline,
      suggestedTDs,
    );
    toRemove.forEach((commentTag) => {
      commentTag.remove && commentTag.remove();
    });
    return content;
  }

  static removeCollabAttributesFromContent(content: Document): Document {
    const startCommentAttr = 'data-comment-start-before';
    const endCommentAttr = 'data-comment-end-after';
    const startAfterCommentAttr = 'data-comment-start-after';
    const endBeforeCommentAttr = 'data-comment-end-before';
    const startSuggestionAttr = 'data-suggestion-start-before';
    const endSuggestionAttr = 'data-suggestion-end-after';
    const startAfterSuggestionAttr = 'data-suggestion-start-after';
    const endBeforeSuggestionAttr = 'data-suggestion-end-before';

    const elementsWithCollabData = Array.from(
      content.querySelectorAll(`[${startCommentAttr}]`),
    ).concat(
      Array.from(content.querySelectorAll(`[${startSuggestionAttr}]`)),
      Array.from(content.querySelectorAll(`[${startAfterCommentAttr}]`)),
      Array.from(content.querySelectorAll(`[${startAfterSuggestionAttr}]`)),
    );
    elementsWithCollabData.forEach((el) => {
      el.removeAttribute(startCommentAttr);
      el.removeAttribute(endCommentAttr);
      el.removeAttribute(startAfterCommentAttr);
      el.removeAttribute(endBeforeCommentAttr);
      el.removeAttribute(startSuggestionAttr);
      el.removeAttribute(endSuggestionAttr);
      el.removeAttribute(startAfterSuggestionAttr);
      el.removeAttribute(endBeforeSuggestionAttr);
    });
    return content;
  }

  /**
   * PS-3599: https://qualio.atlassian.net/browse/PS-3599
   * Strip out any content that looks like: <mailto:testuser1@test.com>
   * because DOMParser will attempt to create it as an element in the html viewer
   */
  static readonly stripMailtoContent = (sectionContent: string) => {
    let processedContent = sectionContent;
    const mailtoMatches = Array.from(
      sectionContent.matchAll(new RegExp(MAIL_TO_REGEX, 'g')),
    );
    mailtoMatches.forEach((match) => {
      const [invalidContent, value] = match;
      const anchorLink = `<a href="mailto:${value}">${value}</a>`;
      // First replace invalid content with nothing
      processedContent = processedContent.replaceAll(invalidContent, '');
      // Then add anchor link to replace value with anchor link
      processedContent = processedContent.replaceAll(value, anchorLink);
    });
    return processedContent;
  };

  /**
   * PS-3860: https://qualio.atlassian.net/browse/PS-3860
   * Strip out any content that looks like: <tel:123...>
   * because DOMParser will attempt to create it as an element in the html viewer
   */
  static readonly stripTelContent = (sectionContent: string) => {
    let processedContent = sectionContent;
    const telMatches = Array.from(
      sectionContent.matchAll(new RegExp(TEL_REGEX, 'g')),
    );
    telMatches.forEach((match) => {
      const [invalidContent, value] = match;
      const anchorLink = `<a href="tel:${value}">${value}</a>`;
      // First replace invalid content with nothing
      processedContent = processedContent.replaceAll(invalidContent, '');
      // Then add anchor link to replace value with anchor link
      processedContent = processedContent.replaceAll(value, anchorLink);
    });
    return processedContent;
  };

  /**
   * PS-3860: https://qualio.atlassian.net/browse/PS-3860
   */
  static readonly stripEmailContent = (sectionContent: string) => {
    let processedContent = sectionContent;
    const emailMatches = Array.from(
      sectionContent.matchAll(new RegExp(EMAIL_REGEX, 'g')),
    );
    emailMatches.forEach((match) => {
      const anchorLink = `<a href="mailto:${match[0]}">${match[0]}</a>`;
      processedContent = processedContent.replaceAll(
        `<${match[0]}>`,
        anchorLink,
      );
    });
    processedContent = processedContent.replace(/&lt;/g, '');
    processedContent = processedContent.replace(/&gt;/g, '');
    return processedContent;
  };
}
