import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  QAlert,
  QAlertProps,
  QBadge,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QCenter,
  QHeader,
  QHorizontalMetadata,
  QLastModifiedMetadata,
  QOwnerMetadata,
  QPageLoader,
  QStack,
  QTab,
  QTabList,
  QTabs,
  QTag,
  QTitle,
  QualioQThemeProvider,
  QVersionMetadata,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import {
  documentApi,
  TrainingAssessmentValidation,
} from '../../../../../api/document';
import { DocumentStatus } from '../../../../../api/model/document';

import { useTrainingAssessment } from '../../../TrainingAssessment/hooks/useTrainingAssessment';
import { Question } from '../../../TrainingAssessment/types/trainingAssessment';

import { DocumentStatusIdToStatusConfigMap } from '../../../../../util/WorkspaceUtils';
import {
  isApprovedOrEffective,
  shouldCompleteTraining,
} from '../../RenderLogic';

import { useBodyBackground } from '../../../../../hooks/BodyBackground';
import { BannerPlaceholder } from '../../../BannerPlaceholder/index';
import { CredentialConfirmationModal } from '../../../CredentialConfirmationModal/index';
import { DocumentContainer } from '../../../DocumentContainer/index';
import { useNavigateAfterTraining } from '../hooks';
import { TrainingAssessmentQuestion } from './TrainingAssessmentQuestion';

type TrainingAssessmentForm = {
  [key: string]: number;
};

const getQuestionsForValidation = (
  questions: Question[],
  formStateAssessmentForValidation: TrainingAssessmentForm,
) => {
  const formStateKeys = Object.keys(formStateAssessmentForValidation);
  const questionsForValidation = [...questions];

  formStateKeys.forEach((key) => {
    const index = parseInt(key.split('-')[1]) - 1;
    const anwsers = [...questionsForValidation[index].answers];
    // we set the is_correct property of that question's answers to the user's choice
    questionsForValidation[index].answers = anwsers.map((answer, idx) => {
      const indexOfSelectedAnswer = parseInt(
        // either the QRadio or react hook form
        // transform the value to string
        // eslint-disable-next-line
        // @ts-ignore
        formStateAssessmentForValidation[key],
      );
      answer.is_correct = indexOfSelectedAnswer === idx;
      return answer;
    });
  });

  return questionsForValidation;
};

export const TrainingAssessmentView = () => {
  useBodyBackground('document-editor-overview-refresh', true);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successfullyValidatedAssessment, setSuccessfullyValidatedAssessment] =
    useState<TrainingAssessmentValidation | null>(null);
  const { id: documentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const navigateAfterTraining = useNavigateAfterTraining();
  const { showToast } = useToastProvider();

  const loadDocument = async () => {
    if (!documentId) {
      return;
    }

    const fetchedDocument = await documentApi.fetchByIdOrVersion(
      documentId,
      '',
      1,
      1,
    );

    return fetchedDocument;
  };

  const {
    isLoading: isLoadingDocument,
    data: qualioDocument,
    isError: isDocumentError,
  } = useQuery({
    queryKey: ['documentTraining', documentId],
    queryFn: loadDocument,
    refetchOnWindowFocus: false,
    onError: () => {
      showToast({
        id: 'training-assessment-load-error',
        title: 'Error',
        description: 'Could not load assessment. Please try again',
        status: 'error',
        replace: true,
      });
    },
  });

  const {
    data: trainingAssessment,
    isLoading: isLoadingTrainingAssessment,
    isError: isAssessmentError,
  } = useTrainingAssessment(documentId);

  const { control, handleSubmit, reset } = useForm<TrainingAssessmentForm>();

  useEffect(() => {
    if (trainingAssessment) {
      reset({});
    }
  }, [trainingAssessment, reset]);

  const handleExitTraining = () => {
    const basePath = location.pathname.split('/training/')[0];
    navigate(basePath);
  };

  const handleConfirmTraining = async (email: string, password: string) => {
    if (!qualioDocument || successfullyValidatedAssessment === null) {
      return;
    }
    try {
      await documentApi.signOffTraining(
        qualioDocument.id,
        email,
        password,
        successfullyValidatedAssessment,
      );
      showToast({
        id: 'training-sign-off-success',
        description: 'Successfully completed required training.',
        status: 'success',
        title: 'Training complete!',
        replace: true,
      });
      navigateAfterTraining();
    } catch (error: unknown) {
      throw error;
    }
  };

  const validateAssessment: SubmitHandler<TrainingAssessmentForm> = async (
    assessmentForm,
  ) => {
    if (!documentId || !trainingAssessment) {
      return;
    }
    setIsValidating(true);
    const docIdNumber = parseInt(documentId);
    const validationPayload = {
      documentId: docIdNumber,
      id: trainingAssessment?.id,
      questions: getQuestionsForValidation(
        trainingAssessment?.questions,
        assessmentForm,
      ),
    };

    try {
      await documentApi.validateAssessment(docIdNumber, validationPayload);
      setIsModalOpen(true);
      setHasValidationError(false);
      setSuccessfullyValidatedAssessment(validationPayload);
    } catch (e: unknown) {
      if ((e as AxiosError)?.response?.status === 400) {
        setHasValidationError(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        showToast({
          id: 'training-assessment-validation-error',
          title: 'Error',
          description: 'Could not validate assessment. Please try again',
          status: 'error',
          replace: true,
        });
      }
    } finally {
      setIsValidating(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTraining = async () => {
    await handleSubmit(validateAssessment)();
  };

  const handleSignOffError = () => {
    showToast({
      id: 'training-assessment-sign-off-error',
      title: 'Error',
      description: 'Could not sign off assessment. Please try again',
      status: 'error',
      replace: true,
    });
  };

  if (!qualioDocument || isLoadingDocument || isLoadingTrainingAssessment) {
    return (
      <>
        <BannerPlaceholder />
        <DocumentContainer>
          <QualioQThemeProvider>
            <QPageLoader />
          </QualioQThemeProvider>
        </DocumentContainer>
      </>
    );
  }

  if (
    (!trainingAssessment && !isLoadingTrainingAssessment) ||
    !shouldCompleteTraining(qualioDocument, currentUser) ||
    !isApprovedOrEffective(qualioDocument.status_id)
  ) {
    handleExitTraining();
  }

  const documentStatusProps = qualioDocument?.status_id
    ? DocumentStatusIdToStatusConfigMap[
        qualioDocument.status_id as DocumentStatus
      ]
    : undefined;

  const alertProps: QAlertProps = {
    title: hasValidationError
      ? 'Incorrect answers'
      : 'Please complete the training assessment.',
    description:
      'You must answer the following questions correctly to complete the training.',
    status: hasValidationError ? 'error' : 'info',
  };

  return (
    <>
      <BannerPlaceholder />
      <DocumentContainer>
        <QualioQThemeProvider>
          <QBodyLayout.Default>
            <QHeader>
              <QTitle>
                {qualioDocument.title}{' '}
                <QBadge data-cy="document-code">{qualioDocument.code}</QBadge>
              </QTitle>

              <QHorizontalMetadata>
                <QTag
                  data-cy="document-status"
                  variantColor={documentStatusProps?.color}
                >
                  {documentStatusProps?.label}
                </QTag>
                <QVersionMetadata
                  data-cy="document-version"
                  value={
                    qualioDocument.major_version +
                    '.' +
                    qualioDocument.minor_version
                  }
                />
                <QOwnerMetadata
                  data-cy="document-owner"
                  value={qualioDocument.owner.full_name}
                />
                <QLastModifiedMetadata
                  data-cy="document-last-modified"
                  value={
                    new Date(qualioDocument.content_last_modified_at * 1000)
                  }
                />
              </QHorizontalMetadata>
              <QButtonGroup>
                <QButton
                  variant="outline"
                  onClick={handleExitTraining}
                  data-testid="doc-overview-exit-training"
                  data-cy="doc-overview-exit-training"
                >
                  Exit training assessment
                </QButton>
              </QButtonGroup>
            </QHeader>
            <QStack direction="row">
              <QBox width={'100%'}>
                <QTabs>
                  <QTabList>
                    <QTab
                      data-testid="document-tab-training-assessment"
                      data-cy="document-tab-training-assessment"
                      isDisabled={true}
                    >
                      Training assessment
                    </QTab>
                  </QTabList>
                </QTabs>
              </QBox>
            </QStack>
            <QCenter mt={10}>
              <QStack spacing={10} width={'720px'}>
                <QStack spacing={4}>
                  <QAlert {...alertProps} />
                  {trainingAssessment?.questions.map((question, idx) => {
                    return (
                      <TrainingAssessmentQuestion
                        question={question}
                        index={idx + 1}
                        key={`question-${idx}`}
                        control={control}
                      />
                    );
                  })}
                </QStack>
                <QCenter mt={10}>
                  <QButton
                    isDisabled={isAssessmentError || isDocumentError}
                    isLoading={isValidating}
                    variant="solid"
                    onClick={handleTraining}
                  >
                    Submit & complete training
                  </QButton>
                </QCenter>
              </QStack>
            </QCenter>
          </QBodyLayout.Default>
          <CredentialConfirmationModal
            isOpen={isModalOpen}
            onCloseModal={handleCloseModal}
            handleStatusChange={handleConfirmTraining}
            modalHeading="Complete training"
            modalText="Enter your digital signature to confirm you have been trained."
            buttonActionLabel="Sign off"
            withComment={false}
            handleError={handleSignOffError}
          />
        </QualioQThemeProvider>
      </DocumentContainer>
    </>
  );
};
