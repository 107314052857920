import { ReactNode } from 'react';

import { QBox } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBodyBackground } from '../../../hooks/BodyBackground';
import { isInQualioFrontendApp } from '../../../util/UrlUtils';
import styles from './DocumentContainer.module.css';

type DocumentContainerProps = {
  children: ReactNode;
};

export const DocumentContainerMTFE = ({ children }: DocumentContainerProps) => {
  useBodyBackground(styles['document-container__body']);
  return (
    <QBox
      className={`gray-container-inner ${styles['document-container__layout-inner']} ${styles['document-container-variable-width']} document-container__inner`}
    >
      {children}
    </QBox>
  );
};

export const DocumentContainerNewFrontend = ({
  children,
}: DocumentContainerProps) => {
  return <>{children}</>;
};

export const DocumentContainer = ({ children }: DocumentContainerProps) => {
  const { useLegacyDomainForMTFE } = useFlags();

  const inQualioFrontendApp = isInQualioFrontendApp(useLegacyDomainForMTFE);
  const Container = inQualioFrontendApp
    ? DocumentContainerNewFrontend
    : DocumentContainerMTFE;

  return <Container>{children}</Container>;
};
